import { formatCurrencySuffix } from "common/lib/currency";
import React from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { createSelector } from "reselect";

const ActiveContractValue = () => {
  // Fetch data frm redux store

  const selectProperties = createSelector(
    (state: any) => state.Contracts,
    (state) => ({
      contractsSummary: state.contractsSummary,
    })
  );

  const { contractsSummary } = useSelector(selectProperties);

  const amount = formatCurrencySuffix(
    contractsSummary?.totalActiveContractValue
  );

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
              Active Contract Value
            </p>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
              <span className="counter-value" data-target="559.25">
                <CountUp
                  start={0}
                  prefix="$"
                  suffix={amount?.suffix}
                  separator=""
                  end={amount?.value}
                  decimals={2}
                  duration={4}
                />
              </span>
            </h4>
            <Link to="/contracts" className="text-decoration-underline">
              View contracts
            </Link>
          </div>
          <div className="avatar-sm flex-shrink-0">
            <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
              <i className={`text-success bx bx-dollar-circle`}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActiveContractValue;
