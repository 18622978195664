import React   from "react";

const SpotChecks = () => {
    return (
        <div>
        <h1>SpotChecks</h1>
        </div>
    );
}

export default SpotChecks;