import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContractorRegistrationForm from "./ContractorRegistrationForm";
import { updateContractor } from "common/services/contractors.service";
import { toast } from "react-toastify";

const UpdateContractorRegistration: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  data: any;
  contractorId: string;
}> = ({ toggle, isModalOpen, contractorId, data, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractorRegistration: (data.length &&
        data.map((item: any) => ({
          id: item.id,
          countryOfRegistration: item.countryOfRegistration,
          registrationName: item.registrationName,
          registrationNumber: item.registrationNumber,
          registrationType: item.registrationType,
          registrationDate: item.registrationDate,
          lengthOfValidity: item.lengthOfValidity,
        }))) || [
        {
          countryOfRegistration: "",
          registrationName: "",
          registrationNumber: "",
          registrationType: "",
          registrationDate: "",
          lengthOfValidity: "",
        },
      ],
    },
    validationSchema: Yup.object({
      contractorRegistration: Yup.array().of(
        Yup.object({
          countryOfRegistration: Yup.string().required("Required"),
          registrationName: Yup.string().required("Required"),
          registrationNumber: Yup.string().required("Required"),
          registrationType: Yup.string(),
          registrationDate: Yup.date().required("Required"),
          lengthOfValidity: Yup.string(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: contractorId,
        data: {
          contractorRegistration: values.contractorRegistration?.map(
            (item: any) => ({
              ...item,
            })
          ),
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractor(data);
          validation.resetForm();
          toggle();
          toast.success("Contractor Registration Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating contractor registration", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contractor Registration{" "}
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.contractorRegistration?.length || 0}
        </small>
      </ModalHeader>

      <ContractorRegistrationForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
      />
    </Modal>
  );
};

export default UpdateContractorRegistration;
