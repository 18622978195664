import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

interface BreadCrumbProps {
  title: string;
  pageTitle?: string;
  pageTitles?: { title: string; link?: string }[];
  back?: boolean;
}

const BreadCrumb = ({
  title,
  pageTitle,
  pageTitles,
  back,
}: BreadCrumbProps) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {back && (
                <i
                  className="me-2 las la-angle-double-left fs-15"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(-1)}
                ></i>
              )}
              <h4 className="mb-sm-0">{title}</h4>
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {pageTitles &&
                  pageTitles.map((page, index) => (
                    <li className="breadcrumb-item" key={index}>
                      {page.link ? (
                        <Link to={page.link}>{page.title}</Link>
                      ) : (
                        page.title
                      )}
                    </li>
                  ))}
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
