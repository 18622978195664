import { ICON_AGENCIES, ICON_CALENDAR } from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";

const ContractItem = ({
  data,
  index,
}: {
  data: any;

  index?: number;
}) => {
  return (
    <div className={index === 0 ? "pb-3" : "py-3"}>
      <h5 className="mb-1">
        <Link to={`/contracts/${data.id}`}>{data?.contractTitle}</Link>
      </h5>
      <p className="text-success mb-2">Contracts</p>
      <p className="text-muted mb-2">
        {`... `}
        {data?.startDate &&
          `Start Date: ${moment(new Date(data?.startDate)).format(
            "dddd, MMM DD, Y"
          )}`}
        {data?.startDate &&
          ` End Date: ${moment(new Date(data?.endDate)).format(
            "dddd, MMM DD, Y"
          )}`}
        {` Contractor: ${data?.contractor?.contractorName}`}
        {` ... `}
      </p>

      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item me-3">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CALENDAR}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">
                {moment(new Date(data?.updatedAt)).format("DD MMM Y")}
              </span>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_AGENCIES}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">{data?.agency?.name}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ContractItem;
