import React from "react";
import { Container } from "reactstrap";
import Content from "./Content";
import { APP_NAME_SHORT } from "common/constants";
import { useParams } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";

const ViewContractor = () => {
  document.title = `View Contractor | ${APP_NAME_SHORT}`;

  const { id: contractorId } = useParams();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Contractor Details"
            pageTitles={[
              {
                title: "Dashboard",
                link: "/",
              },
              { title: "Contractors", link: "/contractors" },
            ]}
            back
          />

          <Content contractorId={contractorId || ""} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewContractor;
