import { createSlice } from "@reduxjs/toolkit";
import {
  getCountryOffices,
  addCountryOffice,
  updateCountryOffice,
  deleteCountryOffice,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  countryOffices: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const CountryOfficesSlice = createSlice({
  name: "CountryOfficesSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountryOffices.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload.data);
        state.countryOffices.data = data || state.countryOffices.data;
      }
      state.countryOffices.meta =
        action.payload?.meta || state.countryOffices.meta;
      state.loading = false;
    });
    builder.addCase(getCountryOffices.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
    builder.addCase(addCountryOffice.fulfilled, () => {
      toast.success("Country Office Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addCountryOffice.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Country Office Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateCountryOffice.fulfilled, () => {
      toast.success("Country Office Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(updateCountryOffice.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Country Office Update Failed", { autoClose: 3000 });
    });
    builder.addCase(
      deleteCountryOffice.fulfilled,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.success("Country Office Deleted Successfully", {
          autoClose: 3000,
        });
      }
    );
    builder.addCase(deleteCountryOffice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
      toast.error("Country Office Deletion Failed", { autoClose: 3000 });
    });
  },
});

export const { setLoading } = CountryOfficesSlice.actions;

export default CountryOfficesSlice.reducer;
