import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import { getSearchResults as getSearchResultsApi } from "helpers/backend_helper";
import { searchClient } from "helpers/meiisearch_helper";

import { setLoading, setPage } from "./reducer";

export const getAllSearchResults = createAsyncThunk(
  "search/getAllSearchResults",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(setPage(1));
      const response = await searchClient
        .index("contract")
        .search(params?.query, {
          hitsPerPage: 10,
          page: 1,
          // filter: 'countryCode = "SO"',
        });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSearchResults = createAsyncThunk(
  "search/getSearchResults",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getSearchResultsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// export const getContractorsResults = createAsyncThunk(
//   "contract/getContract",
//   async (id: any, { dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const params = {
//         populate: [
//           "contractor",
//           "subContractedTo",
//           "contractRating",
//           "contractType",
//           "agency",
//         ],
//       };
//       const response = getContractApi(id, params);
//       return response;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// export const getContractsResults = createAsyncThunk(
//   "contract/addContract",
//   async (contract: any) => {
//     try {
//       const response = addContractApi(contract);
//       return response;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// export const getSanctionsListResults = createAsyncThunk(
//   "contract/updateContract",
//   async (contract: any) => {
//     try {
//       const response = updateContractApi(contract);
//       const data = response;
//       return data;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// export const getAssessmentResults = createAsyncThunk(
//   "contract/deleteContract",
//   async (contract: any) => {
//     try {
//       const response = deleteContractApi(contract);
//       return response;
//     } catch (error) {
//       return error;
//     }
//   }
// );
