import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import TableContainer from "Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import {
  getAgencyContracts,
  deleteAgencyContract,
} from "common/services/contracts.service";
import AddContract from "./AddContract";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import { isContractActive } from "common/lib/contractOptions";
import UpdateContract from "./UpdateContract";
import Permission from "Components/Common/Permission";
import ContractTypeFilter from "pages/Contracts/ContractTypeFilter";
import AgencyFilter from "pages/Contracts/AgencyFilter";
import DeleteModal from "Components/Common/DeleteModal";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import { useProfile } from "Components/Hooks/UserHooks";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  contractType: string;
  agency: string;
  dateRange: DateRange;
}

// @ts-ignore
const TextWrapper: React.FC<{ value: string }> = ({ value }) => {
  if (!value) return null;

  return <div style={{ whiteSpace: "pre-wrap", width: "220px" }}>{value}</div>;
};

const AllContracts = ({
  isViewContractors,
  contractorId,
}: {
  isViewContractors?: boolean;
  isAgencyDashboard?: boolean;
  contractorId?: string;
}) => {
  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const { userProfile } = useProfile();

  const pageSize = 10;

  const fetchContracts = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (contract: any) => {
    setSelectedRecord(contract);
    setIsUpdateModalOpen(true);
  };

  const [searchValue, setSearchValue] = useState("");
  const defaultFilterValue: FilterValue = {
    contractType: "",
    agency: "",
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  };
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      if (filterValue?.contractType) {
        andConditions.push({
          contractType: {
            id: {
              $eq: filterValue.contractType,
            },
          },
        });
      }
      if (filterValue?.dateRange?.fromDate && filterValue?.dateRange?.toDate) {
        andConditions.push({
          endDate: {
            $gte: filterValue.dateRange.fromDate,
            $lte: filterValue.dateRange.toDate,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            contractTitle: {
              $containsi: searchValue,
            },
          },
          {
            contractor: {
              contractorName: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      try {
        setLoading(true);

        const response: any = await getAgencyContracts({
          pagination: {
            page,
            pageSize: 10,
          },
          sort: sortObj,
          populate: [
            "agency",
            "contractType",
            "contractRating",
            "subContractedTo",
            "contractor",
            "location",
          ],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedContracts = useCallback(() => {
    onPageChange(pageCache);
  }, [pageCache, onPageChange]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteAgencyContract(selectedRecord.id);
        fetchUpdatedContracts();
        setDeleting(false);
        setDeleteModal(false);

        toast.success("Contract deleted successfully", { autoClose: 500 });
      } catch (e: any) {
        toast.error("Error while deleting contract", { autoClose: 500 });
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Contract Title",
        accessorKey: "contractTitle",
        id: "contractTitle",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/contracts/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },

      {
        header: "Contractor Name",
        accessorKey: "contractor.contractorName",
        enableColumnFilter: false,
        id: "contractor.contractorName",
        cell: (cell: any) => <TextWrapper value={cell.getValue() || ""} />,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          const status = isContractActive(
            cell.row.original.startDate || "",
            cell.row.original.endDate || ""
          );
          return (
            <React.Fragment>
              {status === null ? (
                <span className="badge bg-warning-subtle text-warning fs-11">
                  N/A
                </span>
              ) : status ? (
                <span className="badge bg-success-subtle text-success fs-11">
                  Active
                </span>
              ) : status === false ? (
                <span className="badge bg-danger-subtle text-danger fs-11">
                  Not Active
                </span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "contractValue",
        enableColumnFilter: false,
        id: "contractValue",
        cell: (cell: any) => {
          return (
            <>
              {cell.getValue() ? `$ ${cell.getValue().toLocaleString()}` : ""}
            </>
          );
        },
      },

      {
        header: "Contract Type",
        accessorKey: "contractType.name",
        enableColumnFilter: false,
        id: "contractType.name",
      },

      {
        header: "Contract Start Date",
        accessorKey: "startDate",
        id: "startDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === null) return null;
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },

      {
        header: "Contract End Date",
        accessorKey: "endDate",
        id: "endDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === null) return null;
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },

      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="contract" action="findOne">
                  <DropdownItem href={`/contracts/${rowData.id}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission
                  resource="contract"
                  action="update"
                  controller="agency"
                >
                  <DropdownItem
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm({
                        id: rowData.id,
                        ...rowData,
                      });
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission
                  resource="contract"
                  action="delete"
                  controller="agency"
                >
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [onClickDelete]
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setDeleteModal(false)}
        loading={deleting}
      />
      <Card>
        <CardBody>
          <Row className="g-3">
            <Col xxl={3} lg={6}>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search contracts..."
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xxl={2} lg={6}>
              <ContractTypeFilter
                onChange={(value: any) => {
                  setFilterValue({ ...filterValue, contractType: value });
                }}
                value={filterValue.contractType}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <Flatpickr
                className="form-control"
                placeholder="Select Date Range"
                options={{
                  mode: "range",
                  altFormat: "d M, Y",
                  dateFormat: "d M, Y",
                }}
                onChange={(e) => {
                  if (e[0] && e[1])
                    setFilterValue({
                      ...filterValue,
                      dateRange: {
                        fromDate: e[0],
                        toDate: e[1],
                      },
                    });
                }}
                value={[
                  filterValue?.dateRange?.fromDate || "",
                  filterValue?.dateRange?.toDate || "",
                ]}
              />
            </Col>
            <Col xxl={2} lg={6}>
              <AgencyFilter
                onChange={(value: any) => {
                  setFilterValue({ ...filterValue, agency: value });
                }}
                value={filterValue.agency}
              />
            </Col>
            <Col xxl={2} lg={4}>
              <button
                onClick={() => {
                  setFilterValue(defaultFilterValue);
                  setSearchValue("");
                }}
                className="btn btn-outline-primary material-shadow-none w-100"
              >
                <i className="ri-equalizer-line align-bottom me-1"></i> Reset
                Filters
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={3}>
              <h5 className="card-title mb-0">
                {userProfile?.agency?.name} Contracts
              </h5>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <Permission resource="contract" action="create">
                  <Link
                    to="/contractors/add"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      showAddModalForm();
                    }}
                  >
                    <i className="ri-add-line align-bottom me-1"></i>
                    Add Contract
                  </Link>
                </Permission>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            loading={loading}
            columns={columns}
            data={data || []}
            customPageSize={pageSize}
            pagination={meta?.pagination || {}}
            onPageChange={onPageChange}
            FilterSection={() => {}}
            totalRows={meta?.pagination?.total || 0}
            parentSearchValue={searchValue}
            parentFilterValue={filterValue}
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light table-nowrap"
          />
          <AddContract
            toggle={() => setIsAddModalOpen((state) => !state)}
            isModalOpen={isAddModalOpen}
            fetchContracts={fetchContracts}
          />
          <UpdateContract
            toggle={() => setIsUpdateModalOpen((state) => !state)}
            isModalOpen={isUpdateModalOpen}
            selectedRecord={selectedRecord}
            fetchContracts={fetchUpdatedContracts}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AllContracts;
