import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select/async";
import { getContractors } from "common/services/contractors.service";
import { normalize } from "common/lib/normalize";
import {
  GroupBase,
  OptionsOrGroups,
} from "react-select/dist/declarations/src/types";

const SelectContractor = ({
  validation,
  isUpdate,
  selectedId,
}: {
  validation: any;
  isUpdate?: boolean;
  selectedId?: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.contractor && validation.errors.contractor
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.contractor && validation.errors.contractor
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let filters = {};
        if (isUpdate) {
          filters = {
            contractor: selectedId,
          };
        }
        const response = await getContractors({
          pagination: {
            page: 1,
            pageSize: 20,
          },
          filters,
        });
        setData(normalize(response.data));
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [isUpdate, selectedId]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.contractorName} ( ${el?.acronym} )`,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="contractor-field" className="form-label">
        Contractor
      </Label>
      <Select
        name="contractor"
        id="contractor"
        value={
          validation.values.contractor.value
            ? validation.values.contractor
            : null
        }
        placeholder="Select contractor"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("contractor", value);
          validation.setFieldError("contractor", "");
        }}
        defaultOptions={options}
        // @ts-ignore
        loadOptions={(
          inputValue: string,
          callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void
        ) => {
          return getContractors({
            pagination: {
              page: 1,
              perPage: 20,
            },
            filters: {
              $or: [
                {
                  contractorName: {
                    $containsi: inputValue,
                  },
                },
                {
                  acronym: {
                    $containsi: inputValue,
                  },
                },
              ],
            },
          }).then((result: any) => {
            const parsedData = normalize(result.data);
            callback(
              parsedData.map((el: any) => {
                return {
                  value: el?.id,
                  label: `${el?.contractorName} ( ${el?.acronym} )`,
                };
              })
            );
          });
        }}
        onBlur={() => validation.setFieldTouched("contractor", true)}
        className={
          validation.touched.contractor && validation.errors.contractor
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
        isLoading={loading}
      />
      <FormFeedback>{validation.errors.contractor?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectContractor;
