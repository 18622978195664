import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import * as moment from "moment";
import UpdateCapacityAssessment from "../UpdateCapacityAssessment";
import DeleteModal from "Components/Common/DeleteModal";
import { toast } from "react-toastify";
import { deleteCapacityAssessment } from "common/services/capacity-assessments.service";
import { useNavigate } from "react-router-dom";
import Permission from "Components/Common/Permission";

const Header = ({ data, fetchData }: { data: any; fetchData: () => void }) => {
  const navigate = useNavigate();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = () => {
    setIsUpdateModalOpen(true);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    setDeleting(true);
    try {
      await deleteCapacityAssessment(data);
      navigate("/assessments/capacity-assessments");
      setDeleteModal(false);
    } catch (err: any) {
      toast.error("Error while deleting capacity assessment", {
        autoClose: 1000,
      });
    } finally {
      setDeleting(false);
    }
  };
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className=" rounded">
          <div className="bg-secondary-subtle rounded">
            <CardBody className="pb-4 ">
              <Row>
                <div className="col-md">
                  <Row className="align-items-center">
                    <div className="col-md-auto">
                      <div className="avatar-md mb-md-0 mb-4 img-thumbnail rounded-circle">
                        <div className="avatar-title bg-secondary-subtle rounded-circle fs-24">
                          <i className="ri-file-pdf-line text-secondary"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <h4 className="fw-semibold" id="ticket-title">
                        {`${data?.reportTitle}`}
                      </h4>
                      <div className="hstack gap-3 flex-wrap">
                        <div className="text-muted text-capitalize">
                          <i className="ri-building-line align-bottom me-1"></i>{" "}
                          <span id="ticket-client">
                            {data?.contractor?.contractorName}
                          </span>
                        </div>
                        <div className="vr"></div>
                        {/* <div className="text-muted">
                          Date Added :{" "}
                          <span className="fw-medium" id="create-date">
                            {moment(new Date(data?.createdAt)).format(
                              "DD MMM, Y"
                            )}
                          </span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Last Updated :{" "}
                          <span className="fw-medium" id="due-date">
                            {moment(new Date(data?.updatedAt)).format(
                              "DD MMM, Y"
                            )}
                          </span>
                        </div>
                        <div className="vr"></div> */}
                      </div>
                    </div>
                  </Row>
                </div>
                {/* <div className="col-md-auto mt-md-0 mt-4"></div> */}
                <div className="col-md-auto">
                  <div className="hstack gap-1 flex-wrap">
                    <UncontrolledDropdown className="float-end">
                      <DropdownToggle
                        tag="a"
                        className="btn py-0 fs-13 material-shadow-none align-middle  text-primary"
                        role="button"
                      >
                        Actions
                        <i className=" ri-arrow-down-s-fill fs-16 ms-1"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <Permission
                          action="update"
                          resource="capacity-assessment"
                        >
                          <DropdownItem
                            href="#"
                            className="edit-item-btn"
                            onClick={(e: any) => {
                              e.preventDefault();
                              showUpdateModalForm();
                            }}
                          >
                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                            Edit
                          </DropdownItem>
                        </Permission>
                        <Permission
                          action="delete"
                          resource="capacity-assessment"
                        >
                          <DropdownItem
                            href="#"
                            className="remove-item-btn"
                            onClick={(e: any) => {
                              e.preventDefault();
                              setDeleteModal(true);
                            }}
                          >
                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                            Delete
                          </DropdownItem>
                        </Permission>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* <div className="hstack flex-wrap">
                      <button
                        type="button"
                        className="btn py-0 fs-16 favourite-btn material-shadow-none active"
                      >
                        <i className="ri-star-fill"></i>
                      </button>
                      <button
                        type="button"
                        className="btn py-0 fs-13 material-shadow-none text-body"
                      >
                        <i className="ri-pencil-line"></i> Edit
                      </button>
                      <button
                        type="button"
                        className="btn py-0 fs-13 material-shadow-none text-body"
                      >
                        <i className="ri-more-fill fs-16"></i> Actions
                      </button>
                    </div> */}
                  </div>
                </div>
              </Row>
            </CardBody>
          </div>
        </Card>
      </Col>
      <UpdateCapacityAssessment
        toggle={() => setIsUpdateModalOpen((state) => !state)}
        isModalOpen={isUpdateModalOpen}
        fetchUpdatedData={fetchData}
        selectedRecord={data}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setDeleteModal(false)}
        loading={deleting}
      />
    </React.Fragment>
  );
};

export default Header;
