import BreadCrumb from "Components/Common/BreadCrumb";
import React from "react";
import { Card, CardBody, Row, Col, Container, CardHeader } from "reactstrap";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { CountriesCharts } from "./DashboardAnalyticsCharts";
import classNames from "classnames";

const LoadingContainer = () => <div>Loading...</div>;

const mapStyles = {
  width: "100%",
  height: "100%",
};

const countryData = [
  {
    data: [1010, 1140, 1290, 1255, 1050, 689, 800, 420, 1085, 589],
    name: "Sessions",
  },
];
const AllegationsReport = (props: any) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Aid Diversion Allegations" pageTitle="Reports" />

          <Card>
            <CardBody>
              <Row className="g-3">
                <Col xxl={4} lg={6}>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search contractors..."
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <select
                    className="form-control"
                    data-choices
                    data-choices-search-false
                    name="choices-single-default2"
                    id="choices-single-default2"
                    onChange={(e) => {}}
                  >
                    <option value="">Select Organization Type</option>
                    <option value="Active ICOs">Government</option>
                    <option value="Ended ICOs">National NGO</option>
                    <option value="Upcoming ICOs">International NGO</option>
                    <option value="Trading ICOs">Private Company</option>
                    <option value="Trading ICOs">UN AFP</option>
                  </select>
                </Col>
                <Col xxl={2} lg={6}>
                  <select
                    className="form-control"
                    data-choices
                    data-choices-search-false
                    name="choices-single-default2"
                    id="choices-single-default2"
                    onChange={(e) => {}}
                  >
                    <option value="">Select Status</option>
                    <option value="Active ICOs">Flagged</option>
                    <option value="Ended ICOs">Not Flagged</option>
                    <option value="Upcoming ICOs">Under Inquiry</option>
                  </select>
                </Col>
                <Col xxl={2} lg={6}>
                  <select
                    className="form-control"
                    data-choices
                    data-choices-search-false
                    name="choices-single-default"
                    id="choices-single-default"
                    onChange={(e) => {}}
                  >
                    <option value="">Select Number of Agencies </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </Col>
                <Col xxl={2} lg={4}>
                  <button className="btn btn-primary w-100" disabled>
                    <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                    Filters
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card></Card>

          <Row>
            <Col lg={3}>
              <Row>
                <Col xl={12} md={12}>
                  <Card className="card-animate">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 overflow-hidden">
                          <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                            Reported Incidents
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between mt-4">
                        <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                            <span
                              className="counter-value"
                              data-target="559.25"
                            >
                              <CountUp
                                start={0}
                                prefix={""}
                                suffix={""}
                                separator={""}
                                end={26}
                                decimals={0}
                                duration={4}
                              />
                            </span>
                          </h4>
                          <Link to="#" className="text-decoration-underline">
                            View Allegations
                          </Link>
                        </div>
                        <div className="avatar-sm flex-shrink-0">
                          <span
                            className={
                              "avatar-title rounded fs-3 bg-" + "success-subtle"
                            }
                          >
                            <i className={`text-success bx bx-info-circle`}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card className="card-height-100">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Sessions by Countries
                      </h4>
                      <div className="d-flex gap-1"></div>
                    </div>
                    <div className="card-body p-0">
                      <div>
                        <CountriesCharts
                          chartId="countries_charts"
                          series={countryData}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={9}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Risks Reported by Location
                  </h4>
                </CardHeader>
                <CardBody>
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      zoom={8}
                      style={mapStyles}
                      initialCenter={{ lat: 34.134117, lng: -118.321495 }}
                    >
                      <Marker position={{ lat: 48.0, lng: -122.0 }} />
                      <Marker position={{ lat: 34.134117, lng: -118.321495 }} />
                      <Marker position={{ lat: 32.995049, lng: -111.536324 }} />
                      <Marker position={{ lat: 37.383064, lng: -109.071236 }} />
                      <Marker position={{ lat: 39.877586, lng: -79.640617 }} />
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBZQBNrzq5vDg8Yc73rx3wrnxPD9SA5zTs",
  LoadingContainer: LoadingContainer,
  v: "3",
})(AllegationsReport);
