import React, { useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
// import Vector from './VectorMap';
import { VectorMap } from "@south-paw/react-vector-maps";
import soMap from "../../common/so.svg.json";
import { getContractDistributionByRegion } from "common/services/contracts.service";

import "./MapStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { setDistributionByRegion } from "slices/contract/reducer";
import { createSelector } from "reselect";

const regionColors: any = {
  Somaliland: "text-primary",
  Puntland: "text-success",
  Galmudug: "text-warning",
  Hirshabelle: "text-secondary",
  "South West": "text-danger",
  Jubaland: "text-info",
  Benadir: "text-warning",
};

const regionBgs: any = {
  Somaliland: "bg-primary",
  Puntland: "bg-success",
  Galmudug: "bg-warning",
  Hirshabelle: "bg-secondary",
  "South West": "bg-danger",
  Jubaland: "bg-info",
  Benadir: "bg-warning",
};

const ContractDistributionMap = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getContractDistributionByRegion({
          startDate: "2024-01-01",
          endDate: "2024-12-31",
        });

        dispatch(setDistributionByRegion(response.data));
      } catch (error) {
        console.error("Error fetching contract distribution by region", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const selectProperties = createSelector(
    (state: any) => state,
    (state) => ({
      distributionByRegion: state.Contracts.distributionByRegion,
      contractTotals: state.Contracts.contractTotals,
    })
  );

  const { distributionByRegion } = useSelector(selectProperties);

  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Distribution of Contracts by Region
          </h4>
          <div className="flex-shrink-0"></div>
        </CardHeader>

        <CardBody>
          <div className="map-container" style={{ height: "269px" }}>
            <VectorMap
              {...soMap}
              // checkedLayers={["somaliland"]}
              // currentLayers={["puntland"]}
            />
          </div>
          <div className="mt-0">
            {distributionByRegion.data.map((item: any, index: number) => {
              if (index > 6) return null;
              const percentage = item?.percentage.toFixed(2) || 0;
              return (
                <div className="d-flex align-items-center" key={item.region}>
                  <i
                    className={
                      "ri-checkbox-blank-circle-fill  align-middle me-3 " +
                      regionColors[item.region]
                    }
                  ></i>
                  <div className="flex-shrink-0 me-4">{item?.region}</div>
                  <div
                    className="progress progress-sm  flex-grow-1"
                    style={{ width: percentage + "%" }}
                  >
                    <div
                      className={
                        "progress-bar progress-bar-striped rounded " +
                        regionBgs[item.region]
                      }
                      role="progressbar"
                      style={{ width: percentage + "%" }}
                    ></div>
                  </div>
                  <div className="ms-3">{percentage + "%"}</div>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ContractDistributionMap;
