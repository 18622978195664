import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { setLoading } from "slices/countries/reducer";
import { getAgencies as onGetAgencies } from "../../slices/thunks";
import { Label, Input, FormFeedback, FormGroup } from "reactstrap";

type SelectAgencyCategoryProps = {
  validation: any;
};

const SelectAgencyCategory = ({ validation }: SelectAgencyCategoryProps) => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetAgencies({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
        filters: {
          category: {
            $null: true,
          },
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const selectLayoutState = (state: any) => state.Agencies;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    agencies: state.agencies.data,
  }));

  const { agencies } = useSelector(selectProperties);

  const options = useMemo(() => {
    return agencies.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [agencies]);

  return (
    <FormGroup>
      <Label htmlFor="choices-publish-status-input" className="form-label">
        Select Category
      </Label>
      <Input
        name="category"
        type="select"
        className="form-select"
        id="choices-publish-body-input"
        placeholder="Select year of manufacture"
        onChange={(e) => {
          validation.setFieldValue("category", e.target.value);
        }}
        onBlur={() => validation.setFieldTouched("category", true)}
        value={validation.values.category || ""}
        invalid={
          validation.touched.category && validation.errors.category
            ? true
            : false
        }
      >
        <option value="" selected>
          Select an option
        </option>
        {options.map((option: any) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
      {validation.touched.category && validation.errors.category ? (
        <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

export default SelectAgencyCategory;
