import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getRoles as getRolesApi,
  // addNewRole as addNewRoleApi,
  // updateRole as updateRoleApi,
  // deleteRole as deleteRoleApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getRoles = createAsyncThunk(
  "role/getRoles",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getRolesApi(params);
      return response;
    } catch (error: any) {
      return error;
    }
  }
);

// export const addNewRole = createAsyncThunk(
//   "role/addNewRole",
//   async (role: any) => {
//     try {
//       const response = addNewRoleApi(role);

//       return response;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// export const updateRole = createAsyncThunk(
//   "role/updateRole",
//   async (role: any) => {
//     try {
//       const response = updateRoleApi(role);
//       const data = response;
//       return data;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// export const deleteRole = createAsyncThunk(
//   "role/deleteRole",
//   async (role: any) => {
//     try {
//       const response = deleteRoleApi(role);
//       return response;
//     } catch (error) {
//       return error;
//     }
//   }
// );
