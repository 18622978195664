import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { addAgency as onAddAgency } from "../../slices/thunks";
import AgencyForm from "./AgencyForm";

const AddAgency: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchAgencies: () => void;
}> = ({ toggle, isModalOpen, fetchAgencies }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      category: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter agency name"),
      category: Yup.string(),
    }),
    onSubmit: (values) => {
      const payload = {
        data: {
          name: values["name"],
          category: values["category"] || null,
        },
      };

      setLoading(true);
      dispatch(onAddAgency(payload)).then((result: any) => {
        setLoading(false);
        if (result?.payload?.data) {
          fetchAgencies();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  return (
    <Modal id="showModal" isOpen={isModalOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Agency
      </ModalHeader>

      <AgencyForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
      />
    </Modal>
  );
};

export default AddAgency;
