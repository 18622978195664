import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  CardBody,
  Card,
  ModalBody,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

const ContactDetailsForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, loading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <SimpleBar
          ref={simpleBarRef}
          style={{ maxHeight: "80vh" }}
          className=" px-4 py-4"
          autoHide={false}
        >
          <Row className="mb-4">
            {validation.values.alias.map((name: string, index: number) => (
              <>
                <Card className="bg-light" key={index}>
                  <CardBody>
                    <div key={index} className="mb-3 ">
                      <Label htmlFor={`alias-${index}`} className="form-label">
                        Alias Name
                      </Label>
                      <Input
                        name={`alias.${index}`}
                        id={`alias.${index}`}
                        className="form-control"
                        placeholder="Enter alias name"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={name}
                        invalid={
                          validation.touched.alias &&
                          validation.touched.alias[index] &&
                          validation.errors.alias &&
                          validation.errors.alias[index]
                            ? true
                            : false
                        }
                      />
                      {validation.touched.preiousOrAliasName &&
                      validation.touched.alias[index] &&
                      validation.errors.alias &&
                      validation.errors.alias[index] ? (
                        <FormFeedback type="invalid">
                          {validation.errors?.alias &&
                            validation.errors.alias[index]}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                      <Button
                        className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          const names = validation.values.alias;
                          names.splice(index, 1);
                          validation.setFieldValue("alias", names);
                        }}
                      >
                        {/* <i className="ri-delete-bin-fill me-2"></i> */}
                        Remove
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </>
            ))}
            <div>
              <button
                className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
                onClick={(e) => {
                  e.preventDefault();
                  if (validation.values.alias.length < 5) {
                    validation.setFieldValue("alias", [
                      ...validation.values.alias,
                      "",
                    ]);
                  }
                }}
              >
                Add
              </button>
            </div>
          </Row>
        </SimpleBar>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ContactDetailsForm;
