import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { createSelector } from "reselect";
import { getRoles as onGetRoles } from "slices/thunks";
import Select from "react-select";

const UserRole = ({ validation }: { validation: any }) => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(onGetRoles({}));
  }, [dispatch]);

  // select roles from redux store
  const selectLayoutState = (state: any) => state.Roles;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    roles: state.roles.data,
    meta: state.roles.meta,
    error: state.error,
    loading: state.loading,
  }));
  const { roles } = useSelector(selectProperties);

  const roleOptions = useMemo(() => {
    return roles.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name + " - " + el?.description,
      };
    });
  }, [roles]);

  // Custom styles for react-select
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.role && validation.errors.role ? "red" : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.role && validation.errors.role ? "red" : "#ced4da",
      },
    }),
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="role-field" className="form-label">
          Role
        </Label>
        <Select
          name="role"
          id="role"
          value={validation.values.role.value ? validation.values.role : null}
          placeholder="Select role"
          onChange={(value: any) => {
            validation.setFieldValue("role", value);
            // Reset the error when a value is selected
            validation.setFieldError("role", "");
          }}
          options={roleOptions}
          onBlur={() => validation.setFieldTouched("role", true)}
          className={
            validation.touched.role && validation.errors.role
              ? "is-invalid"
              : ""
          }
          styles={customSelectStyles}
        />
        <FormFeedback>{validation.errors.role?.value}</FormFeedback>
      </FormGroup>
    </>
  );
};

export default UserRole;
