import { createSlice } from "@reduxjs/toolkit";
import { getSearchResults, getAllSearchResults } from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  allResults: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 3,
        pageCount: 0,
        total: 0,
      },
    },
  },
  contractors: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 3,
        pageCount: 0,
        total: 0,
      },
    },
  },
  contracts: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },

  error: {},
  refreshList: false,
  loading: false,
  globalSearchValue: "",
};

const SearchSlice = createSlice({
  name: "SearchSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSearchValue: (state, action) => {
      state.globalSearchValue = action.payload;
    },
    setPage: (state, action) => {
      state.allResults.meta.pagination.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllSearchResults.fulfilled,
      (state: any, action: any) => {
        if (action.payload) {
          const apiResponse = action.payload;
          // const combinedData = [];
          // for (const key in apiResponse) {
          //   if (apiResponse.hasOwnProperty(key)) {
          //     const responseData = apiResponse[key];
          //     const data = responseData.map((item: any) => {
          //       return {
          //         ...item,
          //         type: key,
          //       };
          //     });
          //     combinedData.push(...data);
          //   }
          // }
          // state.allResults.data = combinedData || state.allResults.data;
        }
        // state.allResults.meta = action.payload?.meta || state.allResults.meta;
        state.loading = false;
      }
    );
    builder.addCase(getAllSearchResults.rejected, (state: any, action: any) => {
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
  },
});

export const { setLoading, setSearchValue, setPage } = SearchSlice.actions;

export default SearchSlice.reducer;
