import React, { useMemo } from "react";
import {
  Form,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Spinner,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
import Select from "react-select";

import Country from "pages/Users/Country";

const UserForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: any;
}> = ({ validation, toggle, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody>
        <input type="hidden" id="id-field" />

        {/* <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
          <Label className="form-check-label" htmlFor="customSwitchsizemd">
            Blocked
          </Label>
          <Input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            value={validation.values.isBlocked}
            name="isBlocked"
            onChange={validation.handleChange}
          />
        </div> */}

        <Row>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                First Name
              </Label>
              <Input
                name="firstName"
                id="firstName"
                className="form-control"
                placeholder="Enter first name"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.firstName || ""}
                invalid={
                  validation.touched.firstName && validation.errors.firstName
                    ? true
                    : false
                }
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                Last Name
              </Label>
              <Input
                name="lastName"
                id="lastName"
                className="form-control"
                placeholder="Enter other names"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.lastName || ""}
                invalid={
                  validation.touched.lastName && validation.errors.lastName
                    ? true
                    : false
                }
              />
              {validation.touched.lastName && validation.errors.lastName ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <div className="mb-3">
              <Label htmlFor="customername-field" className="form-label">
                Email
              </Label>
              <Input
                name="email"
                id="email"
                className="form-control"
                placeholder="Enter email"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm={6}>
            <Country validation={validation} setLoading={setLoading} />
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {isUpdate ? "Update User" : " Add User"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default UserForm;
