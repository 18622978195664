import React, { useMemo, useState } from "react";
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import SimpleBar from "simplebar-react";

import * as moment from "moment";
import { Link } from "react-router-dom";
import UpdatePerformanceRating from "./UpdatePerformanceRating";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import Skeleton from "react-loading-skeleton";
import { ICON_AGENCIES } from "common/constants";

const Rating = ({ rating }: { rating: any }) => {
  const ratingName = rating?.performanceRatingType?.name;

  let bgColor = "info";
  if (ratingName === "Suspended" || ratingName === "Globally Suspended") {
    bgColor = "danger";
  } else if (ratingName === "Not Satisfactory") {
    bgColor = "warning";
  } else if (ratingName === "Satisfactory") {
    bgColor = "success";
  }

  return (
    <React.Fragment>
      <li className="py-2">
        <div className="border border-dashed rounded p-3">
          <div className="d-flex align-items-start mb-3">
            <div className="hstack gap-3">
              <div className={`badge rounded-pill bg-${bgColor} mb-0`}>
                <i className="mdi mdi-star"></i>{" "}
                {rating?.performanceRatingType?.name || ""}
              </div>
              <div className="vr"></div>
              <div className="flex-grow-1"></div>
            </div>
          </div>

          <Row className="mb-1">
            <Col md={2}>
              <span className="fw-semibold text-body me-1">From: </span>
            </Col>
            <Col md={8}>
              <p className="text-muted mb-0">
                {(rating?.from &&
                  moment(new Date(rating?.from)).format("DD MMM, Y")) ||
                  ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col md={2}>
              <span className="fw-semibold text-body me-1">To: </span>
            </Col>
            <Col md={8}>
              <p className="text-muted mb-0">
                {(rating?.to &&
                  moment(new Date(rating?.to)).format("DD MMM, Y")) ||
                  ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={2}>
              <span className="fw-semibold text-body me-1">Reason: </span>
            </Col>
            <Col md={8}>
              <p className="text-muted mb-0">{rating?.description || ""}</p>
            </Col>
          </Row>

          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <div className="d-flex align-items-center">
                <i className={`${ICON_AGENCIES} fs-14 me-1 text-muted`}></i>
                <h5 className="fs-14 mb-0 text-muted">
                  {rating?.ratedBy?.name}
                </h5>
              </div>
            </div>

            <div className="flex-shrink-0">
              <p className="text-muted fs-13 mb-0">
                <span className="fw-semibold text-body me-1">Posted on:</span>
                {moment(rating?.createdAt).format("DD MMM, YYYY")}
              </p>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

const PerformanceRating = ({ contractor }: { contractor: any }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const selectProperties = createSelector(
    (state: any) => state.Contractors,
    (state) => ({
      performanceRatings: state.contractor?.performanceRatings || [],
      loading: state.loading,
    })
  );
  const { performanceRatings: data, loading } = useSelector(selectProperties);

  const satisfactoryCount = useMemo(() => {
    return data.filter(
      (rating: any) => rating.performanceRatingType?.name === "Satisfactory"
    ).length;
  }, [data]);
  const notSatisfactoryCount = useMemo(() => {
    return data.filter(
      (rating: any) => rating.performanceRatingType?.name === "Not Satisfactory"
    ).length;
  }, [data]);
  const suspendedCount = useMemo(() => {
    return data.filter(
      (rating: any) => rating.performanceRatingType?.name === "Suspended"
    ).length;
  }, [data]);
  const globallySuspendedCount = useMemo(() => {
    return data.filter(
      (rating: any) =>
        rating.performanceRatingType?.name === "Globally Suspended"
    ).length;
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader className="d-flex align-items-center border-bottom-dashed">
          <div className="text-muted flex-grow-1">
            <h6 className="mb-0 fw-semibold text-uppercase">
              Performance Rating
            </h6>
          </div>
          <div className="flex-shrink-0">
            <div>
              <Link
                to="#"
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsAddModalOpen(true);
                }}
                className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
              >
                <i className="ri-pencil-line"></i> Edit
              </Link>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="">
            <div>
              <h5 className="fs-14 mb-3">Summary</h5>
            </div>
            <Row className="gy-4 gx-0">
              <Col lg={12}>
                <div>
                  <div className="pb-3">
                    <div className="bg-light px-3 py-2 rounded-2 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="fs-16 align-middle text-success">
                            <i className="ri-star-fill"></i>{" "}
                            <i className="ri-star-fill"></i>{" "}
                            <i className="ri-star-fill"></i>{" "}
                            <i className="ri-star-fill"></i>{" "}
                            <i className="ri-star-half-fill"></i>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">
                            {satisfactoryCount} out of {data.length}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-muted">
                        Total <span className="fw-medium">{data?.length}</span>{" "}
                        ratings
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <Row className="align-items-center g-2">
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0">Satisfactory</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-2">
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0 text-muted">
                            {satisfactoryCount}
                          </h6>
                        </div>
                      </div>
                    </Row>
                    <Row className="align-items-center g-2">
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0">Not Satisfactory</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-2">
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0 text-muted">
                            {notSatisfactoryCount}
                          </h6>
                        </div>
                      </div>
                    </Row>

                    <Row className="align-items-center g-2">
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0">Suspended</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-2">
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0 text-muted">{suspendedCount}</h6>
                        </div>
                      </div>
                    </Row>

                    <Row className="align-items-center g-2">
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0">Globally Suspended</h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-2">
                          <div className="progress animated-progess progress-sm">
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0 text-muted">
                            {globallySuspendedCount}
                          </h6>
                        </div>
                      </div>
                    </Row>
                    <Row className="py-2"></Row>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="">
                  <div className="d-flex flex-wrap align-items-start gap-3">
                    <h5 className="fs-14">Ratings: </h5>
                  </div>

                  <SimpleBar
                    className="me-lg-n3 pe-lg-4"
                    style={{ maxHeight: "400px" }}
                    autoHide={false}
                  >
                    <ul className="list-unstyled mb-0">
                      {loading &&
                        [0, 0, 0, 0].map((_, key) => (
                          <React.Fragment key={key}>
                            <li className="py-2">
                              <div className="border border-dashed rounded p-3">
                                <div className="d-flex align-items-start mb-3">
                                  <div className="hstack gap-3">
                                    <Skeleton
                                      width={80}
                                      height={24}
                                      borderRadius="50px"
                                    />
                                    <div className="vr"></div>
                                    <div className="flex-grow-1"></div>
                                  </div>
                                </div>

                                <div className="d-flex flex-grow-1 mb-1">
                                  <span className="fw-semibold text-body me-1">
                                    From:{" "}
                                  </span>
                                  <Skeleton width={150} />
                                </div>
                                <div className="d-flex flex-grow-1 mb-1">
                                  <span className="fw-semibold text-body me-1">
                                    To:{" "}
                                  </span>
                                  <Skeleton width={150} />
                                </div>
                                <div className="d-flex flex-grow-1 mb-3">
                                  <span className="fw-semibold text-body me-1">
                                    Reason:{" "}
                                  </span>
                                  <Skeleton width="100%" />
                                </div>

                                <div className="d-flex align-items-end">
                                  <div className="flex-grow-1">
                                    <h5 className="fs-14 mb-0">
                                      <Skeleton width={120} />
                                    </h5>
                                  </div>

                                  <div className="flex-shrink-0">
                                    <p className="text-muted fs-13 mb-0">
                                      <span className="fw-semibold text-body me-1">
                                        Posted on:
                                      </span>
                                      <Skeleton width={100} />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </React.Fragment>
                        ))}
                      {data.map((rating: any, key: number) => (
                        <React.Fragment key={key}>
                          <Rating rating={rating} />
                        </React.Fragment>
                      ))}
                    </ul>
                  </SimpleBar>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>

      <UpdatePerformanceRating
        toggle={() => setIsAddModalOpen((state) => !state)}
        isModalOpen={isAddModalOpen}
        contractor={contractor}
      />
    </>
  );
};

export default PerformanceRating;
