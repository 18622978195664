import React from "react";
import { useProfile } from "Components/Hooks/UserHooks";

interface PermissionsProps {
  checks: any;
  children: any;
}

const Permissions = ({ checks, children }: PermissionsProps) => {
  const { userProfile } = useProfile();
  const permissions = userProfile?.permissions;

  const isAllowed =
    checks &&
    checks.some(
      ({
        resource,
        action,
        controller,
      }: {
        resource: string;
        action: string;
        controller?: string;
      }) => {
        return permissions?.[`api::${resource}`]?.controllers?.[
          controller || resource
        ]?.[action]?.enabled;
      }
    );

  if (!isAllowed) {
    return null; // Or render a placeholder like <div>Access Denied</div>
  }

  return <>{children}</>;
};

export default Permissions;
