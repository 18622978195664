import SelectAgency from "./SelectAgency";
import React, { useEffect, useRef } from "react";
import {
  Col,
  FormFeedback,
  Input,
  Label,
  ModalBody,
  Row,
  Spinner,
  Form,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import SelectPerformanceRatingType from "./SelectPerformanceRatingType";
import SimpleBar from "simplebar-react";
import Flatpickr from "react-flatpickr";

const PerformanceRatingForm = ({
  validation,
  isUpdate,
  loading,
  setLoading,
  toggle,
}: {
  validation: any;
  isUpdate: boolean;
  loading: boolean;
  setLoading: (state: any) => void;
  toggle: () => void;
}) => {
  const simpleBarRef: any = useRef(null);

  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <Row>
          <Col md={12} className="mb-3">
            <SimpleBar
              ref={simpleBarRef}
              style={{ maxHeight: "70vh" }}
              className=" px-4 py-4"
              autoHide={false}
            >
              {validation.values.performanceRatings.map(
                (rating: any, index: number) => (
                  <Card className="bg-light" key={index}>
                    <CardBody>
                      <Row>
                        <Col>
                          <SelectAgency
                            validation={validation}
                            setLoading={setLoading}
                            label="Rated By"
                            index={index}
                          />
                        </Col>

                        <Col>
                          <SelectPerformanceRatingType
                            validation={validation}
                            index={index}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="mb-3">
                          <Label
                            htmlFor={`from-${index}`}
                            className="form-label"
                          >
                            From
                          </Label>
                          <Flatpickr
                            name={`performanceRatings[${index}].from`}
                            id={`from-${index}`}
                            className="form-control"
                            placeholder="Select start date"
                            options={{
                              enableTime: false,
                              altInput: false,
                              altFormat: "d M, Y",
                              dateFormat: "d M, Y",
                            }}
                            onChange={(e) => {
                              validation.setFieldValue(
                                `performanceRatings[${index}].from`,
                                e[0]
                              );
                            }}
                            value={
                              validation.values?.performanceRatings[index]
                                ?.from || ""
                            }
                          />

                          {validation.touched?.performanceRatings &&
                          validation.touched?.performanceRatings[index]?.from &&
                          validation.errors?.performanceRatings &&
                          validation.errors?.performanceRatings[index]?.from ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors?.performanceRatings[index]
                                  ?.from
                              }
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col md={6} className="mb-3">
                          <Label htmlFor={`to-${index}`} className="form-label">
                            To
                          </Label>
                          <Flatpickr
                            name={`performanceRatings[${index}].to`}
                            id={`to-${index}`}
                            className="form-control"
                            placeholder="Select end date"
                            options={{
                              enableTime: false,
                              altInput: false,
                              altFormat: "d M, Y",
                              dateFormat: "d M, Y",
                            }}
                            onChange={(e) => {
                              validation.setFieldValue(
                                `performanceRatings[${index}].to`,
                                e[0]
                              );
                            }}
                            value={
                              validation.values?.performanceRatings[index]
                                ?.to || ""
                            }
                          />

                          {validation.touched?.performanceRatings &&
                          validation.touched?.performanceRatings[index]?.to &&
                          validation.errors?.performanceRatings &&
                          validation.errors?.performanceRatings[index]?.to ? (
                            <FormFeedback type="invalid">
                              {validation.errors?.performanceRatings[index]?.to}
                            </FormFeedback>
                          ) : null}
                        </Col>

                        <Col md={12} className="mb-3">
                          <Label
                            htmlFor={`performanceRatings[${index}].description`}
                            className="form-label"
                          >
                            Reason/Description
                          </Label>
                          <Input
                            name={`performanceRatings[${index}].description`}
                            id={`performanceRatings[${index}].description`}
                            className="form-control"
                            placeholder="Enter description"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={rating.description || ""}
                            invalid={
                              validation.touched.performanceRatings?.[index]
                                ?.description &&
                              validation.errors.performanceRatings?.[index]
                                ?.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.performanceRatings?.[index]
                            ?.description &&
                          validation.errors.performanceRatings?.[index]
                            ?.description ? (
                            <FormFeedback type="invalid">
                              {
                                validation.errors.performanceRatings[index]
                                  .description
                              }
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                            onClick={() => {
                              validation.setFieldValue(
                                "performanceRatings",
                                validation.values.performanceRatings.filter(
                                  (_: any, ratingIndex: number) =>
                                    ratingIndex !== index
                                )
                              );
                            }}
                          >
                            {/* <i className="ri-delete-bin-fill me-2"></i> */}
                            Remove
                          </Button>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                )
              )}
              <button
                className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
                disabled={false}
                onClick={() => {
                  if (true) {
                    const newPerformanceRating = {
                      contractor: {
                        value: "",
                        label: "",
                      },
                      performanceRatingType: {
                        value: "",
                        label: "",
                      },
                      description: "",
                      agency: {
                        value: "",
                        label: "",
                      },
                      from: "",
                      to: "",
                    };
                    validation.setFieldValue("performanceRatings", [
                      ...validation.values.performanceRatings,
                      newPerformanceRating,
                    ]);
                  }
                }}
              >
                Add
              </button>
            </SimpleBar>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default PerformanceRatingForm;
