import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import UserForm from "./UserForm";
import { updateUser } from "common/services/users.service";
import { toast } from "react-toastify";

const UpdateUser: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: any;
  fetchUpdatedUsers: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchUpdatedUsers }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      agency: {
        label: selectedRecord?.agency?.name,
        value: selectedRecord?.agency?.id,
      },
      country: {
        label: selectedRecord?.country?.name,
        value: selectedRecord?.country?.id,
      },
      role: {
        label: selectedRecord?.role?.name,
        value: selectedRecord?.role?.id,
      },
      email: selectedRecord?.email,
      firstName: selectedRecord?.firstName,
      lastName: selectedRecord?.lastName,
      countryOffice: {
        label: selectedRecord?.countryOffice?.name,
        value: selectedRecord?.countryOffice?.id,
      },
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      country: Yup.object().shape({
        value: Yup.string().required("Please select country"),
      }),

      email: Yup.string()
        .required("Please enter email")
        .email("Please enter a valid email"),
      password: Yup.string().min(6, "Password must be atleast 6 characters"),
      confirmPassword: Yup.string()
        .min(6, "Password must be atleast 6 characters")
        .oneOf([Yup.ref("password")], "Passwords must match"),
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string(),
      role: Yup.object().shape({
        value: Yup.number().required("Please select role"),
      }),
    }),
    onSubmit: async (values) => {
      const updatedData = {
        id: selectedRecord.id,
        agency: values["agency"]["value"],
        country: values["country"]["value"],
        role: values["role"]["value"],
        email: values["email"],
        firstName: values["firstName"],
        lastName: values["lastName"],
        countryOffice: values["countryOffice"]["value"],
        password: values["password"] || undefined,
      };

      setLoading(true);
      try {
        await updateUser(updatedData);
        toast.success("User updated successfully.", { autoClose: 500 });
        fetchUpdatedUsers();
        validation.resetForm();
        toggle();
      } catch (e: any) {
        toast.error("Error while updating user.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      isOpen={isModalOpen}
      size="lg"
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update User
      </ModalHeader>

      <UserForm
        validation={validation}
        isUpdate={true}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateUser;
