import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getSortObj } from "common/lib/tableDataOptions";
import { getUSSanctionsList as onGetSanctionsList } from "../../../slices/thunks";
import * as moment from "moment";

const USSanctionsList = () => {
  const dispatch: any = useDispatch();

  const selectLayoutState: any = (state: any) => state.SanctionsList;
  const selectinvoiceProperties = createSelector(
    selectLayoutState,
    (state) => ({
      usSanctionsList: state.usSanctionsList.data,
      meta: state.usSanctionsList.meta,
      error: state.error,
      loading: state.loading,
    })
  );

  const { usSanctionsList, meta, error, loading } = useSelector(
    selectinvoiceProperties
  );
  const [searchValue, setSearchValue] = useState("");

  const [selectedRecord, setSelectedRecord] = useState({});

  const pageSize = 20;

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetSanctionsList({
          pagination: {
            page,
            pageSize: pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: [
              {
                $or: [
                  {
                    title: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    firstName: {
                      $containsi: searchValue,
                    },
                  },

                  {
                    lastName: {
                      $containsi: searchValue,
                    },
                  },
                ],
              },
            ],
          },
        })
      );
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Full Name",
        accessorKey: "title",
        id: "title",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const wrappedText = cell
            .getValue()
            .trim()
            .match(/.{1,80}/g)
            .join("\n");

          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/us/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {wrappedText}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Reference",
        accessorKey: "reference",
        enableColumnFilter: false,
      },
      {
        header: "Programme",
        accessorKey: "programme",
        enableColumnFilter: false,
      },
      {
        header: "Alias Names",
        accessorKey: "aliasNames",
        enableColumnFilter: false,
      },
      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );

  document.title = "US Sanctioned List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="US Sanctions List" pageTitle="Sanctions List" />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={9}>
                  <h5 className="card-title">US Sanctions List</h5>
                  <p className="mb-0 text-muted">
                    The US sanctions list identifies individuals, entities, and
                    countries subject to US government sanctions for activities
                    like terrorism and human rights abuses. It includes
                    restrictions on trade, finance, and travel to enforce US
                    foreign policy and national security objectives.
                    <a
                      href="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information"
                      className="mx-1 text-primary"
                    >
                      Treasury's Sanctions Programs.
                    </a>
                  </p>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search US sanctions list..."
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={usSanctionsList || []}
                customPageSize={pageSize}
                pagination={meta?.pagination || {}}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                parentSearchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default USSanctionsList;
