import React from "react";
import { Col } from "reactstrap";

import ActiveContractValue from "./SummaryWidgets/ActiveContractValue";
import UNAgencies from "./SummaryWidgets/UNAgencies";
import Contractors from "./SummaryWidgets/Contractors";
import Assessments from "./SummaryWidgets/Assessments";

const SummaryWidgets = () => {
  return (
    <React.Fragment>
      <Col xl={3} md={6}>
        <ActiveContractValue />
      </Col>
      <Col xl={3} md={6}>
        <UNAgencies />
      </Col>
      <Col xl={3} md={6}>
        <Contractors />
      </Col>
      <Col xl={3} md={6}>
        <Assessments />
      </Col>
    </React.Fragment>
  );
};

export default SummaryWidgets;
