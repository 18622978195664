import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {
    userId: null,
    email: "",
    firstName: "",
    lastName: "",
    role: {},
    agency: {},
  },
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, { payload }) {
      state.user = {
        userId: payload.id,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        role: payload.role,
        agency: payload.agency,
      };

      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true;
    },
    reset_login_flag(state) {
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  setLoading,
} = loginSlice.actions;

export default loginSlice.reducer;
