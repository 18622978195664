import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

// Persons
export const getPersons = (params: any) => api.get(url.GET_PERSONS, params);
export const getPerson = (id: any, params: any) =>
  api.get(url.GET_PERSON + "/" + id, params);
export const addPerson = (data: any) => api.create(url.ADD_PERSON, data);
export const updatePerson = (data: any) =>
  api.update(url.UPDATE_PERSON + "/" + data.id, data);
export const deletePerson = (data: any) =>
  api.delete(url.DELETE_PERSON + "/" + data.id, data);

export const updatePersonSEMG = (data: any) =>
  api.update(
    url.UPDATE_PERSON_SEMG_MENTION + "/" + data.id + "/semg-mention",
    data
  );

export const updatePersonAffiliationsToContractor = (data: any) =>
  api.update(
    url.UPDATE_PERSON_AFFILIATIONS_TO_CONTRACTOR +
      "/" +
      data.id +
      "/affiliations-to-contractors",
    data
  );
export const updatePersonAffiliationsToPerson = (data: any) =>
  api.update(
    url.UPDATE_PERSON_AFFILIATIONS_TO_PERSON +
      "/" +
      data.id +
      "/affiliations-to-persons",
    data
  );
