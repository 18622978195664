import React, { useMemo } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";

const SelectSensitivity = ({
  validation,
  isUpdate,
  selectedId,
}: {
  validation: any;
  isUpdate?: boolean;
  selectedId?: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.sensitivity && validation.errors.sensitivity
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.sensitivity && validation.errors.sensitivity
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const options = useMemo(() => {
    return [
      { value: null, label: "None" },

      {
        value: "Restricted",
        label: `Restricted`,
      },
      {
        value: "Confidential",
        label: `Confidential`,
      },
    ];
  }, []);

  return (
    <FormGroup>
      <Label htmlFor="sensitivity-field" className="form-label">
        Select sensitivity / classification
      </Label>
      <Select
        name="sensitivity"
        id="sensitivity"
        value={
          validation.values.sensitivity.value
            ? validation.values.sensitivity
            : null
        }
        placeholder="Select sensitivity"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("sensitivity", value);
          validation.setFieldError("sensitivity", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("sensitivity", true)}
        className={
          validation.touched.sensitivity && validation.errors.sensitivity
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.sensitivity?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectSensitivity;
