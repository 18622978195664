import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getRiskAssessments } from "common/services/risk-assessments.service";
import { getCapacityAssessments } from "common/services/capacity-assessments.service";
import { getPvrs } from "common/services/pvr.service";
import { useProfile } from "Components/Hooks/UserHooks";

const Assessments = () => {
  const { userProfile } = useProfile();
  const [riskAssessments, setRiskAssessments] = useState<any>(0);
  const [capacityAssessments, setCapacityAssessments] = useState<any>(0);
  const [pvr, setPvr] = useState<any>(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await getRiskAssessments({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        setRiskAssessments(response.meta?.pagination?.total || 0);
      } catch (e: any) {}
    };
    fetchData();
  }, [userProfile?.agency?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await getCapacityAssessments({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        setCapacityAssessments(response.meta?.pagination?.total || 0);
      } catch (e: any) {}
    };
    fetchData();
  }, [userProfile?.agency?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await getPvrs({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        setPvr(response.meta?.pagination?.total || 0);
      } catch (e: any) {}
    };
    fetchData();
  }, [userProfile?.agency?.id]);

  const totalAssessments = riskAssessments + capacityAssessments + pvr;

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
              Assessments
            </p>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
              <span className="counter-value" data-target="559.25">
                <CountUp
                  start={0}
                  prefix={""}
                  suffix={""}
                  separator={""}
                  end={totalAssessments}
                  decimals={0}
                  duration={2}
                />
              </span>
            </h4>
            <Link to="#" className="text-decoration-underline">
              View Assessments
            </Link>
          </div>
          <div className="avatar-sm flex-shrink-0">
            <span className={"avatar-title rounded fs-3 bg-primary-subtle"}>
              <i className={`text-primary bx bx-file`}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Assessments;
