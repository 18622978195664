import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";

import Header from "./Header";
import MainDetails from "./MainDetails";
import AdditionalDetails from "./AdditionalDetails";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getUSSanctionedItem as onGetUSSanctionedItem } from "../../../../slices/thunks";

const ViewUSSanctionedItem = () => {
  const dispatch: any = useDispatch();

  const { id: itemId } = useParams();

  useEffect(() => {
    dispatch(onGetUSSanctionedItem(itemId));
  }, [itemId, dispatch]);

  const selectLayoutState = (state: any) => state.SanctionsList;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    usSanctionedItem: state.usSanctionedItem,
  }));

  const { usSanctionedItem } = useSelector(selectProperties);

  document.title = "US Sanctioned Item | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Header data={usSanctionedItem} />
          </Row>
          <Row>
            <MainDetails data={usSanctionedItem} />
            <AdditionalDetails data={usSanctionedItem} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewUSSanctionedItem;
