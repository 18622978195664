import { createSlice } from "@reduxjs/toolkit";
import {
  getEUSanctionsList,
  getEUSanctionedItem,
  getUKSanctionsList,
  getUKSanctionedItem,
  getUSSanctionsList,
  getUSSanctionedItem,
  getUNGMList,
  getUNGMItem,
  uploadUNGMList,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  euSanctionsList: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  euSanctionedItem: {},
  ukSanctionsList: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  ukSanctionedItem: {},
  usSanctionsList: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  ungmList: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  ungmItem: {},
  error: {},
  refreshList: false,
  loading: false,
};

const SanctionsListSlice = createSlice({
  name: "SanctionsListSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // EU Sanctions List
    builder.addCase(getEUSanctionsList.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = action.payload?.data.map((item: any) => {
          return {
            ...item.attributes,
            id: item.id,
          };
        });
        state.euSanctionsList.data = data || state.euSanctionsList.data;
      }
      state.euSanctionsList.meta =
        action.payload?.meta || state.euSanctionsList.meta;
      state.loading = false;
    });
    builder.addCase(getEUSanctionsList.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
    builder.addCase(
      getEUSanctionedItem.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload?.data);

          state.euSanctionedItem = data || state.euSanctionedItem;
        }
        state.loading = false;
      }
    );

    builder.addCase(getEUSanctionedItem.rejected, (state: any, action: any) => {
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    // UK Sanctions List
    builder.addCase(getUKSanctionsList.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = action.payload?.data.map((item: any) => {
          return {
            ...item.attributes,

            id: item.id,
          };
        });
        state.ukSanctionsList.data = data || state.ukSanctionsList.data;
      }
      state.ukSanctionsList.meta =
        action.payload?.meta || state.ukSanctionsList.meta;
      state.loading = false;
    });
    builder.addCase(getUKSanctionsList.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    builder.addCase(
      getUKSanctionedItem.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload?.data);

          state.ukSanctionedItem = data || state.ukSanctionedItem;
        }
        state.loading = false;
      }
    );
    builder.addCase(getUKSanctionedItem.rejected, (state: any, action: any) => {
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    // US Sanctions List
    builder.addCase(getUSSanctionsList.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = action.payload?.data.map((item: any) => {
          return {
            ...item.attributes,
            id: item.id,
          };
        });
        state.usSanctionsList.data = data || state.usSanctionsList.data;
      }
      state.usSanctionsList.meta =
        action.payload?.meta || state.usSanctionsList.meta;
      state.loading = false;
    });
    builder.addCase(getUSSanctionsList.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    builder.addCase(
      getUSSanctionedItem.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload?.data);

          state.usSanctionedItem = data || state.usSanctionedItem;
        }
        state.loading = false;
      }
    );
    builder.addCase(getUSSanctionedItem.rejected, (state: any, action: any) => {
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    // UNGM List
    builder.addCase(uploadUNGMList.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("An error ocurred while uploading data", {
        autoClose: 3000,
      });
    });

    builder.addCase(getUNGMList.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);
        state.ungmList.data = data || state.ungmList.data;
      }
      state.ungmList.meta = action.payload?.meta || state.ungmList.meta;
      state.loading = false;
    });
    builder.addCase(getUNGMList.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    builder.addCase(getUNGMItem.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);

        state.ungmItem = data || state.ungmItem;
      }
      state.loading = false;
    });
    builder.addCase(getUNGMItem.rejected, (state: any, action: any) => {
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
  },
});

export const { setLoading } = SanctionsListSlice.actions;

export default SanctionsListSlice.reducer;
