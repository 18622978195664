import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getAgencyContractors } from "common/services/contractors.service";

const Contractors = () => {
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<any>({
    pagination: {
      total: 0,
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getAgencyContractors({});
        setMeta(response.meta);
        setLoading(false);
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
              Contractors
            </p>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
              <span className="counter-value" data-target="559.25">
                <CountUp
                  start={0}
                  prefix={""}
                  suffix={""}
                  separator={""}
                  end={meta?.pagination?.total}
                  decimals={0}
                  duration={4}
                />
              </span>
            </h4>
            <Link
              to="/agency-data/contractors"
              className="text-decoration-underline"
            >
              View Contractors
            </Link>
          </div>
          <div className="avatar-sm flex-shrink-0">
            <span className={"avatar-title rounded fs-3 bg-warning-subtle"}>
              <i className={`text-warning bx bx-user-circle`}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Contractors;
