import React from "react";
import moment from "moment";

const handleValidDate = (date: any) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

const handleValidTime = (time: any) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};

const Rating = (cell: any) => {
  return (
    <React.Fragment>
      {cell.getValue() === "Medium" ? (
        <span className="badge bg-warning-subtle text-warning fs-11">
          {" "}
          <i className="ri-time-line align-bottom"></i> {cell.getValue()}
        </span>
      ) : cell.getValue() === "Low" ? (
        <span className="badge bg-success-subtle text-success fs-11">
          {" "}
          <i className="ri-checkbox-circle-line align-bottom"></i>{" "}
          {cell.getValue()}
        </span>
      ) : cell.getValue() === "High" ? (
        <span className="badge bg-danger-subtle text-danger fs-11">
          {" "}
          <i className="ri-close-circle-line align-bottom"></i>{" "}
          {cell.getValue()}
        </span>
      ) : null}
    </React.Fragment>
  );
};
const Status = (cell: any) => {
  return (
    <React.Fragment>
      <span>
        <span className="badge bg-light text-body fs-12 fw-medium">
          {cell.getValue()}
        </span>
      </span>
    </React.Fragment>
  );
};

const Published = (cell: any) => {
  return (
    <React.Fragment>
      <span>{handleValidDate(cell.getValue())}</span>
    </React.Fragment>
  );
};

const Price = (cell: any) => {
  return <React.Fragment>{"$ " + cell.getValue() + ".00"}</React.Fragment>;
};

export { Rating, Published, Price, Status };
