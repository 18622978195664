import React from "react";

import MainDetails from "./MainDetails";
import Activity from "./Activity";
import { Row } from "reactstrap";

const Overview = ({
  userProfile,
  isUpdateModalOpen,
  setIsUpdateModalOpen,
  setIsChangePasswordModalOpen,
  isChangePasswordModalOpen,
}: {
  userProfile: any;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: (state: any) => void;
  setIsChangePasswordModalOpen: (state: any) => void;
  isChangePasswordModalOpen: boolean;
}) => {
  return (
    <Row>
      <MainDetails
        data={userProfile}
        setIsUpdateModalOpen={setIsUpdateModalOpen}
        isUpdateModalOpen={isUpdateModalOpen}
        setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
        isChangePasswordModalOpen={isChangePasswordModalOpen}
      />
      <Activity data={userProfile} />
    </Row>
  );
};

export default Overview;
