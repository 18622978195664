import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { CARD_MIN_HEIGHT } from "common/constants";
import UpdateUser from "./UpdateProfile";
import { useDispatch } from "react-redux";
import { fetchLoggedInUser } from "slices/thunks";
import ChangePassword from "./ChangePassword";

const MainDetails = ({
  data,
  isUpdateModalOpen,
  setIsUpdateModalOpen,
  setIsChangePasswordModalOpen,
  isChangePasswordModalOpen,
}: {
  data: any;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: (state: any) => void;
  setIsChangePasswordModalOpen: (state: any) => void;
  isChangePasswordModalOpen: boolean;
}) => {
  const dispatch: any = useDispatch();

  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          {/* <CardBody>
            <div className="text-muted">
              <h6 className="mb-2 fw-semibold text-uppercase">
                Assessment Details
              </h6>
            </div>
          </CardBody> */}
          <CardBody style={{ minHeight: CARD_MIN_HEIGHT }}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">First Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.firstName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Last Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.lastName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Phone Number:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.phoneNumber || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Email Address:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.email || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Position / Title:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.title || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">User Role:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.role?.name || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Agency / Organization:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.agency?.name || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Nationality:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.nationality?.name || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Bio:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.bio || ""}
                </Col>
              </Row>

              <div className="pt-3 border-top border-top-dashed mt-4">
                <Row className="gy-3">
                  <Col lg={6} sm={6}>
                    <div>
                      <h6 className="mb-3 fw-semibold text-uppercase">
                        Current Status:
                      </h6>
                      <div className=" fs-12">
                        {!data?.blocked && (
                          <span className="badge text-uppercase bg-success-subtle text-success">
                            Active
                          </span>
                        )}
                        {data?.blocked && (
                          <span className="badge text-uppercase bg-danger-subtle text-danger">
                            Blocked
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <UpdateUser
        toggle={() => setIsUpdateModalOpen((state: any) => !state)}
        isModalOpen={isUpdateModalOpen}
        selectedRecord={data}
        fetchUpdatedUsers={() => {
          dispatch(fetchLoggedInUser());
        }}
      />
      <ChangePassword
        toggle={() => setIsChangePasswordModalOpen((state: any) => !state)}
        isModalOpen={isChangePasswordModalOpen}
        selectedRecord={data}
        fetchUpdatedUsers={() => {
          dispatch(fetchLoggedInUser());
        }}
      />
    </React.Fragment>
  );
};

export default MainDetails;
