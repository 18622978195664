import React, { useMemo } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
} from "reactstrap";

import SelectOrganizationType from "./SelectOrganizationType";
import AliasNamesInput from "./AliasNamesInput";
import ContactPersonsInput from "./ContactPersonsInput";
import ContractorRegistration from "./ContractorRegistration";

const ContractorForm: React.FC<{
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col md={12} className="mb-3">
          <Card>
            {/* <CardHeader>
              <h4 className="card-title mb-0">Contractor Information</h4>
            </CardHeader> */}
            <CardBody>
              <Row>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Contractor Name
                  </Label>
                  <Input
                    name="contractorName"
                    id="contractorName"
                    className="form-control"
                    placeholder="Enter contractor name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contractorName || ""}
                    invalid={
                      validation.touched.contractorName &&
                      validation.errors.contractorName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.contractorName &&
                  validation.errors.contractorName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.contractorName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Acronym
                  </Label>
                  <Input
                    name="acronym"
                    id="acronym"
                    className="form-control"
                    placeholder="Enter acronym"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.acronym || ""}
                    invalid={
                      validation.touched.acronym && validation.errors.acronym
                        ? true
                        : false
                    }
                  />
                  {validation.touched.acronym && validation.errors.acronym ? (
                    <FormFeedback type="invalid">
                      {validation.errors.acronym}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <SelectOrganizationType
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0"> Previous or Alias Name(s)</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} className="mb-3">
                  <AliasNamesInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Business Contact Details</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Business Phone
                  </Label>
                  <Input
                    name="businessPhone"
                    id="businessPhone"
                    className="form-control"
                    placeholder="Enter business phone number"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.businessPhone || ""}
                    invalid={
                      validation.touched.businessPhone &&
                      validation.errors.businessPhone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.businessPhone &&
                  validation.errors.businessPhone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.businessPhone}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Business Email
                  </Label>
                  <Input
                    name="businessEmail"
                    id="businessEmail"
                    className="form-control"
                    placeholder="Enter business email address"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.businessEmail || ""}
                    invalid={
                      validation.touched.businessEmail &&
                      validation.errors.businessEmail
                        ? true
                        : false
                    }
                  />
                  {validation.touched.businessEmail &&
                  validation.errors.businessEmail ? (
                    <FormFeedback type="invalid">
                      {validation.errors.businessEmail}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Business Postal Address
                  </Label>
                  <Input
                    name="businessPostalAddress"
                    id="businessPostalAddress"
                    className="form-control"
                    placeholder="Enter business phone number"
                    type="textarea"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.businessPostalAddress || ""}
                    invalid={
                      validation.touched.businessPostalAddress &&
                      validation.errors.businessPostalAddress
                        ? true
                        : false
                    }
                  />
                  {validation.touched.businessPostalAddress &&
                  validation.errors.businessPostalAddress ? (
                    <FormFeedback type="invalid">
                      {validation.errors.businessPostalAddress}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Business Physical Address
                  </Label>
                  <Input
                    name="businessPhysicalAddress"
                    id="businessPhysicalAddress"
                    className="form-control"
                    placeholder="Enter business phone number"
                    type="textarea"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.businessPhysicalAddress || ""}
                    invalid={
                      validation.touched.businessPhysicalAddress &&
                      validation.errors.businessPhysicalAddress
                        ? true
                        : false
                    }
                  />
                  {validation.touched.businessPhysicalAddress &&
                  validation.errors.businessPhysicalAddress ? (
                    <FormFeedback type="invalid">
                      {validation.errors.businessPhysicalAddress}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={12} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Business Website
                  </Label>
                  <Input
                    name="businessWebsite"
                    id="businessWebsite"
                    className="form-control"
                    placeholder="Enter business phone number"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.businessWebsite || ""}
                    invalid={
                      validation.touched.businessWebsite &&
                      validation.errors.businessWebsite
                        ? true
                        : false
                    }
                  />
                  {validation.touched.businessWebsite &&
                  validation.errors.businessWebsite ? (
                    <FormFeedback type="invalid">
                      {validation.errors.businessWebsite}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Contact Persons</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} className="mb-3">
                  <ContactPersonsInput validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Registration Details</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} className="mb-3">
                  <ContractorRegistration validation={validation} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="mb-1">
                <div className="hstack gap-2 justify-content-between">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {}}
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        color="light"
                        size="sm"
                        style={{ marginRight: "8px", marginBottom: "-1px" }}
                      ></Spinner>
                    )}
                    {isUpdate ? "Update Contractor" : " Add Contractor"}
                  </button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractorForm;
