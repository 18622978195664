import React, { useMemo } from "react";
import {
  Form,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Spinner,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
import Select from "react-select";

const ChangePasswordForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: any;
}> = ({ validation, toggle, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody>
        {true && (
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="id-field" className="form-label">
                  Current Password
                </Label>
                <Input
                  name="currentPassword"
                  id="currentPassword"
                  className="form-control"
                  placeholder="Enter currentPassword"
                  type="password"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.currentPassword || ""}
                  invalid={
                    validation.touched.currentPassword &&
                    validation.errors.currentPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.currentPassword &&
                validation.errors.currentPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.currentPassword}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="id-field" className="form-label">
                  New Password
                </Label>
                <Input
                  name="password"
                  id="password"
                  className="form-control"
                  placeholder="Enter password"
                  type="password"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="id-field" className="form-label">
                  Confirm New Password
                </Label>
                <Input
                  name="confirmPassword"
                  id="confirmPassword"
                  className="form-control"
                  placeholder="Enter password"
                  type="password"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                  invalid={
                    validation.touched.confirmPassword &&
                    validation.errors.confirmPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.confirmPassword}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        )}
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {isUpdate ? "Update User" : " Add User"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
