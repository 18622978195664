import React from "react";
import { Col, Row } from "reactstrap";
import slack from "../../../assets/images/brands/slack.png";
import * as moment from "moment";

const Header = ({ contractor }: { contractor: any }) => {
  return (
    <Row className="mb-3">
      <div className="col-md">
        <Row className="align-items-center g-3">
          <div className="col-md-auto">
            <div className="avatar-md img-thumbnail rounded-circle">
              <div className="avatar-title bg-primary-subtle rounded-circle fs-24 ">
                <i className="ri-building-line text-primary"></i>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div>
              <h4 className="fw-bold">{contractor.contractorName}</h4>
              <div className="hstack gap-3 flex-wrap">
                <div>
                  <i className="ri-building-line align-bottom me-1"></i>{" "}
                  {contractor?.organizationType?.name}
                </div>
                <div className="vr"></div>
                {/* <div>
                  Date Added :{" "}
                  <span className="fw-medium">
                    {moment(new Date(contractor?.createdAt)).format(
                      "DD MMM, Y"
                    )}
                  </span>
                </div>
                <div className="vr"></div>
                <div>
                  Last Updated :{" "}
                  <span className="fw-medium">
                    {moment(new Date(contractor?.updatedAt)).format(
                      "DD MMM, Y"
                    )}
                  </span>
                </div> */}
                <div className="vr"></div>
                {/* <div className="badge  bg-info fs-12">New</div>
                <div className="badge  bg-danger fs-12">High</div> */}
              </div>
            </div>
          </div>
        </Row>
      </div>
      {/* <div className="col-md-auto"> */}
      {/* <Col xs={12} className="col-lg-auto order-last order-lg-0">
        <Row className="text text-center">
          <Col lg={6} xs={4}>
            <div className="p-2">
              <h4 className=" mb-1">3</h4>
              <p className="fs-14 mb-0">SEMG</p>
            </div>
          </Col>
          <Col lg={6} xs={4}>
            <div className="p-2">
              <h4 className=" mb-1">3</h4>
              <p className="fs-14 mb-0">Flagged</p>
            </div>
          </Col>
        </Row>
      </Col> */}
      {/* </div> */}
    </Row>
  );
};

export default Header;
