import React, { useState, useMemo, useCallback } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Card,
  Col,
  CardBody,
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import DeleteModal from "../../../Components/Common/DeleteModal";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getSortObj } from "common/lib/tableDataOptions";
import AddRiskAssessment from "./AddRiskAssessment";
import * as moment from "moment";
import {
  deleteRiskAssessment,
  getRiskAssessments,
} from "common/services/risk-assessments.service";
import { normalize } from "common/lib/normalize";
import UpdateRiskAssessment from "./UpdateRiskAssessment";
import FileIcon from "Components/Common/FileIcon";
import RiskAssessmentRating from "./RiskAssessmentRating";
import Permission from "Components/Common/Permission";
import Flatpickr from "react-flatpickr";
import SelectOverallRatingFilter from "./SelectOverallRatingFilter";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  dateRange: DateRange;
  overallRiskRating: any;
}

const RiskAssessments = (props: any) => {
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const pageSize = 10;

  const fetchData = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
      filterValue: defaultFilterValue,
    });
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (record: any) => {
    setSelectedRecord(record);
    setIsUpdateModalOpen(true);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const defaultFilterValue: FilterValue = {
    overallRiskRating: null,
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  };
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
    filterValue: defaultFilterValue,
  });
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue: FilterValue;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }
      setPageCache({ page, sorted, searchValue, filterValue });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      if (filterValue?.overallRiskRating?.value) {
        andConditions.push({
          overallRiskRating: {
            id: filterValue?.overallRiskRating?.value,
          },
        });
      }

      if (filterValue?.dateRange?.fromDate && filterValue?.dateRange?.toDate) {
        andConditions.push({
          reportDate: {
            $gte: filterValue.dateRange.fromDate,
            $lte: filterValue.dateRange.toDate,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            serialNumber: {
              $containsi: searchValue,
            },
          },
          {
            reportTitle: {
              $containsi: searchValue,
            },
          },
          {
            contractor: {
              contractorName: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      try {
        setLoading(true);
        const response: any = await getRiskAssessments({
          pagination: {
            page,
            pageSize: 10,
          },
          sort: sortObj,
          populate: [
            "contractor",
            "contractor.organizationType",
            "agency",
            "overallRiskRating",
            "conductedBy",
            "reportFile",
          ],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response.meta);
      } catch (err: any) {
        toast.error("Error while fetching data", {
          autoClose: 1000,
        });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const onClickDelete = useCallback(
    (riskAssessment: any) => {
      setSelectedRecord(riskAssessment);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange(pageCache);
  }, [pageCache, onPageChange]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteRiskAssessment(selectedRecord);
        fetchUpdatedData();
        setDeleteModal(false);
      } catch (err: any) {
        toast.error("Error while deleting risk assessment", {
          autoClose: 1000,
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Report Title",
        accessorKey: "reportTitle",
        id: "reportTitle",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          return (
            <>
              <div
                className="d-flex align-items-center"
                style={{
                  // whiteSpace: "pre-wrap",
                  width: "550px",
                }}
              >
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-danger rounded fs-24">
                      <FileIcon
                        fileName={
                          rowData?.reportFile &&
                          Array.isArray(rowData?.reportFile) &&
                          rowData?.reportFile[0]?.name
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <Permission
                      alternative={
                        <Link to={"#"} className="text-body">
                          {cell.getValue()}
                        </Link>
                      }
                      action="findOne"
                      resource="risk-assessment"
                    >
                      <Link
                        to={`/assessments/risk-assessments/${rowData.id}`}
                        className="text-body"
                      >
                        {cell.getValue()}
                      </Link>
                    </Permission>
                  </h5>
                  <p className="text-muted mb-0">
                    Contractor :
                    <span className="fw-medium">
                      {cell.row.original?.contractor?.contractorName}
                    </span>
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        header: "Risk Level",
        accessorKey: "overallRiskRating.name",
        id: "overallRiskRating.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <RiskAssessmentRating
              rating={cell.getValue()}
              solid={false}
              fs={"fs-11"}
            />
          );
        },
      },

      // {
      //   header: "Requesting Agency",
      //   accessorKey: "agency.name",
      //   id: "agency.name",
      //   enableColumnFilter: false,
      // },

      {
        header: "Serial Number",
        accessorKey: "serialNumber",
        id: "serialNumber",
        enableColumnFilter: false,
      },

      {
        header: "Assessment Date",
        accessorKey: "reportDate",
        id: "reportDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{(cell.getValue() && date) || null}</>;
        },
      },
      {
        header: "Action",
        cell: (cell: any) => {
          const rowData = cell.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission action="findOne" resource="risk-assessment">
                  <DropdownItem
                    href={`#`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/assessments/risk-assessments/${rowData.id}`);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission action="update" resource="risk-assessment">
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm(rowData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission action="delete" resource="risk-assessment">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [navigate, onClickDelete]
  );
  document.title = "Risk Assessments | CIMS";

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setDeleteModal(false)}
        loading={deleting}
      />
      <Container fluid>
        <BreadCrumb title="Risk Assessments" pageTitle="Assessments" />

        <Row>
          <Col xl={12} lg={12}>
            <div>
              <Card>
                <CardBody className="border-0">
                  <Row className="align-items-center g-3">
                    <Col md={3}>
                      <h5 className="card-title mb-0">Risk Assessments</h5>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex gap-2">
                        <Permission action="create" resource="risk-assessment">
                          <Link
                            to="/#"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              showAddModalForm();
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add Risk Assessment
                          </Link>
                        </Permission>
                      </div>
                    </div>
                  </Row>
                </CardBody>
                <CardBody>
                  <Row className="mb-3">
                    <React.Fragment>
                      <CardBody className="border border-dashed border-end-0 border-start-0">
                        <form>
                          <Row>
                            <Col sm={4}>
                              <div
                                className={
                                  "search-box me-2 mb-2 d-inline-block col-12"
                                }
                              >
                                <input
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                  }}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control search /"
                                  placeholder={"Search risk assessments"}
                                  value={searchValue || ""}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </Col>
                            <Col sm={3}>
                              <SelectOverallRatingFilter
                                filterValue={filterValue}
                                setFilterValue={setFilterValue}
                              />
                            </Col>
                            <Col sm={3}>
                              <Flatpickr
                                className="form-control"
                                placeholder="Select Date Range"
                                options={{
                                  mode: "range",
                                  altFormat: "d M, Y",
                                  dateFormat: "d M, Y",
                                }}
                                onChange={(e: any) => {
                                  if (e[0] && e[1])
                                    setFilterValue({
                                      ...filterValue,
                                      dateRange: {
                                        fromDate: e[0],
                                        toDate: e[1],
                                      },
                                    });
                                }}
                                value={[
                                  filterValue?.dateRange?.fromDate || "",
                                  filterValue?.dateRange?.toDate || "",
                                ]}
                              />
                            </Col>

                            <Col sm={2}>
                              <button
                                color="primary"
                                className="btn btn-outline-primary material-shadow-none w-100"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSearchValue("");
                                  setFilterValue(defaultFilterValue);
                                }}
                              >
                                <i className="ri-equalizer-line me-1 align-bottom"></i>
                                Reset Filters
                              </button>
                            </Col>
                          </Row>
                        </form>
                      </CardBody>
                    </React.Fragment>
                  </Row>
                  <TableContainer
                    loading={loading}
                    columns={columns}
                    data={data || []}
                    customPageSize={pageSize}
                    pagination={meta?.pagination || {}}
                    onPageChange={onPageChange}
                    FilterSection={() => {}}
                    totalRows={meta?.pagination?.total || 0}
                    parentSearchValue={searchValue}
                    parentFilterValue={filterValue}
                    divClass="table-responsive table-card mb-4"
                    tableClass="align-middle  mb-0"
                    theadClass="table-light table-nowrap"
                  />

                  <AddRiskAssessment
                    toggle={() => setIsAddModalOpen((state) => !state)}
                    isModalOpen={isAddModalOpen}
                    fetchData={fetchData}
                  />
                  <UpdateRiskAssessment
                    toggle={() => setIsUpdateModalOpen((state) => !state)}
                    isModalOpen={isUpdateModalOpen}
                    fetchUpdatedData={fetchUpdatedData}
                    selectedRecord={selectedRecord}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RiskAssessments;

// divClass="table-responsive mb-1"
// tableClass="mb-0 align-middle table-borderless"
// theadClass="table-light text-muted"
