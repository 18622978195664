import { LAYOUT_MODE_TYPES } from "Components/constants/layout";
import * as moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody, Table } from "reactstrap";
import { createSelector } from "reselect";

import SimpleBar from "simplebar-react";

const ContractContent = ({
  contract,
  status,
}: {
  contract: any;
  status: any;
}) => {
  // Select propreties from the state
  const selectLayoutState = (state: any) => state.Layout;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    layoutModeType: state.layoutModeType,
  }));
  const { layoutModeType } = useSelector(selectProperties);

  console.log("layoutModeType", layoutModeType);
  const logoDark =
    (contract?.agency?.mainLogo?.formats?.thumbnail?.url &&
      `${process.env.REACT_APP_FILES_URL}${contract?.agency?.mainLogo?.formats?.thumbnail?.url}`) ||
    "";
  const logoLight =
    (contract?.agency?.mainLogo?.formats?.thumbnail?.url &&
      `${process.env.REACT_APP_FILES_URL}${contract?.agency?.mainLogoLight?.formats?.thumbnail?.url}`) ||
    "";
  const dimensions = contract?.agency?.mainLogoLightDimensions || {};

  const watermarkStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    fontSize: "40px",
    color:
      layoutModeType === LAYOUT_MODE_TYPES.DARKMODE
        ? "rgba(255, 255, 255, 18%)"
        : "rgba(0, 0, 0, 0.15)",
    zIndex: 0,
    pointerEvents: "none",
    whiteSpace: "nowrap",
  };

  return (
    <Row className="justify-content-center">
      <Col xxl={9}>
        <Card>
          {/* @ts-ignore */}
          <div style={watermarkStyle}>This is not a valid contract</div>
          <Row>
            <Col lg={12}>
              <CardHeader className="border-bottom-dashed p-4">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <img
                      src={logoDark}
                      className="card-logo card-logo-dark"
                      alt="logo dark"
                      height={dimensions?.height || "145"}
                      width={dimensions?.width || "190"}
                      style={{
                        maxWidth: "100%",
                        // maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />

                    <img
                      src={logoLight}
                      className="card-logo card-logo-light"
                      alt="logo light"
                      height={dimensions?.height || "145"}
                      width={dimensions?.width || "190"}
                      style={{
                        maxWidth: "100%",
                        // maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className="flex-shrink-0 mt-sm-0 mt-3">
                    <h6>
                      <span className="text-muted fw-normal">
                        For more information, please contact
                        <br /> the Risk Management Unit:
                      </span>{" "}
                    </h6>
                    <h6>
                      <span className="text-muted fw-normal">Email:</span>{" "}
                      <span id="email">all.rmu.so@undp.org</span>
                    </h6>

                    {/* <h6 className="mb-0">
                      <span className="text-muted fw-normal">Contact No:</span>{" "}
                      <span id="contact-no"> +(252) 756 45234</span>
                    </h6> */}
                  </div>
                </div>
              </CardHeader>
            </Col>
            <Col lg={12}>
              <CardBody className="p-4">
                <Row className="g-3">
                  <Col lg={3} xs={6}>
                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                      Contract No
                    </p>
                    <h5 className="fs-14 mb-0">
                      <span id="invoice-no">{contract?.contractNo}</span>
                    </h5>
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                      Contract Period
                    </p>
                    <h5 className="fs-14 mb-0">
                      <span id="invoice-date">
                        {moment(new Date(contract?.startDate)).format(
                          "DD MMM Y"
                        )}
                      </span>{" "}
                      -{" "}
                      <span id="invoice-date">
                        {moment(new Date(contract?.endDate)).format("DD MMM Y")}
                      </span>
                    </h5>
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                      Status
                    </p>

                    <React.Fragment>
                      {status === null ? (
                        <span className="badge bg-warning-subtle text-default fs-11">
                          N/A
                        </span>
                      ) : status ? (
                        <span className="badge bg-success-subtle text-success fs-11">
                          Active
                        </span>
                      ) : status === false ? (
                        <span className="badge bg-danger-subtle text-danger fs-11">
                          Not Active
                        </span>
                      ) : null}
                    </React.Fragment>
                  </Col>
                  <Col lg={3} xs={6}>
                    <p className="text-muted mb-2 text-uppercase fw-semibold">
                      Agency
                    </p>
                    <h5 className="fs-14 mb-0">
                      <span id="agency">{contract?.agency?.name}</span>
                    </h5>
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col lg={12}>
              <CardBody className="p-4 border-top border-top-dashed">
                <Row className="g-3">
                  <Col sm={6}>
                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                      Contractor Details
                    </h6>

                    <div
                      className="table-responsive border border-dashed rounded p-2  mb-3 h-100"
                      // style={{ minHeight: "200px" }}
                    >
                      <table className="table table-sm table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <p className="fw-medium mb-0" id="billing-name">
                                <Link
                                  to={`/contractors/${contract?.contractor?.id}`}
                                  className=""
                                >
                                  <i className="ri-building-line align-bottom me-1"></i>{" "}
                                  {contract?.contractor?.contractorName || ""}
                                </Link>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="fw-medium text-muted"
                              style={{ whiteSpace: "nowrap", width: "20%" }}
                            >
                              {" "}
                              Phone:
                            </td>
                            <td>{contract?.contractor?.businessPhone || ""}</td>
                          </tr>
                          <tr>
                            <td className="fw-medium text-muted">Email:</td>
                            <td>{contract?.contractor?.businessEmail || ""}</td>
                          </tr>
                          <tr>
                            <td className="fw-medium text-muted">Website:</td>
                            <td>
                              {contract?.contractor?.businessWebsite || ""}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                      Additional Details
                    </h6>

                    <div className="table-responsive border border-dashed rounded p-2  mb-3 h-100">
                      <table className="table table-sm table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td
                              className="fw-medium text-muted"
                              style={{ whiteSpace: "nowrap", width: "30%" }}
                            >
                              {" "}
                              Contract Title:
                            </td>
                            <td>{contract?.contractTitle || ""}</td>
                          </tr>
                          <tr>
                            <td className="fw-medium text-muted">Location:</td>
                            <td>
                              {(contract?.location &&
                                contract?.location
                                  ?.map((el: any) => {
                                    return el?.name;
                                  })
                                  .join(", ")) ||
                                "Not Provided"}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium text-muted">
                              Contract Type:
                            </td>
                            <td>{contract?.contractType?.name || "N/A"} </td>
                          </tr>
                          <tr>
                            <td className="fw-medium text-muted">
                              Contract Rating:
                            </td>
                            <td>{contract?.contractRating?.name || "N/A"} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col lg={12}>
              <CardBody className="p-4">
                <div className="table-responsive"></div>
                <div className="border-top border-top-dashed mt-2">
                  <Table
                    className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                    style={{ width: "250px" }}
                  >
                    <tbody>
                      <tr className="border-top border-top-dashed fs-15">
                        <th scope="row">Contract Value</th>
                        <th className="text-end">${contract?.contractValue}</th>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div className="mt-4">
                  <div className="alert alert-info">
                    <p className="mb-0">
                      <span className="fw-semibold">NOTE:</span>
                      <span id="note">
                        {" "}
                        The data provided herein is shared by various agencies
                        and is intended solely for use within the United Nations
                        system. Unauthorized dissemination or distribution of
                        this data outside of the UN system is strictly
                        prohibited.
                      </span>
                    </p>
                  </div>
                </div>
                {/* <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                <Link
                  to="#"
                  onClick={printInvoice}
                  className="btn btn-success"
                >
                  <i className="ri-printer-line align-bottom me-1"></i>{" "}
                  Print
                </Link>
                <Link to="#" className="btn btn-primary">
                  <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                  Download
                </Link>
              </div> */}
              </CardBody>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xxl={3} className="mb-4">
        <Card className="h-100">
          <CardBody className="p-0">
            <div className="p-3">
              <h6 className="text-muted mb-0 text-uppercase fw-semibold">
                Activity
              </h6>
            </div>
            <SimpleBar style={{ maxHeight: "410px" }} className="p-3 pt-0">
              <div className="acitivity-timeline acitivity-main">
                <div className="acitivity-item d-flex">
                  <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                    <div className="avatar-title bg-warning-subtle text-warning rounded-circle">
                      <i className="ri-time-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1 lh-base">Last Updated</h6>
                    <p className="text-muted mb-1">
                      {`By ${contract?.createdby?.firstName} ${contract?.createdby?.lastName} - ${contract?.createdby?.agency?.name}`}
                    </p>
                    <small className="mb-0 text-muted">
                      {moment(new Date(contract?.updatedAt)).format(
                        "DD MMM, Y"
                      )}
                    </small>
                  </div>
                </div>
                <div className="acitivity-item py-3 d-flex">
                  <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle">
                      <i className="ri-calendar-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1 lh-base">Created</h6>
                    <p className="text-muted mb-1">
                      {`By ${contract?.createdby?.firstName} ${contract?.createdby?.lastName} - ${contract?.createdby?.agency?.name}`}
                    </p>
                    <p className="mb-0 text-muted">
                      <small>
                        {moment(new Date(contract?.createdAt)).format(
                          "DD MMM, Y"
                        )}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>

        <Card>
          {/* <CardBody>
              <h5 className="card-title mb-4">Performance Rating</h5>
            </CardBody> */}
        </Card>
      </Col>
    </Row>
  );
};

export default ContractContent;
