import React from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
import logoLight from "assets/images/cims-logo-light.png";
import backgroundImage from "assets/images/signin-main-bg.jpg";

const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col lg={6}>
        <div
          className="p-lg-5 p-4 auth-one-bg h-100"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div
            className="bg-overlay"
            style={{
              background: "rgba(1,78,107, 0.9)",
            }}
          ></div>
          <div className="position-relative h-100 d-flex flex-column">
            <div className="mb-4">
              <Link to="/login" className="d-block">
                <img src={logoLight} alt="" height="70" />
              </Link>
            </div>
            <div className="mt-auto">
              {/* <p className="fs-18 ff-secondary text-white-50">
                The Contractor Information Management System helps UN agencies
                to identify potential risks associated with various activities
                and projects and to evaluate their impact on operations,
                finances, and other areas of concern.
              </p>
              <p className="fs-18 ff-secondary text-white-50">
                CIMS also provides decision-makers with the necessary
                information to make informed decisions on risk mitigation and
                management strategies, including the allocation of resources and
                the implementation of risk-reducing measures.
              </p> */}

              <div className="mb-3">
                <i className="ri-double-quotes-l display-4 text-success"></i>
              </div>

              <Carousel
                showThumbs={false}
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                className="slide"
                // id="qoutescarouselIndicators"
              >
                <div className="carousel-inner text-center text-white-50 pb-5">
                  <div className="item">
                    <p className="fs-18 ff-secondary text-white-50">
                      The Contractor Information Management System helps UN
                      agencies to identify potential risks associated with
                      various activities and projects and to evaluate their
                      impact on operations, finances, and other areas of
                      concern.
                    </p>
                  </div>
                </div>
                <div className="carousel-inner text-center text-white-50 pb-5">
                  <div className="item">
                    <p className="fs-18 ff-secondary text-white-50">
                      CIMS also provides decision-makers with the necessary
                      information to make informed decisions on risk mitigation
                      and management strategies, including the allocation of
                      resources and the implementation of risk-reducing
                      measures.
                    </p>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AuthSlider;
