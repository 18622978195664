import React from "react";

const FromCol = (cell: any) => {
  return <React.Fragment>{cell.getValue()}</React.Fragment>;
};

const ToCol = (cell: any) => {
  return <React.Fragment>{cell.getValue()}</React.Fragment>;
};

const DetailsCol = (cell: any) => {
  return <React.Fragment>{cell.getValue()}</React.Fragment>;
};

const TransactionID = (cell: any) => {
  return <React.Fragment>{cell.getValue()}</React.Fragment>;
};

const TypeCol = (cell: any) => {
  return <React.Fragment>{cell.getValue()}</React.Fragment>;
};

const Status = (cell: any) => {
  return (
    <React.Fragment>
      {cell.getValue() === "Semg Mention" ? (
        <span className="badge bg-warning-subtle text-warning fs-11 me-2">
          {" "}
          <i className="ri-time-line align-bottom"></i> {cell.getValue()}
        </span>
      ) : cell.getValue() === "Not Flagged" ? null : cell.getValue() ===
        "Flagged" ? (
        <span className="badge bg-danger-subtle text-danger fs-11 me-2">
          {" "}
          <i className="ri-close-circle-line align-bottom"></i>{" "}
          {cell.getValue()}
        </span>
      ) : null}
    </React.Fragment>
  );
};

// Danger Badge to indicate entity is flagged by the RMU

const FlaggedByRMU = () => {
  return (
    <React.Fragment>
      <span className="badge bg-danger-subtle text-danger fs-11 me-2">
        {" "}
        <i className="ri-close-circle-line align-bottom"></i> Flagged
      </span>
    </React.Fragment>
  );
};

const SEMGMention = () => {
  return (
    <React.Fragment>
      <span className="badge bg-warning-subtle text-warning fs-11 me-2">
        {" "}
        <i className="ri-alert-line align-bottom"></i> SEMG
      </span>
    </React.Fragment>
  );
};

const Debarred = () => {
  return (
    <React.Fragment>
      <span className="badge bg-danger-subtle text-danger fs-11 me-2">
        {" "}
        <i className="ri-close-circle-line align-bottom"></i> Debarred
      </span>
    </React.Fragment>
  );
};

export {
  Debarred,
  FlaggedByRMU,
  SEMGMention,
  FromCol,
  ToCol,
  DetailsCol,
  TransactionID,
  TypeCol,
  Status,
};
