import BreadCrumb from "Components/Common/BreadCrumb";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DistributionByContractType from "./DistributionByContractType";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const LoadingContainer = () => <div>Loading...</div>;

const ContractsReport = (props: any) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Contracts Report" pageTitle="Reports" />

          <Row>
            <Col md={8}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Distribution of Contracts by Location
                  </h4>
                </CardHeader>
                <CardBody>
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      zoom={5}
                      style={mapStyles}
                      initialCenter={{
                        lat: 3.7184891425479596,
                        lng: 44.45111491590748,
                      }}
                    >
                      <Marker position={{ lat: 48.0, lng: -122.0 }} />
                      <Marker position={{ lat: 34.134117, lng: -118.321495 }} />
                      <Marker position={{ lat: 32.995049, lng: -111.536324 }} />
                      <Marker position={{ lat: 37.383064, lng: -109.071236 }} />
                      <Marker position={{ lat: 39.877586, lng: -79.640617 }} />
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <DistributionByContractType />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyBZQBNrzq5vDg8Yc73rx3wrnxPD9SA5zTs",
  LoadingContainer: LoadingContainer,
  v: "3",
})(ContractsReport);
