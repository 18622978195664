import { useProfile } from "Components/Hooks/UserHooks";
import React from "react";
import { Col, Row } from "reactstrap";

const HeaderSection = (props: any) => {
  const { userProfile } = useProfile();
  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                Welcome back, {`${userProfile?.firstName}`}!
              </h4>
              <p className="text-muted mb-0">
                Here's what's happening in CIMS.
              </p>
            </div>
            <div className="mt-3 mt-lg-0"></div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default HeaderSection;
