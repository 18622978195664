import React from "react";

const CIMSReports = () => {
    return (
        <div>
            <h1>CIMSReports</h1>
        </div>
    );
}

export default CIMSReports;