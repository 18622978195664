import React from "react";
import { Label, Input, FormFeedback, Button, Card, CardBody } from "reactstrap";

interface AliasNamesInputProps {
  validation: any; // Replace 'any' with the appropriate type for the 'validation' parameter
}

const AliasNamesInput = ({ validation }: AliasNamesInputProps) => {
  return (
    <>
      {validation.values.previousOrAliasName.map(
        (name: string, index: number) => (
          <>
            <Card className="bg-light">
              <CardBody>
                <div key={index} className="mb-3 ">
                  <Label
                    htmlFor={`previousOrAliasName-${index}`}
                    className="form-label"
                  >
                    Alias Name
                  </Label>
                  <Input
                    name={`previousOrAliasName.${index}`}
                    id={`previousOrAliasName.${index}`}
                    className="form-control"
                    placeholder="Enter alias name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={name}
                    invalid={
                      validation.touched.previousOrAliasName &&
                      validation.touched.previousOrAliasName[index] &&
                      validation.errors.previousOrAliasName &&
                      validation.errors.previousOrAliasName[index]
                        ? true
                        : false
                    }
                  />
                  {validation.touched.preiousOrAliasName &&
                  validation.touched.previousOrAliasName[index] &&
                  validation.errors.previousOrAliasName &&
                  validation.errors.previousOrAliasName[index] ? (
                    <FormFeedback type="invalid">
                      {validation.errors?.previousOrAliasName &&
                        validation.errors.previousOrAliasName[index]}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3 d-flex justify-content-end">
                  <Button
                    className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                    onClick={() => {
                      const names = validation.values.previousOrAliasName;
                      names.splice(index, 1);
                      validation.setFieldValue("previousOrAliasName", names);
                    }}
                  >
                    {/* <i className="ri-delete-bin-fill me-2"></i> */}
                    Remove
                  </Button>
                </div>
              </CardBody>
            </Card>
          </>
        )
      )}
      <button
        className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
        onClick={() => {
          if (validation.values.previousOrAliasName.length < 5) {
            validation.setFieldValue("previousOrAliasName", [
              ...validation.values.previousOrAliasName,
              "",
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default AliasNamesInput;
