import React, { useEffect } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getAgencyUsers } from "common/services/users.service";

const Users = () => {
  const [loading, setLoading] = React.useState(false);
  const [meta, setMeta] = React.useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await getAgencyUsers({
          pagination: {
            page: 1,
            pageSize: 100,
          },
        });
        setMeta(response.meta);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
              Users
            </p>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
              <span className="counter-value" data-target="559.25">
                <CountUp
                  start={0}
                  prefix={""}
                  suffix={""}
                  separator={","}
                  end={meta?.pagination?.total}
                  decimals={0}
                  duration={4}
                />
              </span>
            </h4>
            <Link to="/agency/users" className="text-decoration-underline">
              View users
            </Link>
          </div>
          <div className="avatar-sm flex-shrink-0">
            <span className={"avatar-title rounded fs-3 bg-info-subtle"}>
              <i className={`text-info bx bx-shopping-bag`}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Users;
