import React, { useEffect, useMemo } from "react";
import AssessmentItem from "../Items/AssessmentItem";
import ContractItem from "../Items/ContractItem";
import ContractorsItem from "../Items/ContractorsItem";
import PersonItem from "../Items/PersonItem";
import SanctionItem from "../Items/SanctionItem";
import { searchGlobalContent } from "common/services/dd-search.service";
import PaginationFooter from "./PaginationFooter";
import LoadingItem from "../Items/LoadingItem";
import { determineEntityType } from "common/lib/entityType";

const AllResults = ({ searchValue }: { searchValue: string }) => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  useEffect(() => {
    const search = async () => {
      try {
        setLoading(true);
        const response: any = await searchGlobalContent({
          query: searchValue,
          pageSize: 10,
          page: page,
        });
        setSearchResults(response.hits);
        setPage(response?.page);
        setTotalPages(response?.totalPages);
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    // if (searchValue)
    search();
  }, [searchValue, page]);

  if (loading) {
    return Array.from({ length: 10 }).map((_, index) => (
      <div key={index}>
        {index !== 0 && <div className="border border-dashed"></div>}
        <LoadingItem key={index} />
      </div>
    ));
  }

  if (searchResults.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center border border-dashed p-2 rounded">
        <div className="text-muted text-center pt-5 pb-5 mt-5 mb-5">
          No Results Found
        </div>
      </div>
    );
  }

  return (
    <>
      {searchResults.map((result: any, index: number) => {
        const entityType: string = determineEntityType(result._meilisearch_id);
        if (entityType === "contractor") {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <ContractorsItem data={result} index={index} />
            </div>
          );
        }
        if (entityType === "contract") {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <ContractItem data={result} index={index} />
            </div>
          );
        }

        if (
          entityType === "risk-assessment" ||
          entityType === "capacity-assessment" ||
          entityType === "partner-verification-report"
        ) {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <AssessmentItem
                data={result}
                index={index}
                entityType={entityType}
              />
            </div>
          );
        }
        if (
          entityType === "eu-sanctions-list" ||
          entityType === "us-sanctions-list" ||
          entityType === "uk-sanctions-list" ||
          entityType === "un-designated-list" ||
          entityType === "ungm-list"
        ) {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <SanctionItem
                data={result}
                index={index}
                entityType={entityType}
              />
            </div>
          );
        }
      })}
      {/* 
      <SanctionItem />

      <div className="border border-dashed"></div>

      <PersonItem />

      <div className="border border-dashed"></div>

      <AssessmentItem /> */}

      <PaginationFooter
        page={page}
        totalPages={totalPages}
        onPageChange={(page: number) => setPage(page)}
      />
    </>
  );
};

export default AllResults;
