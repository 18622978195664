import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import AllContracts from "./AllContracts";

const Contracts = () => {
  document.title = "Contracts | CIMS";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Contracts"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />

          <AllContracts isViewContractors={false} isAgencyDashboard={false} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contracts;
