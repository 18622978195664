import {
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Form,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useRef, useEffect } from "react";
import SimpleBar from "simplebar-react";
import SelectAffiliatedTo from "./SelectAffiliatedTo";
import SelectRelationshipStatus from "../AffiliationsToPerson/SelectRelationshipStatus";

interface AffiliationsToPersonsProps {
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
}

const AffiliationsToContractorsForm = ({
  validation,
  toggle,
  loading,
}: AffiliationsToPersonsProps) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <Row>
          <Col md={12} className="mb-3">
            <SimpleBar
              ref={simpleBarRef}
              style={{ maxHeight: "70vh" }}
              className=" px-4 py-4"
              autoHide={false}
            >
              {validation.values.affiliations.map(
                (director: any, index: number) => {
                  return (
                    <Card className="bg-light" key={index}>
                      <CardBody>
                        <Row>
                          <Col md={6} className="mb-3">
                            <SelectAffiliatedTo
                              validation={validation}
                              index={index}
                            />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`natureOfRelationship-${index}`}
                              className="form-label"
                            >
                              Nature of Relationship
                            </Label>
                            <Input
                              name={`affiliations[${index}].natureOfRelationship`}
                              id={`natureOfRelationship-${index}`}
                              className="form-control"
                              placeholder="Enter nature of relationship"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={director.natureOfRelationship}
                              invalid={
                                validation.touched.affiliations?.[index]
                                  ?.natureOfRelationship &&
                                !!validation.errors.affiliations?.[index]
                                  ?.natureOfRelationship
                              }
                            />
                            {validation.touched.affiliations?.[index]
                              ?.natureOfRelationship &&
                              validation.errors.affiliations?.[index]
                                ?.natureOfRelationship && (
                                <FormFeedback>
                                  {
                                    validation.errors.affiliations[index]
                                      .natureOfRelationship
                                  }
                                </FormFeedback>
                              )}
                          </Col>

                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`informationSource-${index}`}
                              className="form-label"
                            >
                              Information Source
                            </Label>
                            <Input
                              name={`affiliations[${index}].informationSource`}
                              id={`informationSource-${index}`}
                              className="form-control"
                              placeholder="Enter information source"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={director.informationSource}
                              invalid={
                                validation.touched.affiliations?.[index]
                                  ?.informationSource &&
                                !!validation.errors.affiliations?.[index]
                                  ?.informationSource
                              }
                            />
                            {validation.touched.affiliations?.[index]
                              ?.informationSource &&
                              validation.errors.affiliations?.[index]
                                ?.informationSource && (
                                <FormFeedback>
                                  {
                                    validation.errors.affiliations[index]
                                      .informationSource
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={6} className="mb-3">
                            <SelectRelationshipStatus
                              validation={validation}
                              index={index}
                            />
                          </Col>

                          <div className="mb-3 d-flex justify-content-end">
                            <Button
                              className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                              onClick={() => {
                                validation.setFieldValue(
                                  "affiliations",
                                  validation.values.affiliations.filter(
                                    (_: any, bodIndex: number) =>
                                      bodIndex !== index
                                  )
                                );
                              }}
                            >
                              {/* <i className="ri-delete-bin-fill me-2"></i> */}
                              Remove
                            </Button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                }
              )}
              <button
                className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
                disabled={validation.values.affiliations.length === 3}
                onClick={(e) => {
                  e.preventDefault();

                  const newAffiliation = {
                    affiliatedTo: {
                      value: "",
                      label: "",
                    },
                    natureOfRelationship: "",
                    informationSource: "",
                    relationshipConfirmed: {
                      value: false,
                      label: "Not Confirmed",
                    },
                  };

                  const affiliations = validation.values.affiliations;
                  validation.setFieldValue("affiliations", [
                    ...affiliations,
                    newAffiliation,
                  ]);
                }}
              >
                Add
              </button>
            </SimpleBar>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default AffiliationsToContractorsForm;
