import { createSlice } from "@reduxjs/toolkit";
import { getRevenueChartsData } from "./thunk";
export const initialState: any = {
  revenueData: [],
  error: {},
};
const DashboardEcommerceSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRevenueChartsData.fulfilled, (state, action) => {
      state.revenueData = action.payload;
    });
    builder.addCase(getRevenueChartsData.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});
export default DashboardEcommerceSlice.reducer;
