import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getContractTypes as onGetContractTypes } from "../../slices/thunks";

type ContractTypeFilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const ContractTypeFilter = ({ onChange, value }: ContractTypeFilterProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetContractTypes({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Contracts;
  const selectVehicleMakeProperties = createSelector(
    selectLayoutState,
    (state) => ({
      contractTypes: state.contractTypes.data,
    })
  );

  const { contractTypes } = useSelector(selectVehicleMakeProperties);

  const options = useMemo(() => {
    return contractTypes.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [contractTypes]);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default2"
      id="choices-single-default2"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      {loading && <option>Loading...</option>}
      <option value="">Select Contract Type</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default ContractTypeFilter;
