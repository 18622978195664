import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getOrganizationTypes as onGetOrganizationTypes } from "slices/thunks";

type FilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const SelectOrganizationTypeFilter = ({ onChange, value }: FilterProps) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetOrganizationTypes({
        pagination: {
          page: 1,
          pageSize: 100,
        },
        fields: ["name"],
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.OrganizationTypes;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    organizationTypes: state.organizationTypes.data,
  }));

  const { organizationTypes } = useSelector(selectProperties);

  const options = useMemo(() => {
    return organizationTypes.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
  }, [organizationTypes]);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default2"
      id="choices-single-default2"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      <option value="">Select Organization Type</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectOrganizationTypeFilter;
