import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { addCountryOffice as onAddCountryOffice } from "../../slices/thunks";
import CountryOfficeForm from "./CountryOfficeForm";

const AddCountryOffice: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchAgencies: () => void;
}> = ({ toggle, isModalOpen, fetchAgencies }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      countries: [],
      // agency: {
      //   label: "",
      //   value: "",
      // },
      // country: {
      //   value: "",
      //   label: "",
      // },
      // role: {
      //   value: "",
      //   label: "",
      // },
      // email: "",
      // firstName: "",
      // lastName: "",
      // password: "",
      // confirmPassword: "",
      blocked: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      // agency: Yup.object().shape({
      //   value: Yup.number().required("Please select agency"),
      // }),
      // country: Yup.object().shape({
      //   value: Yup.string().required("Please select country"),
      // }),
      // confirmPassword: Yup.string()
      //   .min(6, "Password must be atleast 6 characters")
      //   .required("Please confirm password")
      //   .oneOf([Yup.ref("password")], "Passwords must match"),
      // email: Yup.string()
      //   .required("Please enter email")
      //   .email("Please enter a valid email"),
      // firstName: Yup.string().required("Please enter first name"),
      // lastName: Yup.string(),
      // role: Yup.object().shape({
      //   value: Yup.number().required("Please select role"),
      // }),
      countries: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required("Country is required"),
            value: Yup.string().required("Country is required"),
          })
        )
        .min(1, "Select at least one country")
        .required("Country is required"),
    }),
    onSubmit: (values) => {
      const countries = values["countries"].map((el: any) => el.value);

      const data = {
        name: values["name"],
        countries,
        // agency: values["agency"]["value"],
        // country: values["country"]["value"],
        // role: values["role"]["value"],
        // email: values["email"],
        // firstName: values["firstName"],
        // lastName: values["lastName"],
        // password: values["password"],
        blocked: values["blocked"],
      };

      setLoading(true);
      dispatch(
        onAddCountryOffice({
          data,
        })
      ).then((result: any) => {
        setLoading(false);

        if (result?.payload?.data) {
          fetchAgencies();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Country Office
      </ModalHeader>

      <CountryOfficeForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddCountryOffice;
