import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, Collapse, CardBody } from "reactstrap";
import UpdateSEMGMention from "./UpdateSEMGMention";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getPerson as onGetPerson } from "slices/thunks";
import Skeleton from "react-loading-skeleton";

const LoadingSkeleton = () => {
  return (
    <div className="table-responsive border border-dashed rounded p-2 mb-3">
      <table className="table table-sm table-borderless mb-0">
        <tbody>
          <tr>
            <td
              className="fw-medium"
              style={{ whiteSpace: "nowrap", width: "30%" }}
            >
              <Skeleton width="100%" />
            </td>
            <td>
              <Skeleton width="100%" />
            </td>
          </tr>
          <tr>
            <td className="fw-medium">
              {" "}
              <Skeleton width="100%" />
            </td>
            <td>
              <Skeleton width="100%" />
            </td>
          </tr>
          <tr>
            <td className="fw-medium">
              {" "}
              <Skeleton width="100%" />
            </td>
            <td>
              <Skeleton width="100%" />
            </td>
          </tr>
          <tr>
            <td className="fw-medium">
              {" "}
              <Skeleton width="100%" />
            </td>
            <td>
              <Skeleton width="100%" />
            </td>
          </tr>
          <tr>
            <td className="fw-medium">
              {" "}
              <Skeleton width="100%" />
            </td>
            <td>
              <Skeleton width="100%" />
            </td>
          </tr>
          <tr>
            <td className="fw-medium">
              {" "}
              <Skeleton width="100%" />
            </td>
            <td>
              <Skeleton width="100%" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const SEMGMention = ({ person }: { person: any }) => {
  const dispatch: any = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const selectProperties = createSelector(
    (state: any) => state.Persons,
    (state) => ({
      semgMentions: state.person?.semgMentions,
      loading: state.loading,
    })
  );
  const { semgMentions, loading } = useSelector(selectProperties);

  return (
    <Card>
      <CardHeader
        className={`d-flex align-items-center ${
          isOpen ? "border-bottom-dashed" : ""
        }`}
        onClick={toggleCollapse}
        style={{ cursor: "pointer" }}
      >
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">
            SEMG Mentions
            <small
              color="light"
              className="badge bg-primary-subtle align-bottom ms-1 text-primary"
            >
              {(semgMentions && semgMentions?.length) || 0}
            </small>
          </h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Link
            to="#"
            onClick={(e) => {
              e.stopPropagation(); // Prevent collapse toggle on edit click
              showUpdateModal();
            }}
            className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
          >
            <i className="ri-pencil-line"></i> Edit
          </Link>
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="card-body">
          {loading && [0, 0].map((_, index) => <LoadingSkeleton key={index} />)}
          {semgMentions &&
            !loading &&
            semgMentions?.map((item: any, index: number) => (
              <div
                className="table-responsive  border border-dashed rounded p-2 mb-3"
                key={index}
              >
                <table className="table table-sm table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td
                        className="fw-medium"
                        style={{ whiteSpace: "nowrap", width: "30%" }}
                      >
                        SEMG Report No:
                      </td>
                      <td>{item?.reportNumber || ""}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium"> SEMG Page:</td>
                      <td>{item?.SEMGPage || ""}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">SEMG Paragraph:</td>
                      <td>{item?.SEMGParagraph || ""}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">SEMG Section:</td>
                      <td>{item?.SEMGSection || ""} </td>
                    </tr>

                    <tr>
                      <td className="fw-medium">SEMG Commentary:</td>
                      <td>{item?.SEMGCommentary || ""}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">SEMG Location/Region:</td>
                      <td>{item?.SEMGRegion || ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
        </CardBody>

        <UpdateSEMGMention
          toggle={() => setIsUpdateModalOpen((state) => !state)}
          isModalOpen={isUpdateModalOpen}
          fetchData={() => {
            dispatch(
              onGetPerson({
                id: person?.id,
              })
            );
          }}
          personId={person?.id}
          data={semgMentions || []}
        />
      </Collapse>
    </Card>
  );
};

export default SEMGMention;
