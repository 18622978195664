import { useProfile } from "Components/Hooks/UserHooks";
import React from "react";

interface PermissionProps {
  resource: string;
  action: string;
  children?: React.ReactNode;
  category?: string;
  controller?: string;
  alternative?: React.ReactNode;
}

const Permission = ({
  resource,
  action,
  children,
  category,
  controller,
  alternative,
}: PermissionProps) => {
  const { userProfile } = useProfile();
  const permissions = userProfile?.permissions;

  const isAllowed =
    permissions?.[`${category || "api"}::${resource}`]?.controllers?.[
      controller || resource
    ]?.[action]?.enabled;

  if (!isAllowed) {
    if (alternative) {
      return <>{alternative}</>;
    }
    return null;
  }

  return <>{children}</>;
};

export default Permission;
