import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col, Table, CardHeader, Alert } from "reactstrap";
import { createSelector } from "reselect";
import UpdatePerson from "./UpdatePerson";
import { getPerson as onGetPerson } from "slices/thunks";
import moment from "moment";

const Summary = ({ person }: { person: any }) => {
  const selectProperties = createSelector(
    (state: any) => state.Contractors,
    (state) => ({
      performanceRatings: state.person?.performanceRatings || [],
      loading: state.loading,
    })
  );

  const dispatch: any = useDispatch();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center border-bottom-dashed">
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">Summary</h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Link
            to="#"
            onClick={(e) => {
              e.stopPropagation(); // Prevent collapse toggle on edit click
              showUpdateModal();
            }}
            className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
          >
            <i className="ri-pencil-line"></i> Edit
          </Link>
        </div>
      </CardHeader>
      <CardBody className="card-body">
        <Row>
          <Col md={12}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Full Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {`${person?.firstName || ""} ${person?.middleName || ""} ${
                    person?.surname || ""
                  }`}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Title:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {person?.title || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">First Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {person?.firstName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Middle Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {person?.middleName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Surname/Last Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {`${person?.surname || ""}`}
                </Col>
              </Row>

              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Gender:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {person?.gender || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Current Nationality:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {person?.nationalityAtBirth?.name || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Nationality At Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {person?.nationalityAtBirth?.name || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Date of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {(person?.dateOfBirth &&
                    moment(new Date(person?.dateOfBirth)).format(
                      "DD MMM, Y"
                    )) ||
                    ""}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </CardBody>
      <UpdatePerson
        isModalOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen((state) => !state)}
        person={person}
        fetchData={() => {
          dispatch(
            onGetPerson({
              id: person?.id,
            })
          );
        }}
      />
    </Card>
  );
};

export default Summary;
