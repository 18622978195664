import React from "react";
import { Col } from "reactstrap";
import Users from "./Widgets/Users";
import ActiveContractValue from "./Widgets/ActiveContractValue";
import Contractors from "./Widgets/Contractors";
import Assessments from "./Widgets/Assessments";

const Widgets = () => {
  return (
    <React.Fragment>
      <Col xl={3} md={6}>
        <Users />
      </Col>
      <Col xl={3} md={6}>
        <ActiveContractValue />
      </Col>
      <Col xl={3} md={6}>
        <Contractors />
      </Col>
      <Col xl={3} md={6}>
        <Assessments />
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
