import * as moment from "moment";

export function isContractActive(startDate: string, endDate: string) {
  if (!startDate || !endDate) {
    return null;
  }
  const currentDate = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return currentDate.isBetween(start, end, null, "[]");
}
