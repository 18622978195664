import { createSlice } from "@reduxjs/toolkit";
import {
  getContractors,
  getContractor,
  addContractor,
  updateContractor,
  deleteContractor,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  contractors: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  contractor: {
    acronym: "",
    businessEmail: "",
    businessPhone: "",
    businessWebsite: "",
    businessPhysicalAddress: "",
    businessPostalAddress: "",
    contactPersons: [],
    contractorName: "",
    contractorRegistration: [],
    createdAt: "",
    createdBy: "",
    id: "",
    organizationType: "",
    previousOrAliasName: [],
    updatedAt: "",
    updatedBy: "",
    rmuFlag: false,
  },
  performanceRatings: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const ContractorsSlice = createSlice({
  name: "ContractorsSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContractors.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload.data);
        state.contractors.data = data || state.contractors.data;
      }
      state.contractors.meta = action.payload?.meta || state.contractors.meta;
      state.loading = false;
    });
    builder.addCase(getContractors.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    builder.addCase(getContractor.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload.data);
        state.contractor = data || state.contractor.data;
      }
      state.loading = false;
    });
    builder.addCase(getContractor.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching contractor", {
        autoClose: 1000,
      });
    });

    builder.addCase(addContractor.fulfilled, () => {
      toast.success("Contractor Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addContractor.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Contractor Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateContractor.fulfilled, () => {
      toast.success("Contractor Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(updateContractor.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Contractor Update Failed", { autoClose: 3000 });
    });
    builder.addCase(deleteContractor.fulfilled, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.success("Contractor Deleted Successfully", { autoClose: 3000 });
    });
    builder.addCase(deleteContractor.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
      toast.error("Contractor Deletion Failed", { autoClose: 3000 });
    });
  },
});

export const { setLoading } = ContractorsSlice.actions;

export default ContractorsSlice.reducer;
