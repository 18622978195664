import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Summary from "./Summary";
import ContactDetails from "./ContactDetails";
import ContractorRegistration from "./ContractorRegistration";
import Comments from "./Comments";
import SimpleBar from "simplebar-react";
import * as moment from "moment";
import WorkHistory from "./WorkHistory";

import AffiliationsToContractor from "./AffiliationsToContractor";
import AffiliationsToPerson from "./AffiliationsToPerson";
import SEMGMention from "./SEMGMention";
import AliasNames from "./AliasNames";

const OverviewTab = ({
  person,
  toggleTab,
}: {
  person: any;
  toggleTab: (tab: any) => void;
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col xxl={9}>
          <Summary person={person} />

          <AliasNames person={person} />

          <ContactDetails person={person} />

          {/* <ContractorRegistration person={person} /> */}

          <WorkHistory person={person} />

          <AffiliationsToContractor person={person} />

          <AffiliationsToPerson person={person} />

          <SEMGMention person={person} />

          {/* <Comments /> */}
        </Col>

        <Col xxl={3} className="mb-4">
          <Card className="h-100">
            <CardBody className="p-0">
              <div className="p-3">
                <h6 className="text-muted mb-0 text-uppercase fw-semibold">
                  Activity
                </h6>
              </div>
              <SimpleBar style={{ maxHeight: "410px" }} className="p-3 pt-0">
                <div className="acitivity-timeline acitivity-main">
                  <div className="acitivity-item d-flex">
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-warning-subtle text-warning rounded-circle">
                        <i className="ri-time-line"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-1 lh-base">Last Updated</h6>
                      <p className="text-muted mb-1">
                        {`By ${person?.createdby?.firstName} ${person?.createdby?.lastName} - ${person?.createdby?.agency?.name}`}
                      </p>
                      <small className="mb-0 text-muted">
                        {moment(new Date(person?.updatedAt)).format(
                          "DD MMM, Y"
                        )}
                      </small>
                    </div>
                  </div>
                  <div className="acitivity-item py-3 d-flex">
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-primary-subtle text-primary rounded-circle">
                        <i className="ri-calendar-line"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-1 lh-base">Created</h6>
                      <p className="text-muted mb-1">
                        {`By ${person?.createdby?.firstName} ${person?.createdby?.lastName} - ${person?.createdby?.agency?.name}`}
                      </p>
                      <p className="mb-0 text-muted">
                        <small>
                          {moment(new Date(person?.createdAt)).format(
                            "DD MMM, Y"
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </SimpleBar>
            </CardBody>
          </Card>

          <Card>
            {/* <CardBody>
              <h5 className="card-title mb-4">Performance Rating</h5>
            </CardBody> */}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OverviewTab;
