import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useState } from "react";
import { Container } from "reactstrap";
import ContractorForm from "./ContractorForm";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addContractor as onAddContractor } from "../../slices/thunks";
import { useNavigate } from "react-router-dom";
import { LEVEL_COUNTRY } from "common/constants";

const AddContractor = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractorName: "",
      organizationType: {
        value: "",
        label: "",
      },
      acronym: "",
      previousOrAliasName: [""],
      businessPhone: "",
      businessEmail: "",
      businessWebsite: "",
      businessPhysicalAddress: "",
      businessPostalAddress: "",
      contactPersons: [
        {
          name: "",
          emailAddress: "",
          mobileNumber: "",
          telephone: "",
          websiteOrLink: "",
          physicalAddress: "",
        },
      ],
      contractorRegistration: [
        {
          registrationName: "",
          registrationType: "",
          registrationNumber: "",
          lengthOfValidity: "",
          countryOfRegistration: "",
          registrationDate: "",
        },
      ],
    },
    validationSchema: Yup.object({
      contractorName: Yup.string().required("Please enter contractor name"),
      acronym: Yup.string().required("Please enter acronym"),
      organizationType: Yup.object().shape({
        value: Yup.number().required("Please select organization type"),
      }),
      previousOrAliasName: Yup.array().of(Yup.string()),
      businessPhone: Yup.string(),
      businessEmail: Yup.string(),
      businessWebsite: Yup.string(),
      businessPhysicalAddress: Yup.string(),
      businessPostalAddress: Yup.string(),
      contactPersons: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Name is required"),
          emailAddress: Yup.string(),
          mobileNumber: Yup.string(),
          telephone: Yup.string(),
          websiteOrLink: Yup.string(),
          physicalAddress: Yup.string(),
        })
      ),
      contractorRegistration: Yup.array().of(
        Yup.object({
          registrationName: Yup.string().required(
            "Registration name is required"
          ),
          registrationType: Yup.string().required(
            "Registration type is required"
          ),
          registrationNumber: Yup.string().required(
            "Registration number is required"
          ),
          lengthOfValidity: Yup.string(),
          countryOfRegistration: Yup.string().required(
            "Country of registration is required"
          ),
          registrationDate: Yup.string(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        data: {
          contractorName: values["contractorName"],
          acronym: values["acronym"],
          organizationType: values["organizationType"]["value"] || null,
          previousOrAliasName:
            Array.isArray(values["previousOrAliasName"]) &&
            values["previousOrAliasName"].length > 0
              ? values["previousOrAliasName"].map((el) => {
                  return {
                    name: el,
                  };
                })
              : null,
          businessPhone: values["businessPhone"],
          businessEmail: values["businessEmail"],
          businessWebsite: values["businessWebsite"],
          businessPhysicalAddress: values["businessPhysicalAddress"],
          businessPostalAddress: values["businessPostalAddress"],
          contactPersons: values["contactPersons"],
          contractorRegistration: values["contractorRegistration"],
        },
      };
      setLoading(true);
      dispatch(
        onAddContractor({
          level: LEVEL_COUNTRY,
          params: data,
        })
      ).then((result: any) => {
        setLoading(false);
        if (result?.payload?.data) {
          navigate("/contractors/" + result?.payload?.data?.id);
        }
      });
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Add Contractor" pageTitle="Contractors" />

        <ContractorForm
          validation={validation}
          isUpdate={false}
          loading={loading}
          setLoading={setLoading}
        />
      </Container>
    </div>
  );
};

export default AddContractor;
