/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © CIMS Somalia.</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Maintained by the{" "}
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  className="text-reset"
                >
                  Risk Management Unit
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
