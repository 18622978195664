import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {} from "../../helpers/backend_helper";

export const getRevenueChartsData = createAsyncThunk(
  "dashboardEcommerce/getRevenueChartsData",
  async (data: any) => {
    try {
      var response;

      return response;
    } catch (error) {
      return error;
    }
  }
);
