import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  Row,
  UncontrolledDropdown,
  ModalBody,
  ModalHeader,
} from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

const TeamTab = () => {
  //Modal
  const [modal, setModal] = useState<boolean>(false);
  const openModal = () => setModal(!modal);
  return (
    <React.Fragment>
      <Row className="g-4 mb-3"></Row>
    </React.Fragment>
  );
};

export default TeamTab;
