import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import AllAgencyContracts from "./AllAgencyContracts";
import { useProfile } from "Components/Hooks/UserHooks";

const Contracts = () => {
  const { userProfile } = useProfile();

  document.title = `${userProfile?.agency?.name || ""} Contracts | CIMS`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`${userProfile?.agency?.name || ""} Contracts`}
            pageTitle="Dashboard"
          />

          <AllAgencyContracts
            isViewContractors={false}
            isAgencyDashboard={false}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contracts;
