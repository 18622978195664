import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  CardHeader,
} from "reactstrap";
import UpdateContactDetails from "./UpdateContactDetails";
import { getPerson as onGetPerson } from "slices/thunks";

const ContactDetails = ({ person }: { person: any }) => {
  const [openFlush, setOpenFlush] = useState("1");
  const toggleFlush = (id: any) => {
    if (openFlush !== id) {
      setOpenFlush(id);
    }
  };

  const dispatch: any = useDispatch();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center">
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">Contact Details</h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Link
            to="#"
            onClick={(e) => {
              e.stopPropagation(); // Prevent collapse toggle on edit click
              showUpdateModal();
            }}
            className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
          >
            <i className="ri-pencil-line"></i> Edit
          </Link>
        </div>
      </CardHeader>

      <CardBody className="card-body px-0 pt-0">
        <Row className="g-3">
          <Accordion
            id="default-accordion-example"
            flush
            open={openFlush}
            toggle={toggleFlush}
          >
            {person?.contactDetails?.map((el: any, index: number) => {
              const actualId = `${index + 1}`;
              const contactDetailCount = index + 1;
              return (
                <AccordionItem className="material-shadow" key={index}>
                  <AccordionHeader targetId={actualId}>
                    Contact Detail {contactDetailCount}
                  </AccordionHeader>
                  <AccordionBody accordionId={actualId}>
                    <Row>
                      <Col sm={6}>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-danger rounded fs-24">
                                <i className="ri-user-line text-primary"></i>
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1 fs-14"> {el.name}</h6>
                            <p
                              className="text-muted mb-1"
                              id="shipping-address-line-1"
                            >
                              <span className="fw-semibold text-body">
                                Email:
                              </span>{" "}
                              {el.emailAddress}
                            </p>
                            <p className="text-muted mb-1">
                              <span className="fw-semibold text-body">
                                Phone:{" "}
                              </span>
                              <span id="shipping-phone-no"> {el?.phone}</span>
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <p className="text-muted mb-1">
                          <span className="fw-semibold text-body">
                            Physical Address:{" "}
                          </span>
                          <span id="shipping-phone-no">
                            {" "}
                            {el?.physicalAddress}
                          </span>
                        </p>
                        <p className="text-muted mb-1">
                          <span className="fw-semibold text-body">Link: </span>
                          <span id="shipping-phone-no">
                            {el?.websiteOrLink || ""}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Row>
      </CardBody>
      <UpdateContactDetails
        isModalOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen((state) => !state)}
        person={person}
        fetchData={() => {
          dispatch(
            onGetPerson({
              id: person?.id,
            })
          );
        }}
      />
    </Card>
  );
};

export default ContactDetails;
