import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  addCountryContractor as addCountryContractorApi,
  updateCountryContractor as updateCountryContractorApi,
  deleteCountryContractor as deleteCountryContractorApi,
  addAgencyContractor as addAgencyContractorApi,
  updateAgencyContractor as updateAgencyContractorApi,
  deleteAgencyContractor as deleteAgencyContractorApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";
import { LEVEL_AGENCY, LEVEL_COUNTRY } from "common/constants";
import {
  getContractor as getContractorApi,
  getContractors as getContractorsApi,
} from "common/services/contractors.service";

export const getContractors = createAsyncThunk(
  "contractor/getContractors",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      const response = getContractorsApi(payload?.params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getContractor = createAsyncThunk(
  "contractor/getContractor",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params = {
        populate: [
          "contactPersons",
          "contractorRegistration",
          "performanceRatings",
          "performanceRatings.performanceRatingType",
          "performanceRatings.ratedBy",
          "previousOrAliasName",
          "organizationType",
          "semgMentions",
          "createdby",
          "createdby.agency",
          "updatedby",
          "boardOfDirectors",
          "boardOfDirectors.person",
          "personAffiliations",
          "personAffiliations.person",
          "contractorAffiliations",
          "contractorAffiliations.affiliatedTo",
          "contractorAffiliations.affiliatedTo.organizationType",
          "contractorAffiliatedTo.contractor",
          "contractorAffiliatedTo.contractor.organizationType",
        ],
      };

      const response = getContractorApi(payload?.id, params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addContractor = createAsyncThunk(
  "contractor/addContractor",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = addCountryContractorApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = addAgencyContractorApi(payload?.params);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateContractor = createAsyncThunk(
  "contractor/updateContractor",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = updateCountryContractorApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = updateAgencyContractorApi(payload?.params);
      }

      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteContractor = createAsyncThunk(
  "contractor/deleteContractor",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = deleteCountryContractorApi(payload?.params);
      }
      if (payload?.level === LEVEL_AGENCY) {
        response = deleteAgencyContractorApi(payload?.params);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);
