import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
  ModalBody,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

const SEMGMentionForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <Row>
          <Col md={12} className="mb-3">
            <SimpleBar
              ref={simpleBarRef}
              style={{ maxHeight: "70vh" }}
              className=" px-4 py-4"
              autoHide={false}
            >
              {validation.values.semgMentions.map(
                (semgMention: any, index: number) => {
                  return (
                    <Card className="bg-light" key={index}>
                      <CardBody>
                        <Row>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`reportNumber-${index}`}
                              className="form-label"
                            >
                              Report Number
                            </Label>
                            <Input
                              name={`semgMentions[${index}].reportNumber`}
                              id={`reportNumber-${index}`}
                              className="form-control"
                              placeholder="Enter report number"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={semgMention.reportNumber || ""}
                              invalid={
                                validation.touched.semgMentions?.[index]
                                  ?.reportNumber &&
                                validation.errors.semgMentions?.[index]
                                  ?.reportNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.semgMentions?.[index]
                              ?.reportNumber &&
                              validation.errors.semgMentions?.[index]
                                ?.reportNumber && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.semgMentions?.[index]
                                      ?.reportNumber
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`SEMGPage-${index}`}
                              className="form-label"
                            >
                              SEMG Page
                            </Label>
                            <Input
                              name={`semgMentions[${index}].SEMGPage`}
                              id={`SEMGPage-${index}`}
                              className="form-control"
                              placeholder="Enter SEMG Page"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={semgMention.SEMGPage || ""}
                              invalid={
                                validation.touched.semgMentions?.[index]
                                  ?.SEMGPage &&
                                validation.errors.semgMentions?.[index]
                                  ?.SEMGPage
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.semgMentions?.[index]
                              ?.SEMGPage &&
                              validation.errors.semgMentions?.[index]
                                ?.SEMGPage && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.semgMentions?.[index]
                                      ?.SEMGPage
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`SEMGSection-${index}`}
                              className="form-label"
                            >
                              SEMG Section
                            </Label>
                            <Input
                              name={`semgMentions[${index}].SEMGSection`}
                              id={`SEMGSection-${index}`}
                              className="form-control"
                              placeholder="Enter SEMG Section"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={semgMention.SEMGSection || ""}
                              invalid={
                                validation.touched.semgMentions?.[index]
                                  ?.SEMGSection &&
                                validation.errors.semgMentions?.[index]
                                  ?.SEMGSection
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.semgMentions?.[index]
                              ?.SEMGSection &&
                              validation.errors.semgMentions?.[index]
                                ?.SEMGSection && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.semgMentions?.[index]
                                      ?.SEMGSection
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`SEMGParagraph-${index}`}
                              className="form-label"
                            >
                              SEMG Paragraph
                            </Label>
                            <Input
                              name={`semgMentions[${index}].SEMGParagraph`}
                              id={`SEMGParagraph-${index}`}
                              className="form-control"
                              placeholder="Enter SEMG Paragraph"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={semgMention.SEMGParagraph || ""}
                              invalid={
                                validation.touched.semgMentions?.[index]
                                  ?.SEMGParagraph &&
                                validation.errors.semgMentions?.[index]
                                  ?.SEMGParagraph
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.semgMentions?.[index]
                              ?.SEMGParagraph &&
                              validation.errors.semgMentions?.[index]
                                ?.SEMGParagraph && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.semgMentions?.[index]
                                      ?.SEMGParagraph
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`SEMGRegion-${index}`}
                              className="form-label"
                            >
                              SEMG Location/Region
                            </Label>
                            <Input
                              name={`semgMentions[${index}].SEMGRegion`}
                              id={`SEMGRegion-${index}`}
                              className="form-control"
                              placeholder="Enter SEMG Region"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={semgMention.SEMGRegion || ""}
                              invalid={
                                validation.touched.semgMentions?.[index]
                                  ?.SEMGRegion &&
                                validation.errors.semgMentions?.[index]
                                  ?.SEMGRegion
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.semgMentions?.[index]
                              ?.SEMGRegion &&
                              validation.errors.semgMentions?.[index]
                                ?.SEMGRegion && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.semgMentions?.[index]
                                      ?.SEMGRegion
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={12} className="mb-3">
                            <Label
                              htmlFor={`SEMGCommentary-${index}`}
                              className="form-label"
                            >
                              SEMG Commentary
                            </Label>
                            <Input
                              name={`semgMentions[${index}].SEMGCommentary`}
                              id={`SEMGCommentary-${index}`}
                              className="form-control"
                              placeholder="Enter SEMG Commentary"
                              type="textarea"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={semgMention.SEMGCommentary || ""}
                              invalid={
                                validation.touched.semgMentions?.[index]
                                  ?.SEMGCommentary &&
                                validation.errors.semgMentions?.[index]
                                  ?.SEMGCommentary
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.semgMentions?.[index]
                              ?.SEMGCommentary &&
                              validation.errors.semgMentions?.[index]
                                ?.SEMGCommentary && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.semgMentions?.[index]
                                      ?.SEMGCommentary
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <div className="mb-3 d-flex justify-content-end">
                            <Button
                              className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                              onClick={() => {
                                validation.setFieldValue(
                                  "semgMentions",
                                  validation.values.semgMentions.filter(
                                    (_: any, semgIndex: number) =>
                                      semgIndex !== index
                                  )
                                );
                              }}
                            >
                              {/* <i className="ri-delete-bin-fill me-2"></i> */}
                              Remove
                            </Button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                }
              )}
              <button
                className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
                disabled={false}
                onClick={() => {
                  if (true) {
                    const newSEMGMention = {
                      reportNumber: "",
                      SEMGPage: "",
                      SEMGSection: "",
                      SEMGParagraph: "",
                      SEMGCommentary: "",
                    };
                    validation.setFieldValue("semgMentions", [
                      ...validation.values.semgMentions,
                      newSEMGMention,
                    ]);
                  }
                }}
              >
                Add
              </button>
            </SimpleBar>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default SEMGMentionForm;
