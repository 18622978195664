import React, { useEffect } from "react";
import ContractItem from "../Items/ContractItem";
import { searchGlobalContent } from "common/services/dd-search.service";
import PaginationFooter from "./PaginationFooter";
import { determineEntityType } from "common/lib/entityType";
import LoadingItem from "../Items/LoadingItem";

const Contracts = ({ searchValue }: { searchValue: string }) => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  useEffect(() => {
    const search = async () => {
      try {
        setLoading(true);
        const response: any = await searchGlobalContent({
          query: searchValue,
          pageSize: 10,
          page: page,
          index: "contract",
        });
        setSearchResults(response.hits);
        setPage(response?.page);
        setTotalPages(response?.totalPages);
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    // if (searchValue)
    search();
  }, [searchValue, page]);

  if (loading) {
    return Array.from({ length: 10 }).map((_, index) => (
      <div key={index}>
        {index !== 0 && <div className="border border-dashed"></div>}
        <LoadingItem key={index} />
      </div>
    ));
  }

  if (searchResults.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center border border-dashed p-2 rounded">
        <div className="text-muted text-center pt-5 pb-5 mt-5 mb-5">
          No Results Found
        </div>
      </div>
    );
  }

  return (
    <>
      {searchResults.map((result: any, index: number) => {
        const entityType: string = determineEntityType(result._meilisearch_id);

        if (entityType === "contract") {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <ContractItem data={result} index={index} />
            </div>
          );
        }
      })}
      {/* <ContractItem /> */}

      {/* <div className="border border-dashed"></div> */}

      <PaginationFooter
        page={page}
        totalPages={totalPages}
        onPageChange={(page: number) => setPage(page)}
      />
    </>
  );
};

export default Contracts;
