import React from "react";

const RiskAssessmentRating = ({
  rating,
  solid,
  fs,
}: {
  rating: string;
  solid?: boolean;
  fs?: string;
}) => {
  if (solid) {
    switch (rating) {
      case "EXTREME":
        return (
          <span className={`badge bg-danger  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "HIGH":
        return (
          <span className={`badge bg-danger  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "MEDIUM":
        return (
          <span className={`badge bg-warning  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "LOW":
        return (
          <span className={`badge bg-success ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "NEGIGLIBLE":
        return (
          <span className={`badge bg-success  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "NOT ASSESSED":
        return (
          <span className={`badge bg-dark  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "VERY LOW":
        return (
          <span className={`badge bg-success  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      case "VERY HIGH":
        return (
          <span className={`badge bg-danger  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
      default:
        return (
          <span className={`badge bg-secondary  ${(fs && fs) || "fs-12"}`}>
            {rating}
          </span>
        );
    }
  }

  switch (rating) {
    case "EXTREME":
      return (
        <span className="badge bg-danger-subtle text-danger fs-11">
          {rating}
        </span>
      );
    case "HIGH":
      return (
        <span className="badge bg-danger-subtle text-danger fs-11">
          {rating}
        </span>
      );
    case "MEDIUM":
      return (
        <span className="badge bg-warning-subtle text-warning fs-11">
          {rating}
        </span>
      );
    case "LOW":
      return (
        <span className="badge bg-success-subtle text-success fs-11">
          {rating}
        </span>
      );
    case "NEGIGLIBLE":
      return (
        <span className="badge bg-success-subtle text-success fs-11">
          {rating}
        </span>
      );
    case "NOT ASSESSED":
      return (
        <span className="badge bg-dark-subtle text-body fs-11">{rating}</span>
      );
    case "VERY LOW":
      return (
        <span className="badge bg-success-subtle text-success fs-11">
          {rating}
        </span>
      );
    case "VERY HIGH":
      return (
        <span className="badge bg-danger-subtle text-danger fs-11">
          {rating}
        </span>
      );
    default:
      return null;
  }
};

export default RiskAssessmentRating;
