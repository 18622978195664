import { ICON_AGENCIES, ICON_CALENDAR, ICON_CONTRACTS } from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import Skeleton from "react-loading-skeleton";

const LoadingItem = ({ index }: { index?: number }) => {
  return (
    <div className={index === 0 ? "pb-3" : "py-3"}>
      <h5>
        <Skeleton width={150} />
      </h5>
      <p className="text-success mb-2 align-items">
        <Skeleton width={100} />
      </p>
      <p className="text-muted mb-2">
        <Skeleton count={3} />
      </p>

      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item me-3">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <Skeleton circle={true} height={20} width={20} />
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <Skeleton width={100} />
            </div>
          </div>
        </li>
        <li className="list-inline-item me-3">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <Skeleton circle={true} height={20} width={20} />
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <Skeleton width={100} />
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <Skeleton circle={true} height={20} width={20} />
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <Skeleton width={100} />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LoadingItem;
