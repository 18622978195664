import moment from "moment";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

const AdditionalDetails = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col xxl={3} className="mb-4">
        <Card className="h-100">
          <CardBody className="p-0">
            <div className="p-3">
              <h6 className="text-muted mb-0 text-uppercase fw-semibold">
                Activity
              </h6>
            </div>
            <SimpleBar style={{ maxHeight: "410px" }} className="p-3 pt-0">
              <div className="acitivity-timeline acitivity-main">
                <div className="acitivity-item d-flex">
                  <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                    <div className="avatar-title bg-warning-subtle text-warning rounded-circle">
                      <i className="ri-time-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1 lh-base">Last Updated</h6>
                    {/* <p className="text-muted mb-1">
                      {`By ${data?.createdby?.firstName} ${data?.createdby?.lastName} - ${data?.createdby?.agency?.name}`}
                    </p> */}
                    <small className="mb-0 text-muted">
                      {moment(new Date(data?.updatedAt)).format("DD MMM, Y")}
                    </small>
                  </div>
                </div>
                <div className="acitivity-item py-3 d-flex">
                  <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle">
                      <i className="ri-calendar-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1 lh-base">Created</h6>
                    {/* <p className="text-muted mb-1">
                      {`By ${data?.createdby?.firstName} ${data?.createdby?.lastName} - ${data?.createdby?.agency?.name}`}
                    </p> */}
                    <p className="mb-0 text-muted">
                      <small>
                        {moment(new Date(data?.createdAt)).format("DD MMM, Y")}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AdditionalDetails;
