import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getAgencies as onGetAgencies } from "slices/thunks";

const SelectAgency = ({
  validation,
  setLoading,
  label,
  index,
}: {
  validation: any;
  setLoading: any;
  label?: any;
  index: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.performanceRatings?.[index]?.agency &&
        validation.errors.performanceRatings?.[index]?.agency
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.performanceRatings?.[index]?.agency &&
          validation.errors.performanceRatings?.[index]?.agency
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetAgencies({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
        filters: {
          category: {
            $null: false,
          },
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Agencies;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    agencies: state.agencies.data,
  }));

  const { agencies } = useSelector(selectProperties);

  const options = useMemo(() => {
    return agencies.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [agencies]);

  return (
    <FormGroup>
      <Label
        htmlFor={`performanceRatings[${index}].agency`}
        className="form-label"
      >
        {label ? label : "Agency"}
      </Label>
      <Select
        name={`performanceRatings[${index}].agency`}
        id={`performanceRatings[${index}].agency`}
        value={
          validation.values.performanceRatings[index]?.agency?.value
            ? validation.values.performanceRatings[index]?.agency
            : null
        }
        placeholder="Select agency"
        onChange={(value: any) => {
          validation.setFieldValue(
            `performanceRatings[${index}].agency`,
            value
          );
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched(
            `performanceRatings[${index}].agency`,
            true
          )
        }
        className={
          validation.touched.performanceRatings?.[index]?.agency &&
          validation.errors.performanceRatings?.[index]?.agency
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>
        {validation.errors.performanceRatings?.[index]?.agency?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectAgency;
