export const getSortObj = (sorted: any) => {
  if (sorted && sorted.length === 0) {
    sorted.push({ id: "createdAt", desc: true });
  }

  let sortObj: { fieldName?: string; order?: string } = {};

  if (sorted.length > 0)
    sortObj = {
      fieldName: sorted[0]?.id,
      order: sorted[0]?.desc ? "desc" : "asc",
    };

  return [`${sortObj.fieldName}:${sortObj.order}`];
};

export const getFilterOptions = (
  search: { columns: []; searchValue: string },
  filter: any
) => {
  let filterOptions: any = {};

  if (search.searchValue) {
    const or: any = search.columns.map((column: any) => {
      return {
        [column]: {
          $containsi: search.searchValue,
        },
      };
    });
    filterOptions["$or"] = or;
  }

  return filterOptions;
};
