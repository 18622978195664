import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import { getCountries as getCountriesApi } from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getCountries = createAsyncThunk(
  "countries/getCountries",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getCountriesApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
