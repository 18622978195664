import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getAgencies as onGetAgencies } from "../../slices/thunks";

const SelectAgency = ({
  validation,
  label,
}: {
  validation: any;

  label?: any;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.agency && validation.errors.agency
          ? "red"
          : "#ced4da", // styles.borderColor,
      borderRadius: ".25rem",
      "&:hover": {
        borderColor:
          validation.touched.agency && validation.errors.agency
            ? "red"
            : "#ced4da", // styles["&:hover"].borderColor,
        borderRadius: ".25rem",
      },
    }),
  };

  const dispatch: any = useDispatch();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetAgencies({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
        filters: {
          category: {
            $null: false,
          },
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Agencies;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    agencies: state.agencies.data,
  }));

  const { agencies } = useSelector(selectProperties);

  const options = useMemo(() => {
    return agencies.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [agencies]);

  return (
    <FormGroup>
      <Label htmlFor="agency-field" className="form-label">
        {label ? label : "Agency"}
      </Label>
      <Select
        name="agency"
        id="agency"
        value={validation.values.agency.value ? validation.values.agency : null}
        placeholder="Select agency"
        onChange={(value: any) => {
          validation.setFieldValue("agency", value);
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("agency", true)}
        className={
          validation.touched.agency && validation.errors.agency
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.agency?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectAgency;
