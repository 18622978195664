import { useProfile } from "Components/Hooks/UserHooks";
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import profileBg from "assets/images/wfp-somalia-cover.jpg";
import { getContractors } from "common/services/contractors.service";
import { getContracts } from "common/services/contracts.service";
import { getRiskAssessments } from "common/services/risk-assessments.service";
import { getCapacityAssessments } from "common/services/capacity-assessments.service";
import { getPvrs } from "common/services/pvr.service";

const Header = () => {
  const { userProfile } = useProfile();

  const [totalContracts, setTotalContracts] = React.useState(0);
  useEffect(() => {
    const fechContractData = async () => {
      try {
        const response: any = await getContracts({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        setTotalContracts(response?.meta?.pagination?.total);
      } catch (err: any) {}
    };
    fechContractData();
  }, [userProfile?.agency?.id]);

  const [totalFlaggedContractors, setTotalFlaggedContractors] =
    React.useState(0);
  useEffect(() => {
    const fetchFlaggedContractors = async () => {
      try {
        const response: any = await getContractors({
          filters: {
            $and: [
              {
                performanceRatings: {
                  performanceRatingType: {
                    isFlag: true,
                  },
                },
              },
              {
                performanceRatings: {
                  ratedBy: userProfile?.agency?.id,
                },
              },
            ],
          },
        });
        setTotalFlaggedContractors(response?.meta?.pagination?.total);
      } catch (err: any) {}
    };
    fetchFlaggedContractors();
  }, [userProfile?.agency?.id]);

  const [totalAssessments, setTotalAssessments] = React.useState(0);
  useEffect(() => {
    const fetchTotalAssessments = async () => {
      try {
        const riskResponse: any = await getRiskAssessments({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        const capacityResponse: any = await getCapacityAssessments({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        const pvrResponse: any = await getPvrs({
          filters: {
            agency: userProfile?.agency?.id,
          },
        });
        const total =
          riskResponse?.meta?.pagination?.total +
          capacityResponse?.meta?.pagination?.total +
          pvrResponse?.meta?.pagination?.total;
        setTotalAssessments(total);
      } catch (err: any) {}
    };
    fetchTotalAssessments();
  }, [userProfile?.agency?.id]);

  const agencyLogo =
    userProfile?.agency?.mainLogoLight?.formats?.thumbnail?.url || "";
  const dimensions = userProfile?.agency?.mainLogoLightDimensions || {};

  return (
    <div className="profile-foreground position-relative rounded">
      <div className="profile-wid-bg rounded">
        <img src={profileBg} alt="" className="profile-wid-img rounded" />
      </div>
      <div className="pt-5 mb-4 mb-lg-3 pb-lg-4 px-4 ">
        <Row className="g-4">
          <Col>
            <div className="p-2">
              <h3 className="text-white mb-1">
                {userProfile?.agency?.fullName}
              </h3>
              <p className="text-white text-opacity-75">United Natons AFP</p>
              <div className="hstack text-white text-opacity-75 gap-1 align-items-center">
                <div className="me-2 align-items-center">
                  <i
                    className={` ri-checkbox-blank-circle-fill fs-10
                me-1 text-white text-opacity-75 `}
                  ></i>
                  {`${totalContracts} Contracts`}
                </div>
                <div className="me-2 align-items-center">
                  <i className=" ri-checkbox-blank-circle-fill fs-10 me-1 text-white text-opacity-75 "></i>
                  {`${totalFlaggedContractors} Suspended Contractors`}
                </div>
                <div className="align-items-center">
                  <i className=" ri-checkbox-blank-circle-fill fs-10 me-1 text-white text-opacity-75 "></i>
                  {`${totalAssessments} Assessments`}
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} className="col col-lg-auto d-none d-lg-block">
            <div
              className="logo-container "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "200px" /* Adjust as necessary */,
                height: "100px" /* Adjust as necessary */,
                overflow: "hidden",
              }}
            >
              {(agencyLogo && (
                <img
                  src={`${process.env.REACT_APP_FILES_URL}${agencyLogo}`}
                  alt=""
                  className=""
                  height={dimensions?.height || "100"}
                  width={dimensions?.width || "190"}
                  style={{
                    maxWidth: "100%",
                    // maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              )) ||
                null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
