import React, { useState, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import TableContainer from "Components/Common/TableContainer";
import * as moment from "moment";

import { toast } from "react-toastify";

import InviteUser from "./InviteUser";
import { getSortObj } from "common/lib/tableDataOptions";
import Permission from "Components/Common/Permission";
import { getAgencyUsers } from "common/services/users.service";
import { normalize } from "common/lib/normalize";
import { useProfile } from "Components/Hooks/UserHooks";

const FilterSection: React.FC<{
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}> = ({ searchValue, setSearchValue }) => {
  return (
    <Row className="mb-3">
      <React.Fragment>
        <CardBody className="border border-dashed border-end-0 border-start-0">
          <form>
            <Row>
              <Col sm={5}>
                <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
                  <input
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control search /"
                    placeholder={"Search user"}
                    value={searchValue || ""}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>

              {searchValue.length > 0 ? (
                <Col sm={4} xxl={1}>
                  <Button
                    color="primary"
                    className="w-400"
                    style={{ paddingInline: 10 }}
                    onClick={() => setSearchValue("")}
                  >
                    <i className="ri-close-fill me-1 align-bottom"></i>
                    Filters
                  </Button>
                </Col>
              ) : null}
            </Row>
          </form>
        </CardBody>
      </React.Fragment>
    </Row>
  );
};

const AgencyUsers = () => {
  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const { userProfile } = useProfile();

  const pageSize = 10;

  const fetchUsers = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setmeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      try {
        setLoading(true);
        const response: any = await getAgencyUsers({
          pagination: {
            page,
            pageSize: 10,
          },
          sort: sortObj,
          populate: [
            "role",
            "agency",
            "country",
            "countryOffice",
            "roles.permissions",
          ],
          filters: {
            $or: [
              {
                email: {
                  $containsi: searchValue,
                },
              },

              {
                firstName: {
                  $containsi: searchValue,
                },
              },
              {
                lastName: {
                  $containsi: searchValue,
                },
              },
            ],
          },
        });
        setData(normalize(response.data));
        setmeta(response.meta);
      } catch (e: any) {
        toast.error("Error while fetching data.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchUpdatedUsers = useCallback(() => {
    onPageChange(pageCache);
  }, [pageCache, onPageChange]);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const showInviteModalForm = () => {
    setIsInviteModalOpen(true);
  };

  //Column
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "firstName",
        id: "firstName",
        enableColumnFilter: false,
        cell: (user: any) => (
          <>
            <div className="d-flex align-items-center">
              {user.row.original.img ? (
                <img
                  src={""}
                  alt=""
                  className="avatar-xs rounded-circle me-2"
                />
              ) : (
                <div className="flex-shrink-0 avatar-xs me-3">
                  <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-16">
                    <i className={"ri-user-line"}></i>
                  </div>
                </div>
              )}
              <Link to={`#`} className="fw-medium link-primary">
                {`${user?.row?.original?.firstName || ""} ${
                  user?.row?.original?.lastName || ""
                }`}
              </Link>
            </div>
          </>
        ),
      },
      {
        header: "Email",
        accessorKey: "email",
        id: "email",
        hidden: true,
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.getValue()}
            </Link>
          );
        },
      },

      {
        header: "Role",
        accessorKey: "role.name",
        id: "role.name",
        enableColumnFilter: false,
      },

      {
        header: "Status",
        accessorKey: "blocked",
        id: "blocked",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === false) {
            return (
              <span className="badge text-uppercase bg-success-subtle text-success">
                Active
              </span>
            );
          } else if (cell.getValue() === true) {
            return (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                Blocked
              </span>
            );
          } else {
            return (
              <span className="badge text-uppercase bg-warning-subtle text-warning">
                N/A
              </span>
            );
          }
        },
      },

      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          // const time = moment(new Date(cell?.getValue())).format("hh:mm A");
          return (
            <>
              {date}
              {/* <small className="text-muted">{time}</small> */}
            </>
          );
        },
      },
    ],
    []
  );

  document.title = "Users List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`${userProfile?.agency?.name} Users List`}
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />

          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      {" "}
                      {userProfile?.agency?.name} Users
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Permission resource="user-management" action="invite">
                          <Link
                            to="#"
                            className="btn btn-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              showInviteModalForm();
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Invite User
                          </Link>
                        </Permission>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={data || []}
                      customPageSize={pageSize}
                      pagination={meta?.pagination || {}}
                      onPageChange={onPageChange}
                      FilterSection={FilterSection}
                      loading={loading}
                      totalRows={meta?.pagination?.total || 0}
                      divClass="table-responsive table-card mb-4"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light table-nowrap"
                      SearchPlaceholder={""}
                    />
                  </div>

                  <InviteUser
                    toggle={() => setIsInviteModalOpen((state) => !state)}
                    isModalOpen={isInviteModalOpen}
                    selectedRecord={selectedRecord}
                    fetchUpdatedUsers={fetchUpdatedUsers}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgencyUsers;
