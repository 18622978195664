import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import UserForm from "./UserForm";
import { updateMyProfile } from "common/services/users.service";
import { toast } from "react-toastify";

const UpdateUser: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: any;
  fetchUpdatedUsers: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchUpdatedUsers }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      agency: {
        label: selectedRecord?.agency?.name,
        value: selectedRecord?.agency?.id,
      },
      country: {
        label: selectedRecord?.country?.name,
        value: selectedRecord?.country?.id,
      },
      role: {
        label: selectedRecord?.role?.name,
        value: selectedRecord?.role?.id,
      },
      email: selectedRecord?.email,
      firstName: selectedRecord?.firstName,
      lastName: selectedRecord?.lastName,
      countryOffice: {
        label: selectedRecord?.countryOffice?.name,
        value: selectedRecord?.countryOffice?.id,
      },
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      country: Yup.object().shape({
        value: Yup.string().required("Please select country"),
      }),

      email: Yup.string()
        .required("Please enter email")
        .email("Please enter a valid email"),

      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string(),
    }),
    onSubmit: async (values) => {
      const updatedData = {
        id: selectedRecord.id,
        country: values["country"]["value"],
        email: values["email"],
        firstName: values["firstName"],
        lastName: values["lastName"],
      };

      setLoading(true);
      try {
        await updateMyProfile(updatedData);
        toast.success("User profile updated successfully.", { autoClose: 500 });
        fetchUpdatedUsers();
        validation.resetForm();
        toggle();
      } catch (e: any) {
        toast.error("Error while updating user profile.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      isOpen={isModalOpen}
      size="lg"
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Profile
      </ModalHeader>

      <UserForm
        validation={validation}
        isUpdate={true}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateUser;
