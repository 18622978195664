import React from "react";
import {
  Form,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import SelectContractor from "pages/Contracts/SelectContractor";
import Flatpickr from "react-flatpickr";
import SelectContractRating from "pages/Contracts/SelectContractRating";
import SelectSubContractor from "pages/Contracts/SelectSubContractor";
import SelectContractType from "pages/Contracts/SelectContractType";
import SelectRegion from "pages/Contracts/SelectRegions";

const ContractForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, toggle, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody>
        <input type="hidden" id="id-field" />

        <div className="mb-3">
          <Label htmlFor="id-field" className="form-label">
            Contract Title
          </Label>
          <Input
            name="contractTitle"
            id="contractTitle"
            className="form-control"
            placeholder="Enter contract title"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.contractTitle || ""}
            invalid={
              validation.touched.contractTitle &&
              validation.errors.contractTitle
                ? true
                : false
            }
          />
          {validation.touched.contractTitle &&
          validation.errors.contractTitle ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <SelectContractor validation={validation} />
        </div>

        <Row>
          <Col md={6} className="mb-3">
            <Label htmlFor="id-field" className="form-label">
              Contract Number
            </Label>
            <Input
              name="contractNo"
              id="contractNo"
              className="form-control"
              placeholder="Enter contract number"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contractNo || ""}
              invalid={
                validation.touched.contractNo && validation.errors.contractNo
                  ? true
                  : false
              }
            />
            {validation.touched.contractNo && validation.errors.contractNo ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
          <Col md={6} className="mb-3">
            <Label htmlFor="id-field" className="form-label">
              Contract Value (USD)
            </Label>
            <Input
              name="contractValue"
              id="contractValue"
              className="form-control"
              placeholder="Enter contract value"
              type="number"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contractValue || ""}
              invalid={
                validation.touched.contractValue &&
                validation.errors.contractValue
                  ? true
                  : false
              }
            />
            {validation.touched.contractValue &&
            validation.errors.contractValue ? (
              <FormFeedback type="invalid">
                {validation.errors.contractValue}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Label htmlFor="startDate-field" className="form-label">
              Start Date
            </Label>
            <Flatpickr
              name="startDate"
              id="startDate-field"
              className="form-control"
              placeholder="Select start date"
              options={{
                enableTime: false,
                altInput: false,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(e) => {
                validation.setFieldValue("startDate", e[0]);
              }}
              value={validation.values.startDate || ""}
            />
            {validation.touched.startDate && validation.errors.startDate ? (
              <FormFeedback type="invalid">
                {validation.errors.startDate}
              </FormFeedback>
            ) : null}
          </Col>
          <Col md={6} className="mb-3">
            <Label htmlFor="dateOfPayment-field" className="form-label">
              End Date
            </Label>
            <Flatpickr
              name="endDate"
              id="endDate-field"
              className="form-control"
              placeholder="Select end date"
              options={{
                enableTime: false,
                altInput: false,
                altFormat: "d M, Y",
                dateFormat: "d M, Y",
              }}
              onChange={(e) => {
                validation.setFieldValue("endDate", e[0]);
              }}
              value={validation.values.endDate || ""}
            />
            {validation.touched.endDate && validation.errors.endDate ? (
              <FormFeedback type="invalid">
                {validation.errors.endDate}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <SelectContractRating
              validation={validation}
              setLoading={setLoading}
            />
          </Col>
          <Col md={6} className="mb-3">
            <SelectRegion validation={validation} />
          </Col>
          <Col md={12} className="mb-3">
            <SelectSubContractor validation={validation} />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <SelectContractType
              validation={validation}
              setLoading={setLoading}
            />
          </Col>

          <Col md={12} className="mb-3">
            <div className="form-check form-switch form-switch-lg px-0 form-check-right">
              <Input
                name="isLTA"
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckCheckedDisabled2"
                value={validation.values.isLTA}
                onChange={validation.handleChange}
              />
              <Label
                className="form-check-label"
                htmlFor="flexSwitchCheckCheckedDisabled2"
                style={{ width: "90px" }}
              >
                Is LTA?
              </Label>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {isUpdate ? "Update Contract" : " Add Contract"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ContractForm;
