import React, { useState, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import * as moment from "moment";

//Import actions
import {
  getCountryOffices as onGetCountryOffices,
  deleteCountryOffice as onDeleteCountryOffice,
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { createSelector } from "reselect";
import AddCountryOffice from "./AddCountryOffice";
import UpdateCountryOffice from "./UpdateCountryOffice";
import { getSortObj } from "common/lib/tableDataOptions";
import Permission from "Components/Common/Permission";

const FilterSection: React.FC<{
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}> = ({ searchValue, setSearchValue }) => {
  return (
    <Row className="mb-3">
      <React.Fragment>
        <CardBody className="border border-dashed border-end-0 border-start-0">
          <form>
            <Row>
              <Col sm={5}>
                <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
                  <input
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control search /"
                    placeholder={"Search country offices..."}
                    value={searchValue || ""}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>

              {searchValue.length > 0 ? (
                <Col sm={4} xxl={1}>
                  <Button
                    color="primary"
                    className="w-400"
                    style={{ paddingInline: 10 }}
                    onClick={() => setSearchValue("")}
                  >
                    <i className="ri-close-fill me-1 align-bottom"></i>
                    Filters
                  </Button>
                </Col>
              ) : null}
            </Row>
          </form>
        </CardBody>
      </React.Fragment>
    </Row>
  );
};

const CountryOffice = () => {
  const dispatch: any = useDispatch();

  const selectinvoiceProperties = createSelector(
    (state: any) => state.CountryOffices,
    (state) => ({
      countryOffices: state.countryOffices.data,
      meta: state.countryOffices.meta,
      error: state.error,
      loading: state.loading,
    })
  );
  const { countryOffices, meta, loading } = useSelector(
    selectinvoiceProperties
  );

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [deleteModal, setDeleteModal] = useState(false);

  const pageSize = 10;

  const fetchAgencies = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetCountryOffices({
          pagination: {
            page,
            pageSize: 10,
          },
          sort: sortObj,
          populate: ["countries", "adminUser"],
          filters: {
            $or: [
              {
                name: {
                  $containsi: searchValue,
                },
              },
            ],
            // $and: [
            //   {
            //     category: {
            //       $notNull: true,
            //     },
            //   },
            // ],
          },
        })
      );
    },
    [dispatch]
  );

  const fetchUpdatedCountryOffice = useCallback(() => {
    onPageChange(pageCache);
  }, [pageCache, onPageChange]);

  // Delete Data
  const onClickDelete = (countryOffice: any) => {
    setSelectedRecord(countryOffice);
    setDeleteModal(true);
  };
  const [deleting, setDeleting] = useState<any>(false);
  const handleDelete = () => {
    if (selectedRecord) {
      setDeleting(true);
      dispatch(onDeleteCountryOffice(selectedRecord)).then(() => {
        setDeleting(false);
        fetchUpdatedCountryOffice();
        setDeleteModal(false);
      });
    }
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (countryOffice: any) => {
    setSelectedRecord(countryOffice);
    setIsUpdateModalOpen(true);
  };

  //Column
  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        id: "id",
        enableColumnFilter: false,
      },
      {
        header: "Country Office Name",
        accessorKey: "name",
        id: "name",
        enableColumnFilter: false,
        Cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.value}
            </Link>
          );
        },
      },
      {
        header: "Countries",
        accessorKey: "countries",
        id: "countries",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell
                .getValue()
                ?.map((country: any) => country?.name)
                .join(", ")}
            </Link>
          );
        },
      },

      {
        header: "Status",
        accessorKey: "blocked",
        id: "blocked",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case false:
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  Active
                </span>
              );

            case true:
              return (
                <span className="badge text-uppercase bg-danger-subtle text-danger">
                  Blocked
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  No Status
                </span>
              );
          }
        },
      },

      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          const time = moment(new Date(cell?.getValue())).format("hh:mm A");
          return (
            <>
              {date} <small className="text-muted">{time}</small>
            </>
          );
        },
      },

      {
        header: "Action",
        id: "action",
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" end>
                <DropdownItem
                  href="#"
                  onClick={() =>
                    showUpdateModalForm({
                      id: rowData.id,
                      ...rowData,
                    })
                  }
                >
                  <i className="ri-edit-2-line align-bottom me-2 text-muted"></i>{" "}
                  Edit
                </DropdownItem>

                <Permission resource="country-office" action="delete">
                  <DropdownItem divider />

                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete({
                        id: rowData.id,
                        ...rowData,
                      });
                    }}
                  >
                    <i className="ri-delete-bin-line align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  document.title = "Country Office | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => handleDelete()}
          onCloseClick={() => setDeleteModal(false)}
          // loading={deleting}
        />

        <Container fluid>
          <BreadCrumb title="Country Offices" pageTitle="Dashboard" />

          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Country Offices
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        <Link
                          to="#"
                          className="btn btn-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            showAddModalForm();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add Country Office
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    <TableContainer
                      loading={loading}
                      columns={columns}
                      data={countryOffices || []}
                      customPageSize={pageSize}
                      pagination={meta?.pagination}
                      onPageChange={onPageChange}
                      FilterSection={FilterSection}
                      totalRows={meta?.pagination?.total || 0}
                      divClass="table-responsive table-card mb-4"
                      tableClass="align-middle table-nowrap mb-0"
                      theadClass="table-light table-nowrap"
                      SearchPlaceholder={""}
                    />
                  </div>
                  <AddCountryOffice
                    toggle={() => setIsAddModalOpen((state) => !state)}
                    isModalOpen={isAddModalOpen}
                    fetchAgencies={fetchAgencies}
                  />
                  <UpdateCountryOffice
                    toggle={() => setIsUpdateModalOpen((state) => !state)}
                    isModalOpen={isUpdateModalOpen}
                    selectedRecord={selectedRecord}
                    fetchUpdatedCountryOffice={fetchUpdatedCountryOffice}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CountryOffice;
