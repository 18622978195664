import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  loading: false,
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
    userForgetPasswordSuccess(state, action) {
      state.forgetSuccessMsg = action.payload;
    },
    userForgetPasswordError(state, action) {
      state.forgetError = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setLoading,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
