import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  Button,
} from "reactstrap";

interface ContactPersonsInputProps {
  validation: any; // Replace 'any' with the appropriate type for the 'validation' parameter
}

const ContactPersonsInput = ({ validation }: ContactPersonsInputProps) => {
  return (
    <>
      {validation.values.contactPersons.map(
        (contactPerson: any, index: number) => {
          return (
            <Card className="bg-light">
              <CardBody>
                <Row key={index}>
                  <Col md={6} className="mb-3">
                    <Label htmlFor={`name-${index}`} className="form-label">
                      Full Name
                    </Label>
                    <Input
                      name={`contactPersons[${index}].name`}
                      id={`name-${index}`}
                      className="form-control"
                      placeholder="Enter full name"
                      type="text"
                      validate={{ required: { value: true } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contactPerson.name || ""}
                      invalid={
                        validation.touched.contactPersons?.[index]?.name &&
                        validation.errors.contactPersons?.[index]?.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactPersons?.[index]?.name &&
                      validation.errors.contactPersons?.[index]?.name && (
                        <FormFeedback type="invalid">
                          {validation.errors.contactPersons?.[index]?.name}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label htmlFor={`email-${index}`} className="form-label">
                      Email Address
                    </Label>
                    <Input
                      name={`contactPersons[${index}].emailAddress`}
                      id={`email-${index}`}
                      className="form-control"
                      placeholder="Enter email address"
                      type="email"
                      validate={{ required: { value: true }, email: true }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contactPerson.emailAddress || ""}
                      invalid={
                        validation.touched.contactPersons?.[index]
                          ?.emailAddress &&
                        validation.errors.contactPersons?.[index]?.emailAddress
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactPersons?.[index]?.emailAddress &&
                      validation.errors.contactPersons?.[index]
                        ?.emailAddress && (
                        <FormFeedback>
                          {validation.errors.contactPersons[index].emailAddress}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`mobileNumber-${index}`}
                      className="form-label"
                    >
                      Mobile Number
                    </Label>
                    <Input
                      name={`contactPersons[${index}].mobileNumber`}
                      id={`mobileNumber-${index}`}
                      className="form-control"
                      placeholder="Enter mobile number"
                      type="text"
                      validate={{ required: { value: true } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contactPerson.mobileNumber || ""}
                      invalid={
                        validation.touched.contactPersons?.[index]
                          ?.mobileNumber &&
                        validation.errors.contactPersons?.[index]?.mobileNumber
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactPersons?.[index]?.mobileNumber &&
                      validation.errors.contactPersons?.[index]
                        ?.mobileNumber && (
                        <FormFeedback>
                          {validation.errors.contactPersons[index].mobileNumber}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`telephone-${index}`}
                      className="form-label"
                    >
                      Telephone
                    </Label>
                    <Input
                      name={`contactPersons[${index}].telephone`}
                      id={`telephone-${index}`}
                      className="form-control"
                      placeholder="Enter telephone number"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contactPerson.telephone || ""}
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`websiteOrLink-${index}`}
                      className="form-label"
                    >
                      Website or Link
                    </Label>
                    <Input
                      name={`contactPersons[${index}].websiteOrLink`}
                      id={`websiteOrLink-${index}`}
                      className="form-control"
                      placeholder="Enter website or link"
                      type="url"
                      validate={{ url: true }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contactPerson.websiteOrLink || ""}
                      invalid={
                        validation.touched.contactPersons?.[index]
                          ?.websiteOrLink &&
                        validation.errors.contactPersons?.[index]?.websiteOrLink
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactPersons?.[index]
                      ?.websiteOrLink &&
                      validation.errors.contactPersons?.[index]
                        ?.websiteOrLink && (
                        <FormFeedback>
                          {
                            validation.errors.contactPersons[index]
                              .websiteOrLink
                          }
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`physicalAddress-${index}`}
                      className="form-label"
                    >
                      Physical Address
                    </Label>
                    <Input
                      name={`contactPersons[${index}].physicalAddress`}
                      id={`physicalAddress-${index}`}
                      className="form-control"
                      placeholder="Enter physical address"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contactPerson.physicalAddress || ""}
                    />
                  </Col>
                  <div className="mb-3 d-flex justify-content-end">
                    <Button
                      className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                      onClick={() => {
                        validation.setFieldValue(
                          "contactPersons",
                          validation.values.contactPersons.filter(
                            (_: any, contactPersonIndex: number) =>
                              contactPersonIndex !== index
                          )
                        );
                      }}
                    >
                      {/* <i className="ri-delete-bin-fill me-2"></i> */}
                      Remove
                    </Button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          );
        }
      )}

      <button
        className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
        disabled={validation.values.contactPersons.length === 3}
        onClick={() => {
          if (validation.values.contactPersons.length < 3) {
            const newContactPerson = {
              name: "",
              emailAddress: "",
              mobileNumber: "",
              telephone: "",
              websiteOrLink: "",
              physicalAddress: "",
            };
            validation.setFieldValue("contactPersons", [
              ...validation.values.contactPersons,
              newContactPerson,
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default ContactPersonsInput;
