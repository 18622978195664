import React from "react";
import { Label, Input, FormFeedback, Button, Card, CardBody } from "reactstrap";

interface AliasNamesInputProps {
  validation: any; // Replace 'any' with the appropriate type for the 'validation' parameter
}

const AliasNamesInput = ({ validation }: AliasNamesInputProps) => {
  return (
    <>
      {validation.values.alias.map((name: string, index: number) => (
        <>
          <Card className="bg-light" key={index}>
            <CardBody>
              <div key={index} className="mb-3 ">
                <Label htmlFor={`alias-${index}`} className="form-label">
                  Alias Name
                </Label>
                <Input
                  name={`alias.${index}`}
                  id={`alias.${index}`}
                  className="form-control"
                  placeholder="Enter alias name"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={name}
                  invalid={
                    validation.touched.alias &&
                    validation.touched.alias[index] &&
                    validation.errors.alias &&
                    validation.errors.alias[index]
                      ? true
                      : false
                  }
                />
                {validation.touched.preiousOrAliasName &&
                validation.touched.alias[index] &&
                validation.errors.alias &&
                validation.errors.alias[index] ? (
                  <FormFeedback type="invalid">
                    {validation.errors?.alias && validation.errors.alias[index]}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3 d-flex justify-content-end">
                <Button
                  className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                  onClick={(e) => {
                    e.preventDefault();
                    const names = validation.values.alias;
                    names.splice(index, 1);
                    validation.setFieldValue("alias", names);
                  }}
                >
                  {/* <i className="ri-delete-bin-fill me-2"></i> */}
                  Remove
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      ))}
      <button
        className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.alias.length < 5) {
            validation.setFieldValue("alias", [...validation.values.alias, ""]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default AliasNamesInput;
