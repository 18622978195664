import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllContractors from "./AllAgencyContractors";
import { useProfile } from "Components/Hooks/UserHooks";

const AgencyContractors = () => {
  document.title = "Contractors | CIMS";

  const { userProfile } = useProfile();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`${userProfile?.agency?.name} Contractors`}
            pageTitles={[
              { title: "Dashboard", link: "/agency-dashboard/agency" },
            ]}
          />

          <AllContractors />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgencyContractors;
