import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { getPerformanceRatingTypes } from "common/services/performance-rating.service";
import { normalize } from "common/lib/normalize";

const SelectPerformanceRatingType = ({
  validation,
  index,
  label,
}: {
  validation: any;
  index: number;
  label?: string;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.performanceRatings?.[index]?.performanceRatingType &&
        validation.errors.performanceRatings?.[index]?.performanceRatingType
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.performanceRatings?.[index]
            ?.performanceRatingType &&
          validation.errors.performanceRatings?.[index]?.performanceRatingType
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();

  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getPerformanceRatingTypes({});
        setData(normalize(response.data));
        setError(null);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name || ""}`,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label
        htmlFor={`performanceRatings[${index}].performanceRatingType`}
        className="form-label"
      >
        {label ? label : "Performance Rating"}
      </Label>
      <Select
        name={`performanceRatings[${index}].performanceRatingType`}
        id={`performanceRatings[${index}].performanceRatingType`}
        value={
          validation.values.performanceRatings[index]?.performanceRatingType
            ?.value
            ? validation.values.performanceRatings[index]?.performanceRatingType
            : null
        }
        placeholder="Select rating"
        onChange={(value: any) => {
          validation.setFieldValue(
            `performanceRatings[${index}].performanceRatingType`,
            value
          );
          validation.setFieldError(
            `performanceRatings[${index}].performanceRatingType`,
            ""
          );
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched(
            `performanceRatings[${index}].performanceRatingType`,
            true
          )
        }
        className={
          validation.touched.performanceRatings?.[index]
            ?.performanceRatingType &&
          validation.errors.performanceRatings?.[index]?.performanceRatingType
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
        isLoading={loading}
      />
      <FormFeedback>
        {validation.errors.performanceRatings?.[index]?.performanceRatingType
          ?.value || ""}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectPerformanceRatingType;
