import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getOrganizationTypes as getOrganizationTypesApi,
  addOrganizationType as addOrganizationTypeApi,
  updateOrganizationType as updateOrganizationTypeApi,
  deleteOrganizationType as deleteOrganizationTypeApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getOrganizationTypes = createAsyncThunk(
  "organizationType/getOrganizationTypes",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getOrganizationTypesApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addOrganizationType = createAsyncThunk(
  "organizationType/addOrganizationType",
  async (organizationType: any) => {
    try {
      const response = addOrganizationTypeApi(organizationType);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateOrganizationType = createAsyncThunk(
  "organizationType/updateOrganizationType",
  async (organizationType: any) => {
    try {
      const response = updateOrganizationTypeApi(organizationType);
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteOrganizationType = createAsyncThunk(
  "organizationType/deleteOrganizationType",
  async (organizationType: any) => {
    try {
      const response = deleteOrganizationTypeApi(organizationType);
      return response;
    } catch (error) {
      return error;
    }
  }
);
