import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "../../Components/Common/withRouter";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import AuthSlider from "./AuthSlider";

const ResetPassword = (props: any) => {
  const dispatch: any = useDispatch();

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [confrimPasswordShow, setConfrimPasswordShow] =
    useState<boolean>(false);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confrim_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), ""])
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  const forgetSuccessMsg = false;

  document.title = "Reset Password | CIMS";

  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "rgba(1,78,107, 0.9)",
        }}
      >
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden card-bg-fill galaxy-border-none">
                  <Row className="g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div className=" mt-2">
                          <h5 className="text-primary">Reset Password</h5>
                          <p className="text-muted">
                            Your new password must be different from previous
                            used password.
                          </p>
                        </div>

                        <div className="mt-5">
                          {forgetSuccessMsg ? (
                            <Alert
                              color="success"
                              style={{ marginTop: "13px" }}
                            >
                              {forgetSuccessMsg}
                            </Alert>
                          ) : null}
                          <Form onSubmit={validation.handleSubmit}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control pe-5 password-input"
                                  placeholder="Enter password"
                                  id="password-input"
                                  name="password"
                                  value={validation.values.password}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.password &&
                                    validation.touched.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.password &&
                                validation.touched.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                              <div id="passwordInput" className="form-text">
                                Must be at least 8 characters.
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="confirm-password-input"
                              >
                                Confirm Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  type={
                                    confrimPasswordShow ? "text" : "password"
                                  }
                                  className="form-control pe-5 password-input"
                                  placeholder="Confirm password"
                                  id="confirm-password-input"
                                  name="confirmPassword"
                                  value={validation.values.confirmPassword}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.confirmPassword &&
                                    validation.touched.confirmPassword
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.confirmPassword &&
                                validation.touched.confirmPassword ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confirmPassword}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() =>
                                    setConfrimPasswordShow(!confrimPasswordShow)
                                  }
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                  type="button"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                            </div>

                            <div
                              id="password-contain"
                              className="p-3 bg-light mb-2 rounded"
                            >
                              <h5 className="fs-13">Password must contain:</h5>
                              <p
                                id="pass-length"
                                className="invalid fs-12 mb-2"
                              >
                                Minimum <b>8 characters</b>
                              </p>
                              <p id="pass-lower" className="invalid fs-12 mb-2">
                                At <b>lowercase</b> letter (a-z)
                              </p>
                              <p id="pass-upper" className="invalid fs-12 mb-2">
                                At least <b>uppercase</b> letter (A-Z)
                              </p>
                              <p
                                id="pass-number"
                                className="invalid fs-12 mb-0"
                              >
                                A least <b>number</b> (0-9)
                              </p>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </Label>
                            </div>

                            <div className="mt-4">
                              <Button
                                color="primary"
                                className="w-100"
                                type="submit"
                              >
                                Reset Password
                              </Button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Back to Sign In Page -{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer galaxy-border-none">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0">
                    CIMS {new Date().getFullYear()} - Risk Management Unit
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
