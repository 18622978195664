import { createSlice } from "@reduxjs/toolkit";
import {
  getPersons,
  addPerson,
  updatePerson,
  deletePerson,
  getPerson,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  persons: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },

  person: {},
  error: {},
  refreshList: false,
  loading: false,
};

const PersonsSlice = createSlice({
  name: "PersonsSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersons.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);
        state.persons.data = data || state.persons.data;
      }
      state.persons.meta = action.payload?.meta || state.persons.meta;
      state.loading = false;
    });
    builder.addCase(getPersons.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 2000,
      });
    });
    builder.addCase(getPerson.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);

        state.person = data || state.person;
      }
      state.loading = false;
    });
    builder.addCase(addPerson.fulfilled, () => {
      toast.success("Person Added Successfully", { autoClose: 2000 });
    });
    builder.addCase(addPerson.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Person Creation Failed", {
        autoClose: 2000,
      });
    });
    builder.addCase(updatePerson.fulfilled, () => {
      toast.success("Person Updated Successfully", { autoClose: 2000 });
    });
    builder.addCase(updatePerson.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Person Update Failed", { autoClose: 2000 });
    });
    builder.addCase(deletePerson.fulfilled, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.success("Person Deleted Successfully", { autoClose: 2000 });
    });
    builder.addCase(deletePerson.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
      toast.error("Person Deletion Failed", { autoClose: 2000 });
    });
  },
});

export const { setLoading } = PersonsSlice.actions;

export default PersonsSlice.reducer;
