import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getGlobalAgencies as getGlobalAgenciesApi,
  getAgencies as getAgenciesApi,
  addAgency as addAgencyApi,
  updateAgency as updateAgencyApi,
  deleteAgency as deleteAgencyApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getGlobalAgencies = createAsyncThunk(
  "agency/getGlobalAgencies",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getGlobalAgenciesApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getAgencies = createAsyncThunk(
  "agency/getAgencies",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getAgenciesApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addAgency = createAsyncThunk(
  "agency/addNewAgency",
  async (agency: any) => {
    try {
      const response = addAgencyApi(agency);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateAgency = createAsyncThunk(
  "agency/updateAgency",
  async (agency: any) => {
    try {
      const response = updateAgencyApi(agency);
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteAgency = createAsyncThunk(
  "agency/deleteAgency",
  async (agency: any) => {
    try {
      const response = deleteAgencyApi(agency);
      return response;
    } catch (error) {
      return error;
    }
  }
);
