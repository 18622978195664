import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Images
import profileBg from "assets/images/wfp-somalia-cover.jpg";
import avatar1 from "assets/images/users/user-dummy-img.jpg";
import ChangePassword from "./MyProfile/ChangePassword";
import Overview from "./MyProfile/Overview";
import { useProfile } from "Components/Hooks/UserHooks";
import * as moment from "moment";
import BreadCrumb from "Components/Common/BreadCrumb";

const MyProfile = () => {
  document.title = "My Profile | CIMS";

  const [activeTab, setActiveTab] = useState("1");
  const [activityTab, setActivityTab] = useState("1");

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleActivityTab = (tab: any) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };

  const { userProfile } = useProfile();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="My Profile"
            pageTitle="My Profile"
            back
            pageTitles={[
              {
                title: "Dashboard",
                link: "/dashboard",
              },
            ]}
          />
          <div className="profile-foreground position-relative rounded">
            <div
              className="profile-wid-bg rounded"
              // style={{ height: "225px" }}
            >
              <img src={profileBg} alt="" className="profile-wid-img rounded" />
            </div>
          </div>
          <div className="pt-4 pb-lg-4 mb-lg-1 px-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src={avatar1}
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                  />
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">{`${
                    userProfile?.firstName || ""
                  } ${userProfile?.lastName || ""}`}</h3>
                  <p className="text-white text-opacity-75">
                    {userProfile?.role?.name || ""}
                  </p>
                  <div className="hstack text-white text-opacity-75 gap-3">
                    {/* <div className="me-2">
                      <i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                      California, United States
                    </div> */}
                    <div>
                      <i className="ri-earth-fill me-1 text-white text-opacity-75 fs-16 align-bottom"></i>
                      {userProfile?.agency?.name || ""}
                    </div>
                    {/* <div className="vr"></div>
                    <div>
                      <i className="ri-user-line me-1 text-white text-opacity-75 fs-16 align-bottom"></i>
                      {userProfile?.title || ""}
                    </div> */}
                    <div className="vr"></div>
                    <div>
                      <i className="ri-calendar-line me-1 text-white text-opacity-75 fs-16 align-bottom"></i>
                      Joined:{" "}
                      {moment(userProfile?.createdAt).format("DD MMM, Y") || ""}
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={12} className="col-lg-auto order-last order-lg-0">
                <Row className="text text-white-50 text-center">
                  <Col lg={6} xs={4}>
                    <div className="p-2">
                      {/* <h4 className="text-white mb-1">24.3K</h4>
                      <p className="fs-14 mb-0">Followers</p> */}
                    </div>
                  </Col>
                  <Col lg={6} xs={4}>
                    <div className="p-2">
                      {/* <h4 className="text-white mb-1">1.3K</h4>
                      <p className="fs-14 mb-0">Following</p> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex px-4">
                  <Nav
                    pills
                    className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <NavItem className="fs-14">
                      <NavLink
                        href="#overview-tab"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Overview
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="flex-shrink-0">
                    <Link
                      to="#"
                      className="btn btn-light me-2
                      "
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsUpdateModalOpen(true);
                      }}
                    >
                      <i className="ri-edit-box-line align-bottom"></i> Edit
                      Profile
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-dark
                      "
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsUpdateModalOpen(true);
                      }}
                    >
                      <i className="ri-lock-line align-bottom"></i> Change
                      Password
                    </Link>
                  </div>
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <Overview
                      userProfile={userProfile}
                      setIsUpdateModalOpen={setIsUpdateModalOpen}
                      isUpdateModalOpen={isUpdateModalOpen}
                      setIsChangePasswordModalOpen={
                        setIsChangePasswordModalOpen
                      }
                      isChangePasswordModalOpen={isChangePasswordModalOpen}
                    />
                  </TabPane>
                  {/* <TabPane tabId="2">
                    <ChangePassword />
                  </TabPane> */}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyProfile;
