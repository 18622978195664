import React, { useMemo } from "react";
import { Label, FormFeedback, FormGroup, Input } from "reactstrap";

const SelectGender = ({ validation }: { validation: any; setLoading: any }) => {
  const options = useMemo(() => {
    const genders = [
      { id: 1, name: "Male" },
      { id: 2, name: "Female" },
      { id: 3, name: "Other" },
    ];

    return genders.map((el: any) => {
      return {
        value: el?.name,
        label: el?.name,
      };
    });
  }, []);

  return (
    <FormGroup>
      <Label htmlFor="gender-input" className="form-label">
        Gender
      </Label>
      <Input
        name="gender"
        type="select"
        className="form-select"
        id="gender-input"
        placeholder="Select gender"
        onChange={(e) => {
          validation.setFieldValue("gender", e.target.value);
        }}
        onBlur={() => validation.setFieldTouched("gender", true)}
        value={validation.values.gender || ""}
        invalid={
          validation.touched.gender && validation.errors.gender ? true : false
        }
      >
        <option value="" disabled selected>
          Select an option
        </option>
        {options.map((item: any) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </Input>
      {validation.touched.gender && validation.errors.gender ? (
        <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

export default SelectGender;
