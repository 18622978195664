import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select/async";
import { getPersons } from "common/services/persons.service";
import { normalize } from "common/lib/normalize";
import {
  GroupBase,
  OptionsOrGroups,
} from "react-select/dist/declarations/src/types";

const SelectPerson = ({
  validation,
  selectedId,
  index,
}: {
  validation: any;
  isUpdate?: boolean;
  selectedId?: number;
  index: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation?.touched?.affiliations?.[index]?.person &&
        validation?.errors?.affiliations?.[index]?.person
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation?.touched?.affiliations?.[index]?.person &&
          validation?.errors?.affiliations?.[index]?.person
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getPersons({
          pagination: {
            page: 1,
            pageSize: 20,
          },
        });
        setData(normalize(response.data));
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedId]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.firstName || ""} ${el.middleName || ""} ${
          el?.surname || ""
        }`,
      };
    });
  }, [data]);

  return (
    <FormGroup key={index}>
      <Label htmlFor={`affiliations.${index}.person`} className="form-label">
        Person
      </Label>
      <Select
        name={`affiliations.${index}.person`}
        id={`affiliations.${index}.person`}
        value={
          validation.values.affiliations[index].person.value
            ? validation.values.affiliations[index].person
            : null
        }
        placeholder="Select person"
        onChange={(value: any) => {
          validation.setFieldValue(`affiliations.${index}.person`, value);
          validation.setFieldError(`affiliations.${index}.person`, "");
        }}
        defaultOptions={options}
        // @ts-ignore
        loadOptions={(
          inputValue: string,
          callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void
        ) => {
          return getPersons({
            pagination: {
              page: 1,
              perPage: 20,
            },
            filters: {
              $or: [
                {
                  firstName: {
                    $containsi: inputValue,
                  },
                },
                {
                  middleName: {
                    $containsi: inputValue,
                  },
                },
                {
                  surname: {
                    $containsi: inputValue,
                  },
                },
              ],
            },
          }).then((result: any) => {
            const parsedData = normalize(result.data);
            callback(
              parsedData.map((el: any) => {
                return {
                  value: el?.id,
                  label: `${el?.firstName || ""} ${el.middleName || ""} ${
                    el?.surname || ""
                  }`,
                };
              })
            );
          });
        }}
        onBlur={() =>
          validation.setFieldTouched(`affiliations.${index}.person`, true)
        }
        className={
          validation.touched.affiliations?.[index]?.person &&
          validation.errors.affiliations?.[index]?.person
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
        isLoading={loading}
      />
      <FormFeedback>
        {validation.errors.affiliations?.[index]?.person?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectPerson;
