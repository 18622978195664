import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getCountryOffices as getCountryOfficesApi,
  addCountryOffice as addCountryOfficeApi,
  updateCountryOffice as updateCountryOfficeApi,
  deleteCountryOffice as deleteCountryOfficeApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getCountryOffices = createAsyncThunk(
  "countryOffice/getCountryOffices",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getCountryOfficesApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addCountryOffice = createAsyncThunk(
  "countryOffice/addCountryOffice",
  async (countryOffice: any) => {
    try {
      const response = addCountryOfficeApi(countryOffice);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateCountryOffice = createAsyncThunk(
  "countryOffice/updateCountryOffice",
  async (countryOffice: any) => {
    try {
      const response = updateCountryOfficeApi(countryOffice);
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteCountryOffice = createAsyncThunk(
  "countryOffice/deleteCountryOffice",
  async (countryOffice: any) => {
    try {
      const response = deleteCountryOfficeApi(countryOffice);
      return response;
    } catch (error) {
      return error;
    }
  }
);
