import { createSlice } from "@reduxjs/toolkit";
import {
  getCapacityAssessments,
  addCapacityAssessment,
  updateCapacityAssessment,
  deleteCapacityAssessment,
  getCapacityAssessmentTypes,
  getCapacityAssessmentRatings,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  capacityAssessments: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  capacityAssessmentTypes: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },

  capacityAssessmentRatings: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const CapacityAssessmentsSlice = createSlice({
  name: "CapacityAssessmentsSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCapacityAssessments.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload.data);
          state.capacityAssessments.data =
            data || state.capacityAssessments.data;
        }
        state.capacityAssessments.meta =
          action.payload?.meta || state.capacityAssessments.meta;
        state.loading = false;
      }
    );
    builder.addCase(
      getCapacityAssessments.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        state.loading = false;
        toast.error("Error while fetching data", {
          autoClose: 3000,
        });
      }
    );
    builder.addCase(addCapacityAssessment.fulfilled, () => {
      toast.success("Assessment Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(
      addCapacityAssessment.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.error("Agency Creation Failed", {
          autoClose: 3000,
        });
      }
    );
    builder.addCase(updateCapacityAssessment.fulfilled, () => {
      toast.success("Agency Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(
      updateCapacityAssessment.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.error("Agency Update Failed", { autoClose: 3000 });
      }
    );
    builder.addCase(
      deleteCapacityAssessment.fulfilled,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.success("Agency Deleted Successfully", { autoClose: 3000 });
      }
    );
    builder.addCase(
      deleteCapacityAssessment.rejected,
      (state: any, action: any) => {
        state.error = action.payload.error || null;
        toast.error("Agency Deletion Failed", { autoClose: 3000 });
      }
    );
    builder.addCase(
      getCapacityAssessmentTypes.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload.data);
          state.capacityAssessmentTypes.data =
            data || state.capacityAssessmentTypes.data;
        }
        state.capacityAssessmentTypes.meta =
          action.payload?.meta || state.capacityAssessmentTypes.meta;
        state.loading = false;
      }
    );
    builder.addCase(
      getCapacityAssessmentRatings.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload.data);
          state.capacityAssessmentRatings.data =
            data || state.capacityAssessmentRatings.data;
        }
        state.capacityAssessmentRatings.meta =
          action.payload?.meta || state.capacityAssessmentRatings.meta;
        state.loading = false;
      }
    );
  },
});

export const { setLoading } = CapacityAssessmentsSlice.actions;

export default CapacityAssessmentsSlice.reducer;
