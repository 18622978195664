import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getEUSanctionsList as getEUSanctionsListApi,
  getEUSanctionedItem as getEUSanctionedItemApi,
  getUKSanctionsList as getUKSanctionsListApi,
  getUKSanctionedItem as getUKSanctionedItemApi,
  getUSSanctionsList as getUSSanctionsListApi,
  getUSSanctionedItem as getUSSanctionedItemApi,
  uploadUNGMList as uploadUNGMListApi,
  getUNGMList as getUNGMListApi,
  getUNGMItem as getUNGMItemApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getEUSanctionsList = createAsyncThunk(
  "euSanctionsList/getEUSanctionsList",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getEUSanctionsListApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getEUSanctionedItem = createAsyncThunk(
  "euSanctionsList/getEUSanctionedItem",
  async (itemId: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params = {};
      const response = getEUSanctionedItemApi(itemId, params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUKSanctionsList = createAsyncThunk(
  "ukSanctionsList/getUKSanctionsList",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getUKSanctionsListApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUKSanctionedItem = createAsyncThunk(
  "ukSanctionsList/getUKSanctionedItem",
  async (itemId: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getUKSanctionedItemApi(itemId, {});
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUSSanctionsList = createAsyncThunk(
  "usSanctionsList/getUSSanctionsList",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getUSSanctionsListApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUSSanctionedItem = createAsyncThunk(
  "usSanctionsList/getUSSanctionedItem",
  async (itemId: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getUSSanctionedItemApi(itemId, {});
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUNGMList = createAsyncThunk(
  "ungmList/getUNGMList",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = getUNGMListApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUNGMItem = createAsyncThunk(
  "ungmList/getUNGMItem",
  async (itemId: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = getUNGMItemApi(itemId, {});
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const uploadUNGMList = createAsyncThunk(
  "ungmSanctionsList/uploadUNGMList",
  async (data: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = uploadUNGMListApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
