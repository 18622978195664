import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import PersonAffiliationsForm from "./AffiliationsToPersonsForm";
import { updateContractorAffiliationsToPerson } from "common/services/contractors.service";
import { toast } from "react-toastify";

const UpdateAffiliationsToPersons: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  data: any;
  contractorId: string;
}> = ({ toggle, isModalOpen, contractorId, data, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      affiliations: (data.length &&
        data.map((item: any) => ({
          id: item.id,
          person: {
            value: item.person?.id || "",
            label: `${item?.person?.firstName || ""} ${
              item?.person?.middleName || ""
            } ${item?.person?.surname || ""}`,
          },
          natureOfRelationship: item.natureOfRelationship || "",
          informationSource: item.informationSource || "",
          relationshipConfirmed: {
            value: item.relationshipConfirmed,
            label: item.relationshipConfirmed ? "Confirmed" : "Not confirmed",
          },
        }))) || [
        {
          person: {
            value: "",
            label: "",
          },
          natureOfRelationship: "",
          informationSource: "",
          relationshipConfirmed: {
            value: false,
            label: "Not confirmed",
          },
        },
      ],
    },
    validationSchema: Yup.object({
      affiliations: Yup.array().of(
        Yup.object({
          person: Yup.object().shape({
            value: Yup.string().required("Required"),
            label: Yup.string(),
          }),
          natureOfRelationship: Yup.string().nullable(),
          informationSource: Yup.string().nullable(),
          relationshipConfirmed: Yup.object().shape({
            value: Yup.boolean(),
            label: Yup.string(),
          }),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: contractorId,
        data: values.affiliations?.map((item: any) => ({
          ...item,
          person: item.person?.value,
          relationshipConfirmed: item.relationshipConfirmed?.value,
        })),
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractorAffiliationsToPerson(data);
          validation.resetForm();
          toggle();
          toast.success("Affiliations Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating affiliations", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Affiliation to Persons
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.affiliations?.length || 0}
        </small>
      </ModalHeader>

      <PersonAffiliationsForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
      />
    </Modal>
  );
};

export default UpdateAffiliationsToPersons;
