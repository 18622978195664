import React from "react";
import { FeedbackFish } from "@feedback-fish/react";
import { useProfile } from "Components/Hooks/UserHooks";

const Feedback = () => {
  const { userProfile } = useProfile();
  return (
    <div className="customizer-setting d-none d-md-block">
      <FeedbackFish projectId="86e9a7d580786a" userId={userProfile?.email}>
        <div
          // onClick={() => {
          //   console.log("Clicked");
          // }}
          className="btn-primary rounded-pill shadow-lg btn btn-icon btn-lg p-2 rounded-pill"
        >
          <i className="mdi  mdi-chat-outline fs-22"></i>
        </div>
      </FeedbackFish>
    </div>
  );
};

export default Feedback;
