import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationFooter = ({
  page,
  totalPages,
  onPageChange,
}: {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const generatePageNumbers: any = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, page - 2);
    const endPage = Math.min(totalPages, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <Pagination
      listClassName="justify-content-center"
      className="pagination-separated mb-0 mt-3"
    >
      <PaginationItem disabled={page <= 1}>
        {" "}
        <PaginationLink previous onClick={() => onPageChange(page - 1)}>
          {" "}
          <i className="mdi mdi-chevron-left" />{" "}
        </PaginationLink>
      </PaginationItem>

      {generatePageNumbers().map((pageNumber: number) => (
        <PaginationItem key={pageNumber} active={pageNumber === page}>
          <PaginationLink onClick={() => onPageChange(pageNumber)}>
            {pageNumber}{" "}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={page >= totalPages}>
        {" "}
        <PaginationLink next onClick={() => onPageChange(page + 1)}>
          {" "}
          <i className="mdi mdi-chevron-right" />{" "}
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationFooter;
