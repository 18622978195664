import { searchClient } from "helpers/meiisearch_helper";

export const searchGlobalContent = (params: any) => {
  return searchClient
    .index(params?.index || "global_content")
    .search(params?.query, {
      hitsPerPage: params?.pageSize || 10,
      page: params?.page || 1,
      // filter: 'countryCode = "SO"',
    });
};
