import {
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Form,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useRef, useEffect } from "react";
import SimpleBar from "simplebar-react";
import SelectPerson from "./SelectPerson";
import Flatpickr from "react-flatpickr";

interface BoardOfDirectorsFormProps {
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
}

const BoardOfDirectorsForm = ({
  validation,
  toggle,
  loading,
}: BoardOfDirectorsFormProps) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <Row>
          <Col md={12} className="mb-3">
            <SimpleBar
              ref={simpleBarRef}
              style={{ maxHeight: "70vh" }}
              className=" px-4 py-4"
              autoHide={false}
            >
              {validation.values.boardOfDirectors.map(
                (director: any, index: number) => {
                  return (
                    <Card className="bg-light" key={index}>
                      <CardBody>
                        <Row>
                          <Col md={6} className="mb-3">
                            <SelectPerson
                              validation={validation}
                              index={index}
                              director={director}
                            />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`role-${index}`}
                              className="form-label"
                            >
                              Role
                            </Label>
                            <Input
                              name={`boardOfDirectors[${index}].role`}
                              id={`role-${index}`}
                              className="form-control"
                              placeholder="Enter role"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={director.role}
                              invalid={
                                validation.touched.boardOfDirectors?.[index]
                                  ?.role &&
                                !!validation.errors.boardOfDirectors?.[index]
                                  ?.role
                              }
                            />
                            {validation.touched.boardOfDirectors?.[index]
                              ?.role &&
                              validation.errors.boardOfDirectors?.[index]
                                ?.role && (
                                <FormFeedback>
                                  {
                                    validation.errors.boardOfDirectors[index]
                                      .role
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`from-${index}`}
                              className="form-label"
                            >
                              From
                            </Label>
                            <Flatpickr
                              name={`boardOfDirectors[${index}].fromDate`}
                              id={`fromDate-${index}`}
                              className="form-control"
                              placeholder="Select start date"
                              options={{
                                enableTime: false,
                                altInput: false,
                                altFormat: "d M, Y",
                                dateFormat: "d M, Y",
                              }}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  `boardOfDirectors[${index}].fromDate`,
                                  e[0]
                                );
                              }}
                              value={
                                validation.values?.boardOfDirectors[index]
                                  ?.fromDate || ""
                              }
                            />

                            {validation.touched?.boardOfDirectors &&
                            validation.touched?.boardOfDirectors[index]
                              ?.fromDate &&
                            validation.errors?.boardOfDirectors &&
                            validation.errors?.boardOfDirectors[index]
                              ?.fromDate ? (
                              <FormFeedback type="invalid">
                                {
                                  validation.errors?.boardOfDirectors[index]
                                    ?.fromDate
                                }
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md={6} className="mb-3">
                            <Label
                              htmlFor={`toDate-${index}`}
                              className="form-label"
                            >
                              To
                            </Label>
                            <Flatpickr
                              name={`boardOfDirectors[${index}].toDate`}
                              id={`toDate-${index}`}
                              className="form-control"
                              placeholder="Select end date"
                              options={{
                                enableTime: false,
                                altInput: false,
                                altFormat: "d M, Y",
                                dateFormat: "d M, Y",
                              }}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  `boardOfDirectors[${index}].toDate`,
                                  e[0]
                                );
                              }}
                              value={
                                validation.values?.boardOfDirectors[index]
                                  ?.toDate || ""
                              }
                            />

                            {validation.toDateuched?.boardOfDirectors &&
                            validation.toDateuched?.boardOfDirectors[index]
                              ?.toDate &&
                            validation.errors?.boardOfDirectors &&
                            validation.errors?.boardOfDirectors[index]
                              ?.toDate ? (
                              <FormFeedback type="invalid">
                                {
                                  validation.errors?.boardOfDirectors[index]
                                    ?.toDate
                                }
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <div className="mb-3 d-flex justify-content-end">
                            <Button
                              className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                              onClick={() => {
                                validation.setFieldValue(
                                  "boardOfDirectors",
                                  validation.values.boardOfDirectors.filter(
                                    (_: any, bodIndex: number) =>
                                      bodIndex !== index
                                  )
                                );
                              }}
                            >
                              {/* <i className="ri-delete-bin-fill me-2"></i> */}
                              Remove
                            </Button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                }
              )}
              <button
                className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
                onClick={(e) => {
                  e.preventDefault();

                  const newContractorRegistration = {
                    person: {
                      value: "",
                      label: "",
                    },
                    role: "",
                    fromDate: "",
                    toDate: "",
                  };

                  const boardOfDirectors = validation.values.boardOfDirectors;
                  validation.setFieldValue("boardOfDirectors", [
                    ...boardOfDirectors,
                    newContractorRegistration,
                  ]);
                }}
              >
                Add
              </button>
            </SimpleBar>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default BoardOfDirectorsForm;
