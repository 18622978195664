import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserForm from "./UserForm";
import { inviteUser } from "common/services/users.service";
import { toast } from "react-toastify";

const UpdateUser: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: any;
  fetchUpdatedUsers: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchUpdatedUsers }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: selectedRecord?.email,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter email")
        .email("Please enter a valid email"),
    }),
    onSubmit: async (values) => {
      const data = {
        email: values["email"],
      };

      setLoading(true);
      try {
        await inviteUser(data);
        toast.success("Invite sent successfully.", { autoClose: 500 });
        validation.resetForm();
        toggle();
      } catch (e: any) {
        toast.error("Error while sending invite.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      isOpen={isModalOpen}
      size="lg"
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Invite User
      </ModalHeader>

      <UserForm
        validation={validation}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateUser;
