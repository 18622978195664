import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getCapacityAssessments = (params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENTS, params);
export const getCapacityAssessment = (id: any, params: any) => {
  const populate = [
    "conductedBy",
    "reportFile",
    "createdby",
    "createdby.agency",
    "updatedby",
    "agency",
    "overallRating",
    "typeOfAssessment",
    "contractor",
  ];
  if (!params?.populate) params.populate = populate;
  return api.get(url.GET_CAPACITY_ASSESSMENT + "/" + id, params);
};

export const addCapacityAssessment = (data: any) =>
  api.createAndUpload(url.ADD_CAPACITY_ASSESSMENT, data);
export const updateCapacityAssessment = (data: any) =>
  api.updateAndUpload(
    url.UPDATE_CAPACITY_ASSESSEMENT + "/" + data.id,
    data.formData
  );
export const deleteCapacityAssessment = (data: any) =>
  api.delete(url.DELETE_CAPACITY_ASSESSMENT + "/" + data.id, data);

// Capacity Assessment Ratings
export const getCapacityAssessmentRatings = (params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_RATINGS, params);
export const getCapacityAssessmentRating = (id: any, params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_RATING + "/" + id, params);
export const addCapacityAssessmentRating = (data: any) =>
  api.create(url.ADD_CAPACITY_ASSESSMENT_RATING, data);
export const updateCapacityAssessmentRating = (data: any) =>
  api.update(url.UPDATE_CAPACITY_ASSESSMENT_RATING + "/" + data.id, data);
export const deleteCapacityAssessmentRating = (data: any) =>
  api.delete(url.DELETE_CAPACITY_ASSESSMENT_RATING + "/" + data.id, data);

// Capacity Assessment Types
export const getCapacityAssessmentTypes = (params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_TYPES, params);
export const getCapacityAssessmentType = (id: any, params: any) =>
  api.get(url.GET_CAPACITY_ASSESSMENT_TYPE + "/" + id, params);
export const addCapacityAssessmentType = (data: any) =>
  api.create(url.ADD_CAPACITY_ASSESSMENT_TYPE, data);
export const updateCapacityAssessmentType = (data: any) =>
  api.update(url.UPDATE_CAPACITY_ASSESSMENT_TYPE + "/" + data.id, data);
export const deleteCapacityAssessmentType = (data: any) =>
  api.delete(url.DELETE_CAPACITY_ASSESSMENT_TYPE + "/" + data.id, data);
