import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateContractor } from "common/services/contractors.service";
import { toast } from "react-toastify";
import ContactDetailsForm from "./ContactDetailsForm";

const UpdateContactDetails: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;

  contractor: any;
}> = ({ toggle, isModalOpen, contractor, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const contactPersons = contractor?.contactPersons || [];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessPhone: contractor?.businessPhone || "",
      businessEmail: contractor?.businessEmail || "",
      businessWebsite: contractor?.businessWebsite || "",
      businessPostalAddress: contractor?.businessPostalAddress || "",
      businessPhysicalAddress: contractor?.businessPhysicalAddress || "",
      contactPersons: (contactPersons.length > 0 &&
        contactPersons.map((item: any) => ({
          id: item.id,
          name: item.name,
          emailAddress: item.emailAddress,
          mobileNumber: item.mobileNumber,
          telephone: item.telephone,
          websiteOrLink: item.websiteOrLink,
          physicalAddress: item.physicalAddress,
        }))) || [
        {
          name: "",
          emailAddress: "",
          mobileNumber: "",
          telephone: "",
          websiteOrLink: "",
          physicalAddress: "",
        },
      ],
    },
    validationSchema: Yup.object({
      businessPhone: Yup.string(),
      businessEmail: Yup.string().email("Invalid email address"),
      businessWebsite: Yup.string(),
      businessPostalAddress: Yup.string(),
      businessPhysicalAddress: Yup.string(),

      contactPersons: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Name is required"),
          emailAddress: Yup.string().email("Invalid email address").nullable(),
          mobileNumber: Yup.string().nullable(),
          telephone: Yup.string().nullable(),
          websiteOrLink: Yup.string().nullable(),
          physicalAddress: Yup.string().nullable(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: contractor.id,
        data: {
          businessPhone: values.businessPhone,
          businessEmail: values.businessEmail,
          businessWebsite: values.businessWebsite,
          businessPostalAddress: values.businessPostalAddress,
          businessPhysicalAddress: values.businessPhysicalAddress,
          contactPersons: values.contactPersons?.map((item: any) => ({
            ...item,
          })),
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractor(data);
          validation.resetForm();
          toggle();
          toast.success("Contact Details Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating contact details", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contact Details
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.contractorRegistration?.length || 0}
        </small>
      </ModalHeader>

      <ContactDetailsForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateContactDetails;
