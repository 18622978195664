import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import * as moment from "moment";
import { CARD_MIN_HEIGHT } from "common/constants";

const MainDetails = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody>
            <div className="text-muted">
              <h6 className="mb-2 fw-semibold text-uppercase">
                Sanction Details
              </h6>
            </div>
          </CardBody>
          <CardBody style={{ minHeight: CARD_MIN_HEIGHT }}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Full Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.title || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Designate Type:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.designateType || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Reference:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.reference || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Alias Names:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.aliasNames || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">First Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.firstName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Middle Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.middleName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Last Name:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.lastName || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Desigation:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.designation || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Gender:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.gender || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Publish Date:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {(data?.publishDate &&
                    moment(new Date(data?.publishDate)).format("DD MMM, Y")) ||
                    ""}
                </Col>
              </Row>

              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Programme:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.programme || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Language:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.language || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Date Of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.dateOfBirth || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Place of Birth:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.placeOfBirth || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Address:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.address || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Citizenship:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.citizenship || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">
                    Identification Documents Information:
                  </div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.identificationDocumentsInformation || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Additional Information:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.additionalInformation || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">PDF Link:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.pdfLink || ""}
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MainDetails;
