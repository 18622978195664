import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { updateCountryOffice as onUpdateCountryOffice } from "../../slices/thunks";
import CountryOfficeForm from "./CountryOfficeForm";

const UpdateVehicleModel: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: any;
  fetchUpdatedCountryOffice: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchUpdatedCountryOffice }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: selectedRecord?.name || "",
      countries:
        (selectedRecord?.countries &&
          selectedRecord?.countries.map((el: any) => ({
            label: el.name,
            value: el.id,
          }))) ||
        [],
      blocked: selectedRecord?.blocked || false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      countries: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required("Country is required"),
            value: Yup.string().required("Country is required"),
          })
        )
        .min(1, "Select at least one country")
        .required("Country is required"),
    }),
    onSubmit: (values) => {
      const countries = values["countries"].map((el: any) => el.value);

      const updatedData = {
        name: values["name"],
        countries,
        // agency: values["agency"]["value"],
        // country: values["country"]["value"],
        // role: values["role"]["value"],
        // email: values["email"],
        // firstName: values["firstName"],
        // lastName: values["lastName"],
        // password: values["password"],
        blocked: values["blocked"],
      };
      // save new order
      setLoading(true);
      dispatch(
        onUpdateCountryOffice({
          id: selectedRecord.id,
          data: updatedData,
        })
      ).then((result: any) => {
        setLoading(false);

        if (result?.payload?.data) {
          fetchUpdatedCountryOffice();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  return (
    <Modal id="showModal" isOpen={isModalOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Country Office
      </ModalHeader>

      <CountryOfficeForm
        validation={validation}
        isUpdate={true}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateVehicleModel;
