import React, { useEffect, useMemo } from "react";
import CountUp from "react-countup";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { getContractorSummary } from "common/services/contractors.service";
import { formatCurrencySuffix } from "common/lib/currency";

const Statistics = ({ toggleTab }: { toggleTab: (tab: any) => void }) => {
  const [contractorSummary, setContractorSummary] = React.useState<any>();
  const [Loading, setLoading] = React.useState<boolean>(false);
  const { id: contractorId } = useParams();
  useEffect(() => {
    // Fetch contractor summary data
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getContractorSummary({ id: contractorId });

        setContractorSummary(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (contractorId) fetchData();
  }, [contractorId]);

  const totalValueOfContracts = useMemo(() => {
    return formatCurrencySuffix(contractorSummary?.totalValueOfContracts);
  }, [contractorSummary?.totalValueOfContracts]);

  return (
    <Row>
      <Col md={4} xs={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Contract Value
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      prefix={"$"}
                      suffix={totalValueOfContracts?.suffix}
                      separator={""}
                      end={totalValueOfContracts?.value}
                      decimals={2}
                      duration={4}
                    />
                  </span>
                </h4>
                <Link
                  to="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    toggleTab("2");
                  }}
                  className="text-decoration-underline"
                >
                  View Contracts
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
                  <i className={`text-success bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md={4} xs={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Assessments
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={""}
                      separator={""}
                      end={contractorSummary?.totalAssessments}
                      decimals={0}
                      duration={2}
                    />
                  </span>
                </h4>
                <Link
                  to="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    toggleTab("3");
                  }}
                  className="text-decoration-underline"
                >
                  View Assessments
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-primary-subtle"}>
                  <i className={`text-primary bx bx-file`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={4} xs={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  UN Agencies
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={""}
                      separator={","}
                      end={contractorSummary?.totalAgencies} // Change this value to change the counter value
                      decimals={0}
                      duration={4}
                    />
                  </span>
                </h4>
                <Link
                  to="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    toggleTab("2");
                  }}
                  className="text-decoration-underline"
                >
                  View contracts
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-info-subtle"}>
                  <i className={`text-info bx bx-shopping-bag `}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
