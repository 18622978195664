import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  ModalBody,
  Button,
  CardBody,
  Card,
} from "reactstrap";
import SimpleBar from "simplebar-react";

const ContactDetailsForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <SimpleBar
          ref={simpleBarRef}
          style={{ maxHeight: "80vh" }}
          className=" px-4 py-4"
          autoHide={false}
        >
          {validation.values.contactDetails.map(
            (contactDetail: any, index: number) => {
              return (
                <Card className="bg-light" key={index}>
                  <CardBody>
                    <Row key={index}>
                      <Col md={12} className="mb-3">
                        <Label
                          htmlFor={`email-${index}`}
                          className="form-label"
                        >
                          Email Address
                        </Label>
                        <Input
                          name={`contactDetails[${index}].emailAddress`}
                          id={`email-${index}`}
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          validate={{ required: { value: true }, email: true }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={contactDetail.emailAddress || ""}
                          invalid={
                            validation.touched.contactDetails?.[index]
                              ?.emailAddress &&
                            validation.errors.contactDetails?.[index]
                              ?.emailAddress
                              ? true
                              : false
                          }
                        />
                        {validation.touched.contactDetails?.[index]
                          ?.emailAddress &&
                          validation.errors.contactDetails?.[index]
                            ?.emailAddress && (
                            <FormFeedback>
                              {
                                validation.errors.contactDetails[index]
                                  .emailAddress
                              }
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={6} className="mb-3">
                        <Label
                          htmlFor={`phone-${index}`}
                          className="form-label"
                        >
                          Phone
                        </Label>
                        <Input
                          name={`contactDetails[${index}].phone`}
                          id={`phone-${index}`}
                          className="form-control"
                          placeholder="Enter phone number"
                          type="text"
                          validate={{ required: { value: true } }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={contactDetail.phone || ""}
                          invalid={
                            validation.touched.contactDetails?.[index]?.phone &&
                            validation.errors.contactDetails?.[index]?.phone
                              ? true
                              : false
                          }
                        />
                        {validation.touched.contactDetails?.[index]?.phone &&
                          validation.errors.contactDetails?.[index]?.phone && (
                            <FormFeedback>
                              {validation.errors.contactDetails[index].phone}
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={6} className="mb-3">
                        <Label
                          htmlFor={`postalAddress-${index}`}
                          className="form-label"
                        >
                          Postal Address
                        </Label>
                        <Input
                          name={`contactDetails[${index}].postalAddress`}
                          id={`postalAddress-${index}`}
                          className="form-control"
                          placeholder="Enter postal address "
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={contactDetail.postalAddress || ""}
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <Label
                          htmlFor={`websiteOrLink-${index}`}
                          className="form-label"
                        >
                          Website or Link
                        </Label>
                        <Input
                          name={`contactDetails[${index}].websiteOrLink`}
                          id={`websiteOrLink-${index}`}
                          className="form-control"
                          placeholder="Enter website or link"
                          type="url"
                          validate={{ url: true }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={contactDetail.websiteOrLink || ""}
                          invalid={
                            validation.touched.contactDetails?.[index]
                              ?.websiteOrLink &&
                            validation.errors.contactDetails?.[index]
                              ?.websiteOrLink
                              ? true
                              : false
                          }
                        />
                        {validation.touched.contactDetails?.[index]
                          ?.websiteOrLink &&
                          validation.errors.contactDetails?.[index]
                            ?.websiteOrLink && (
                            <FormFeedback>
                              {
                                validation.errors.contactDetails[index]
                                  .websiteOrLink
                              }
                            </FormFeedback>
                          )}
                      </Col>
                      <Col md={6} className="mb-3">
                        <Label
                          htmlFor={`physicalAddress-${index}`}
                          className="form-label"
                        >
                          Physical Address
                        </Label>
                        <Input
                          name={`contactDetails[${index}].physicalAddress`}
                          id={`physicalAddress-${index}`}
                          className="form-control"
                          placeholder="Enter physical address"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={contactDetail.physicalAddress || ""}
                        />
                      </Col>
                      <div className="mb-3 d-flex justify-content-end">
                        <Button
                          className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                          onClick={(e) => {
                            e.preventDefault();
                            validation.setFieldValue(
                              "contactDetails",
                              validation.values.contactDetails.filter(
                                (_: any, contactDetailIndex: number) =>
                                  contactDetailIndex !== index
                              )
                            );
                          }}
                        >
                          {/* <i className="ri-delete-bin-fill me-2"></i> */}
                          Remove
                        </Button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              );
            }
          )}

          <button
            className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
            disabled={validation.values.contactDetails.length === 3}
            onClick={(e) => {
              e.preventDefault();
              if (validation.values.contactDetails.length < 3) {
                const newContactDetail = {
                  emailAddress: "",
                  phone: "",
                  postalAddress: "",
                  websiteOrLink: "",
                  physicalAddress: "",
                };
                validation.setFieldValue("contactDetails", [
                  ...validation.values.contactDetails,
                  newContactDetail,
                ]);
              }
            }}
          >
            Add
          </button>
        </SimpleBar>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ContactDetailsForm;
