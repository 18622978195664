import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getUsers = (params: any) => api.get(url.GET_USERS, params);
export const getUser = (params: any) => api.get(url.GET_USER, params);
export const addUser = (payload: any) => api.create(url.ADD_USER, payload);
export const updateUser = (payload: any) =>
  api.update(url.UPDATE_USER + "/" + payload.id, payload);
export const deleteUser = (payload: any) =>
  api.delete(url.DELETE_USER + "/" + payload.id, payload);
export const activateUser = (payload: any) =>
  api.update(url.ACTIVATE_USER + "/" + payload.id, payload);
export const deactivateUser = (payload: any) =>
  api.update(url.DEACTIVATE_USER + "/" + payload.id, payload);
export const sendPasswordResetLink = (payload: any) =>
  api.create(url.POST_FORGET_PASSWORD, payload);

export const getUsersDefault = (params: any) =>
  api.get(url.GET_USERS_DEFAULT, params);

export const getCountryUsers = (params: any) => api.get(url.GET_USERS, params);

export const getAgencyUsers = (params: any) =>
  api.get(url.GET_AGENCY_USERS, params);
export const inviteUser = (payload: any) =>
  api.create(url.INVITE_USER, payload);

export const updateMyProfile = (payload: any) =>
  api.update(url.UPDATE_MY_PROFILE, payload);

export const changePassword = (data: any) =>
  api.create(url.POST_CHANGE_PASSWORD, data);
