import React, { useEffect, useMemo, useState } from "react";
import { Card, CardHeader, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Components/Common/useChartColors";
import { getContractDistributionByType } from "common/services/contracts.service";

const DonutChart = ({ chartId, labels, series }: any) => {
  const chartColors = useChartColors(chartId);

  var options: any = {
    labels: labels,
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        id={chartId}
        type="donut"
        height="450"
        data-colors='["#3d78e3", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
        data-colors-minimal='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.70", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]'
        data-colors-interactive='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.70", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]'
        data-colors-galaxy='["--vz-primary", "--vz-primary-rgb, 0.85", "--vz-primary-rgb, 0.70", "--vz-primary-rgb, 0.60", "--vz-primary-rgb, 0.45"]'
        className="apex-charts"
      />
    </React.Fragment>
  );
};
const DistributionByContractType = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getContractDistributionByType({});
        setData(response);
        setLoading(false);
      } catch (e: any) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const labels = useMemo(() => {
    return (data && data.map((item: any) => item.name)) || [];
  }, [data]);

  const series = useMemo(() => {
    return (
      (data && data.map((item: any) => parseFloat(item.totalAmount))) || []
    );
  }, [data]);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Distribution by Contract Type
            </h4>
            <div className="flex-shrink-0"></div>
          </CardHeader>

          <div className="card-body">
            <DonutChart
              chartId="contract-distribution-by-type-per-agency"
              labels={labels}
              series={series}
            />
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DistributionByContractType;
