import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getPvrs = (params: any) => api.get(url.GET_PVRS, params);
export const getPvr = (id: any, params: any) => {
  const populate = [
    "conductedBy",
    "pvrFile",
    "createdby",
    "createdby.agency",
    "updatedby",
    "agency",
    "contractor",
  ];
  if (!params?.populate) params.populate = populate;
  return api.get(url.GET_PVR + "/" + id, params);
};
export const addPvr = (data: any) => api.createAndUpload(url.ADD_PVR, data);
export const updatePvr = (data: any) =>
  api.updateAndUpload(url.UPDATE_PVR + "/" + data.id, data.formData);
export const deletePvr = (data: any) =>
  api.delete(url.DELETE_PVR + "/" + data.id, data);
