import React from "react";
import { useParams } from "react-router-dom";
import AllContracts from "pages/Contracts/AllContracts";

const Contractstab = () => {
  const { id: contractorId } = useParams();

  return <AllContracts isViewContractors={true} contractorId={contractorId} />;
};

export default Contractstab;
