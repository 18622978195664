import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useState } from "react";
import { Container } from "reactstrap";
import PersonsForm from "./PersonsForm";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addPerson as onAddPerson } from "../../slices/thunks";
import { useNavigate } from "react-router-dom";
import { LEVEL_COUNTRY } from "common/constants";

const AddPerson = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: "",
      firstName: "",
      surname: "",
      middleName: "",
      gender: "",
      dateOfBirth: "",
      currentNationality: {
        value: "",
        label: "",
      },
      nationalityAtBirth: {
        value: "",
        label: "",
      },
      alias: [""],
      contactDetails: [
        {
          emailAddress: "",
          phone: "",
          postalAddress: "",
          websiteOrLink: "",
          physicalAddress: "",
        },
      ],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please select title"),
      firstName: Yup.string().required("Please enter first name"),
      surname: Yup.string().required("Please enter surname"),
      middleName: Yup.string().required("Please enter middle name"),
      alias: Yup.array().of(Yup.string()),
      currentNationality: Yup.object().shape({
        value: Yup.number().required("Please select current nationality"),
      }),
      nationalityAtBirth: Yup.object().shape({
        value: Yup.number().required("Please select nationality at birth"),
      }),
      gender: Yup.string(),
      dateOfBirth: Yup.string(),
      photo: Yup.array(),
      contactDetails: Yup.array().of(
        Yup.object({
          emailAddress: Yup.string(),
          phone: Yup.string(),
          postalAddress: Yup.string(),
          websiteOrLink: Yup.string(),
          physicalAddress: Yup.string(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        data: {
          title: values["title"],
          firstName: values["firstName"],
          surname: values["surname"],
          middleName: values["middleName"],
          currentNationality: values["currentNationality"]["value"] || null,
          nationalityAtBirth: values["nationalityAtBirth"]["value"] || null,
          alias:
            Array.isArray(values["alias"]) && values["alias"].length > 0
              ? values["alias"].map((el) => {
                  return {
                    name: el,
                  };
                })
              : null,
          gender: values["gender"],
          dateOfBirth: values["dateOfBirth"],
          contactDetails: values["contactDetails"],
        },
      };
      // save new order
      setLoading(true);
      dispatch(
        onAddPerson({
          level: LEVEL_COUNTRY,
          params: data,
        })
      ).then((result: any) => {
        setLoading(false);
        if (result?.payload?.data) {
          navigate("/persons/view/" + result?.payload?.data?.id);
        }
      });
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Add Person" pageTitle="Persons" />

        <PersonsForm
          validation={validation}
          isUpdate={false}
          loading={loading}
          setLoading={setLoading}
        />
      </Container>
    </div>
  );
};

export default AddPerson;
