import { createSlice } from "@reduxjs/toolkit";
import {
  getGlobalAgencies,
  getAgencies,
  addAgency,
  updateAgency,
  deleteAgency,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  agencies: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  globalAgencies: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const AgenciesSlice = createSlice({
  name: "AgenciesSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalAgencies.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);
        state.globalAgencies.data = data || state.globalAgencies.data;
      }
      state.globalAgencies.meta =
        action.payload?.meta || state.globalAgencies.meta;
      state.loading = false;
    });
    builder.addCase(getGlobalAgencies.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    builder.addCase(getAgencies.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);
        state.agencies.data = data || state.agencies.data;
      }
      state.agencies.meta = action.payload?.meta || state.agencies.meta;
      state.loading = false;
    });
    builder.addCase(getAgencies.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });

    builder.addCase(addAgency.fulfilled, () => {
      toast.success("Agency Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addAgency.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Agency Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateAgency.fulfilled, () => {
      toast.success("Agency Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(updateAgency.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Agency Update Failed", { autoClose: 3000 });
    });
    builder.addCase(deleteAgency.fulfilled, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.success("Agency Deleted Successfully", { autoClose: 3000 });
    });
    builder.addCase(deleteAgency.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
      toast.error("Agency Deletion Failed", { autoClose: 3000 });
    });
  },
});

export const { setLoading } = AgenciesSlice.actions;

export default AgenciesSlice.reducer;
