import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import ImportUNGMList from "./ImportUNGMList";
import { getSortObj } from "common/lib/tableDataOptions";
import { getUNGMList as onGetUNGMList } from "../../../slices/thunks";
import * as moment from "moment";
import Permission from "Components/Common/Permission";

const UNGMSanctionsList = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {}, [dispatch]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const selectProperties = createSelector(
    (state: any) => state.SanctionsList,
    (state) => ({
      ungmList: state.ungmList.data,
      meta: state.ungmList.meta,
      error: state.error,
      loading: state.loading,
    })
  );
  const { ungmList, meta, loading } = useSelector(selectProperties);

  const fetchUNGMList = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const pageSize = 20;

  const [searchValue, setSearchValue] = useState("");

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetUNGMList({
          pagination: {
            page,
            pageSize: 20,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: [
              {
                dataSource: {
                  $eq: "UN List",
                },
              },

              {
                $or: [
                  {
                    companyName: {
                      $containsi: searchValue,
                    },
                  },
                ],
              },
            ],
          },
        })
      );
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        header: "Contractor Name",
        accessorKey: "companyName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/ungm/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Country",
        accessorKey: "country",
        enableColumnFilter: false,
      },
      {
        header: "Sanction Start Date",
        accessorKey: "sanctionStartDate",
        id: "sanctionStartDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date =
            (cell.getValue() &&
              moment(new Date(cell?.getValue())).format("DD MMM Y")) ||
            "";
          return <>{date}</>;
        },
      },
      {
        header: "Sanction End Date",
        accessorKey: "sanctionEndDate",
        id: "sanctionEndDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date =
            (cell.getValue() &&
              moment(new Date(cell?.getValue())).format("DD MMM Y")) ||
            "";
          return <>{date}</>;
        },
      },
      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="UNGM Debarred List" pageTitle="Sanctions List" />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={8}>
                  <h5 className="card-title">UNGM Debarred List</h5>
                  <p className="mb-0 text-muted">
                    This is a resource list of sanctioned/suspended vendors
                    maintained by UN organizations and shared through the
                    <a
                      href="https://www.ungm.org/"
                      className="mx-1 text-primary"
                    >
                      UN Global Market Place Website.
                    </a>
                  </p>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search UNGM debarred list..."
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    <Permission resource="ungm-list" action="uploadContent">
                      <button
                        className="btn btn-primary"
                        onClick={() => showAddModalForm()}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        Import
                      </button>
                    </Permission>
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={ungmList || []}
                customPageSize={pageSize}
                pagination={meta?.pagination || {}}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                parentSearchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />

              <ImportUNGMList
                isModalOpen={isAddModalOpen}
                toggle={() => setIsAddModalOpen(!isAddModalOpen)}
                fetchUNGMList={fetchUNGMList}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UNGMSanctionsList;
