import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getCountries as onGetCountries } from "../../slices/thunks";

const Countries = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: any;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.countries && validation.errors.countries
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.countries && validation.errors.countries
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetCountries({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Countries;
  const selectVehicleMakeProperties = createSelector(
    selectLayoutState,
    (state) => ({
      countries: state.countries.data,
    })
  );

  const { countries } = useSelector(selectVehicleMakeProperties);

  const countryOptions = useMemo(() => {
    return countries.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [countries]);

  return (
    <FormGroup>
      <Label htmlFor="countries-field" className="form-label">
        Countries
      </Label>
      <Select
        name="countries"
        id="countries"
        isMulti
        value={validation.values.countries ? validation.values.countries : null}
        placeholder="Select countries"
        onChange={(value: any) => {
          validation.setFieldValue("countries", value);
        }}
        options={countryOptions}
        onBlur={() => validation.setFieldTouched("countries", true)}
        className={
          validation.touched.countries && validation.errors.countries
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.countries?.value}</FormFeedback>
    </FormGroup>
  );
};

Countries.propTypes = {
  validation: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default Countries;
