import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getRiskAssessments as getRiskAssessmentsApi,
  getRiskAssessment as getRiskAssessmentApi,
  addRiskAssessment as addRiskAssessmentApi,
  updateRiskAssessment as updateRiskAssessmentApi,
  deleteRiskAssessment as deleteRiskAssessmentApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getRiskAssessments = createAsyncThunk(
  "riskAssessment/getRiskAssessments",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getRiskAssessmentsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getRiskAssessment = createAsyncThunk(
  "riskAssessment/getRiskAssessment",
  async (id: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params = {
        populate: ["contractor", "overallRiskRating", "conductedBy", "agency"],
      };
      const response = getRiskAssessmentApi(id, params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addRiskAssessment = createAsyncThunk(
  "riskAssessment/addRiskAssessment",
  async (riskAssessment: any) => {
    try {
      const response = addRiskAssessmentApi(riskAssessment);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateRiskAssessment = createAsyncThunk(
  "riskAssessment/updateRiskAssessment",
  async (riskAssessment: any) => {
    try {
      const response = updateRiskAssessmentApi(riskAssessment);
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteRiskAssessment = createAsyncThunk(
  "riskAssessment/deleteRiskAssessment",
  async (riskAssessment: any) => {
    try {
      const response = deleteRiskAssessmentApi(riskAssessment);
      return response;
    } catch (error) {
      return error;
    }
  }
);
