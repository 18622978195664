import React from "react";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import {
  CARD_MIN_HEIGHT,
  CLASSIFICATION_CONFIDENTIAL,
  CLASSIFICATION_RESTRICTED,
} from "common/constants";
import FileIcon from "Components/Common/FileIcon";
import Permission from "Components/Common/Permission";
import RiskAssessmentRating from "pages/Assessments/RiskAssessments/RiskAssessmentRating";
import moment from "moment";

const MainDetails = ({ data }: { data: any }) => {
  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody>
            <div className="text-muted">
              <h6 className="mb-2 fw-semibold text-uppercase">
                Assessment Details
              </h6>
            </div>
          </CardBody>
          <CardBody style={{ minHeight: CARD_MIN_HEIGHT }}>
            <div className="fs-13">
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Report Title:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.reportTitle || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Contractor Assessed:</div>
                </Col>
                <Col lg={9} sm={9}>
                  <Link
                    to={`/contractors/${data?.contractor?.id}`}
                    className="text-truncate d-block"
                  >
                    <i className="ri-building-line align-bottom me-1"></i>{" "}
                    {data?.contractor?.contractorName || ""}
                  </Link>
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Assessment provided by:</div>
                </Col>
                <Col lg={9} sm={9}>
                  <Link to={`#`} className="text-truncate d-block">
                    {data?.agency?.name || ""}
                  </Link>
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Conducted By:</div>
                </Col>
                <Col lg={9} sm={9}>
                  <Link
                    to={`/contractors/${data?.conductedBy?.id}`}
                    className="text-truncate d-block"
                  >
                    <i className="ri-building-line align-bottom me-1"></i>{" "}
                    {data?.conductedBy?.contractorName || ""}
                  </Link>
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Type of Assessment:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {`${data?.typeOfAssessment?.name || ""}`}
                </Col>
              </Row>
              {/* <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Sensitivity / Classification:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.sensitivity || ""}
                </Col>
              </Row> */}
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Assessment Date:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {moment(data?.reportDate).format("DD MMM, Y") || ""}
                </Col>
              </Row>
              <Row className="px-1 mb-2">
                <Col lg={3} sm={3}>
                  <div className="fw-medium">Summary of Findings:</div>
                </Col>
                <Col lg={9} sm={9}>
                  {data?.summaryOfFindings || ""}
                </Col>
              </Row>

              <div className="pt-3 border-top border-top-dashed mt-4">
                <Row className="gy-3">
                  <Col lg={6} sm={6}>
                    <div>
                      <h6 className="mb-3 fw-semibold text-uppercase">
                        Overall Rating:
                      </h6>
                      <div className=" fs-12">
                        <RiskAssessmentRating
                          rating={data?.overallRating?.name}
                          solid
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="pt-3 border-top border-top-dashed mt-4">
                <h6 className="mb-3 fw-semibold text-uppercase">
                  Documents & Resources
                </h6>

                <Row className="g-3">
                  {data.reportFile &&
                    data?.reportFile?.map((doc: any, i: number) => {
                      const url = `${process.env.REACT_APP_FILES_URL}${doc?.url}`;
                      const target = "_blank";
                      return (
                        <Col xxl={6} lg={6}>
                          <div
                            className="border rounded border-dashed p-2"
                            key={i}
                          >
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar-sm">
                                  <div className="avatar-title bg-light text-secondary rounded fs-24">
                                    <FileIcon fileName={doc?.name} />
                                  </div>
                                </div>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <h5 className="fs-13 mb-1">
                                  <Permission
                                    alternative={
                                      <Link
                                        to="#"
                                        className="text-body text-truncate d-block"
                                      >
                                        {doc?.name}
                                      </Link>
                                    }
                                    resource={"capacity-assessment"}
                                    action={"download"}
                                  >
                                    <Link
                                      to={`${url}`}
                                      className="text-body text-truncate d-block"
                                      target={target}
                                    >
                                      {doc?.name}
                                    </Link>
                                  </Permission>
                                </h5>
                                <div>{doc?.formattedSize}</div>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex gap-1">
                                  <Permission
                                    alternative={
                                      <Link
                                        className="btn btn-icon text-muted btn-sm fs-18"
                                        to={`#`}
                                      >
                                        <i className="ri-download-2-line "></i>
                                      </Link>
                                    }
                                    resource={"capacity-assessment"}
                                    action={"download"}
                                  >
                                    <Link
                                      className="btn btn-icon text-muted btn-sm fs-18"
                                      to={`${url}`}
                                      target={target}
                                    >
                                      <i className="ri-download-2-line"></i>
                                    </Link>
                                  </Permission>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MainDetails;
