import { ICON_CALENDAR } from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";

const SanctionItem = ({
  data,
  index,
  entityType,
}: {
  data: any;
  index: number;
  entityType: any;
}) => {
  let link = "#";
  if (entityType === "eu-sanctions-list") {
    link = `/sanctions-list/$eu/{data?.id}`;
  } else if (entityType === "us-sanctions-list") {
    link = `/sanctions-list/us/${data?.id}`;
  } else if (entityType === "uk-sanctions-list") {
    link = `/sanctions-list/uk/${data?.id}`;
  } else if (entityType === "un-designated-list") {
    link = `/sanctions-list/un/${data?.id}`;
  } else if (entityType === "ungm-list") {
    link = `/sanctions-list/ungm/${data?.id}`;
  }

  return (
    <div className="py-3">
      <h5 className="mb-1">
        <Link to={link}>{data?.title || data?.companyName}</Link>
      </h5>
      <p className="text-success mb-2">
        <span className="me-2">
          {entityType === "eu-sanctions-list" && "EU Sanctions List"}
          {entityType === "us-sanctions-list" && "US Sanctions List"}
          {entityType === "uk-sanctions-list" && "UK Sanctions List"}
          {entityType === "un-designated-list" && "UN Designated List"}
          {entityType === "ungm-list" && "UNGM List"}
        </span>
        {/* <span className="badge bg-warning-subtle text-warning fs-11 me-2">
          SEMG Mention
        </span> */}
        {entityType === "eu-sanctions-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            EU Sanction
          </span>
        )}
        {entityType === "us-sanctions-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            US Sanction
          </span>
        )}
        {entityType === "uk-sanctions-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            UK Sanction
          </span>
        )}
        {entityType === "un-designated-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            UN Designated
          </span>
        )}
        {entityType === "ungm-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            UNGM List
          </span>
        )}
      </p>
      <p className="text-muted mb-2">
        ... Alias Name 1, Alias Name 2, Alias Name 3, Alias Name 4, Alias Name
        5...
      </p>
      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CALENDAR}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">
                {moment(new Date(data?.updatedAt)).format("DD MMM  Y")}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SanctionItem;
