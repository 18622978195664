import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from "../../Components/Common/withRouter";

// action
import { userForgetPassword, addContract } from "slices/thunks";

// import images
// import profile from "../../assets/images/bg.png";
import logoLight from "../../assets/images/cims-logo-light.png";
import { createSelector } from "reselect";

import backgroundImage from "../../assets/images/signin-main-bg.jpg";
import { APP_NAME_SHORT } from "common/constants";

import AuthSlider from "./AuthSlider";
import { useDispatch, useSelector } from "react-redux";

const ForgetPassword = (props: any) => {
  const dispatch: any = useDispatch();

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Please enter a valid email address"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history)).then(
        (result: any) => {
          if (result?.ok) {
            validation.resetForm();
          }
        }
      );
    },
  });

  const selectLayoutProperties = createSelector(
    (state: any) => state.ForgetPassword,
    (state) => ({
      forgetError: state.forgetError,
      forgetSuccessMsg: state.forgetSuccessMsg,
      loading: state.loading,
    })
  );

  const { forgetError, forgetSuccessMsg, loading } = useSelector(
    selectLayoutProperties
  );

  document.title = "Reset Password | CIMS";

  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "rgba(1,78,107, 0.9)",
        }}
      >
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden card-bg-fill galaxy-border-none">
                  <Row className="g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary">Forgot Password?</h5>
                          <p className="text-muted">Reset your CIMS password</p>

                          <i className="ri-mail-send-line display-5 text-primary mb-3"></i>
                        </div>

                        <Alert
                          className="border-0 alert-warning text-center mb-2 mx-2"
                          role="alert"
                        >
                          Please enter your email and the instructions will be
                          sent to you!
                        </Alert>
                        <div className="p-2">
                          {forgetError && forgetError ? (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {forgetError}
                            </Alert>
                          ) : null}
                          {forgetSuccessMsg ? (
                            <Alert
                              color="success"
                              style={{ marginTop: "13px" }}
                            >
                              {forgetSuccessMsg}
                            </Alert>
                          ) : null}
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="mb-4">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.email}</div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="text-center mt-4">
                              <button
                                className="btn btn-primary w-100"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner size="sm" className="me-2">
                                    Loading...
                                  </Spinner>
                                ) : null}
                                Send Reset Link
                              </button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 mb-4 text-center">
                          <p className="mb-0">
                            Back to Sign In Page...{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Sign In
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer galaxy-border-none">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0">
                    CIMS {new Date().getFullYear()} - Risk Management Unit
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgetPassword);
