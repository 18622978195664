import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addAgencyContract } from "common/services/contracts.service";
import ContractForm from "./ContractForm";
import { toast } from "react-toastify";

const AddContract: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchContracts: () => void;
}> = ({ toggle, isModalOpen, fetchContracts }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractTitle: "",
      contractNo: "",
      contractor: {
        value: "",
        label: "",
      },
      subContractedTo: {
        value: "",
        label: "",
      },
      contractRating: {
        value: "",
        label: "",
      },
      contractType: {
        value: "",
        label: "",
      },
      contractValue: "",
      startDate: "",
      endDate: "",
      isLTA: false,

      regions: [],
    },
    validationSchema: Yup.object({
      contractTitle: Yup.string().required("Please enter contract title"),
      contractNo: Yup.string().required("Please enter contract number"),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      subContractedTo: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      contractRating: Yup.object().shape({
        value: Yup.number().nullable(),
      }),
      contractType: Yup.object().shape({
        value: Yup.number().required("Please select contract type"),
      }),
      contractValue: Yup.number().required("Please enter contract value"),
      startDate: Yup.string(),
      endDate: Yup.string(),

      regions: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required("Country is required"),
          value: Yup.string().required("Country is required"),
        })
      ),
    }),
    onSubmit: async (values: any) => {
      const data = {
        data: {
          contractTitle: values["contractTitle"],
          contractor: values["contractor"]["value"] || null,
          contractNo: values["contractNo"],
          subContractedTo: values["subContractedTo"]["value"] || null,
          contractRating: values["contractRating"]["value"] || null,
          contractType: values["contractType"]["value"] || null,
          contractValue: values["contractValue"],
          startDate: values["startDate"],
          endDate: values["endDate"],
          isLTA: values["isLTA"],
          location: values["regions"].map((el: any) => el.value),
        },
      };
      // save new order
      setLoading(true);
      try {
        await addAgencyContract(data);
        fetchContracts();
        validation.resetForm();
        toggle();
        toast.success("Contract Added Successfully", { autoClose: 500 });
      } catch (e: any) {
        toast.error("Contract Creation Failed", {
          autoClose: 500,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Contract
      </ModalHeader>

      <ContractForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddContract;
