import { createSlice } from "@reduxjs/toolkit";
import {
  getRoles,
  // addNewRole,
  // updateRole,
  // deleteRole,
} from "./thunk";
import { toast } from "react-toastify";

export const initialState: any = {
  roles: {
    data: [],
    meta: {
      page: 1,
      pageSize: 25,
      pageCount: 0,
      total: 0,
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const RoleSlice = createSlice({
  name: "RoleSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoles.fulfilled, (state: any, action: any) => {
      if (action.payload?.roles) {
        // const data: any = action.payload?.data.map((item: any) => {
        //   return {
        //     ...item.attributes,
        //     id: item.id,
        //   };
        // });
        state.roles.data = action?.payload?.roles || state.roles.data;
      }
      state.roles.meta = action.payload?.meta || state.roles.meta;
      state.loading = false;
    });
    builder.addCase(getRoles.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 1000,
      });
    });
  },
});

export const { setLoading } = RoleSlice.actions;

export default RoleSlice.reducer;
