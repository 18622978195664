import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ContractDistributionByAgencyCharts } from "./ContractDistributionCharts";
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import {
  setDistributionByAgency,
  setContractTotals,
} from "slices/contract/reducer";
import { createSelector } from "reselect";
import { formatCurrencySuffix } from "common/lib/currency";
import {
  getContractDistributionByAgency,
  getContractTotals,
} from "common/services/contracts.service";

// get the past 4 years and save them to 4 different variables
const currentYear = new Date().getFullYear();

const year0 = currentYear.toString();
const year1 = (currentYear - 1).toString();
const year2 = (currentYear - 2).toString();
const year3 = (currentYear - 3).toString();

// Helper function to get the start and end date for a given year
function getYearRange(year: any) {
  const startDate = new Date(`${year}-01-01`);
  const endDate = new Date(`${year}-12-31`);
  return { startDate, endDate };
}

const year0Dates = getYearRange(year0);
const year1Dates = getYearRange(year1);
const year2Dates = getYearRange(year2);
const year3Dates = getYearRange(year3);

const ContractsDistribution = () => {
  const dispatch: any = useDispatch();

  const [chartData, setchartData] = useState<any>([
    {
      name: "Active Contract Value",
      type: "area",
      data: [],
    },
    {
      name: "Total Contract Value",
      type: "bar",
      data: [],
    },
    {
      name: "Expired Contract Value",
      type: "line",
      data: [],
    },
  ]);

  const selectProperties = createSelector(
    (state: any) => state,
    (state) => ({
      distributionByAgency: state.Contracts.distributionByAgency,
      contractTotals: state.Contracts.contractTotals,
    })
  );

  const { distributionByAgency, contractTotals } =
    useSelector(selectProperties);

  useEffect(() => {
    const validAgencyData = distributionByAgency.data?.filter(
      (item: any) => item.totalContractValue > 0
    );
    const newChartData = [
      {
        name: "Active Contract Value",
        type: "area",
        data: validAgencyData?.map(
          (item: any) => item.totalActiveContractValue
        ),
      },
      {
        name: "Total Contract Value",
        type: "bar",
        data: validAgencyData?.map((item: any) => item.totalContractValue),
      },
      {
        name: "Expired Contract Value",
        type: "line",
        data: validAgencyData?.map(
          (item: any) => item.totalInactiveContractValue
        ),
      },
    ];
    setchartData(newChartData);
  }, [distributionByAgency.data]);

  const [activeYear, setActiveYear] = useState<any>(year0);
  const [startDate, setStartDate] = useState<any>(year0Dates.startDate);
  const [endDate, setEndDate] = useState<any>(year0Dates.endDate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getContractDistributionByAgency({
          startDate,
          endDate,
        });
        dispatch(setDistributionByAgency(response.data));
      } catch (error) {
        console.error("Error fetching contract distribution by agency", error);
      }
    };
    fetchData();
  }, [dispatch, endDate, startDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getContractTotals({
          startDate,
          endDate,
        });

        dispatch(setContractTotals(response));
      } catch (error) {
        console.error("Error fetching contract totals", error);
      }
    };
    fetchData();
  }, [dispatch, endDate, startDate]);

  console.log("Total Contract Value", contractTotals?.totalContractValue);

  const totalContractValue = formatCurrencySuffix(
    contractTotals?.totalContractValue
  );

  console.log("Format Currency Suffix", totalContractValue);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Distribution of Contracts by Agency
          </h4>
          <div className="d-flex gap-1">
            <button
              type="button"
              className={`btn ${
                activeYear === "2024"
                  ? "btn-soft-primary"
                  : "btn-soft-secondary"
              } btn-sm`}
              onClick={() => {
                setActiveYear(year0);
                setStartDate(year0Dates.startDate);
                setEndDate(year0Dates.endDate);
              }}
            >
              2024
            </button>
            <button
              type="button"
              className={`btn ${
                activeYear === "2023"
                  ? "btn-soft-primary"
                  : "btn-soft-secondary"
              } btn-sm`}
              onClick={() => {
                setActiveYear(year1);
                setStartDate(year1Dates.startDate);
                setEndDate(year1Dates.endDate);
              }}
            >
              2023
            </button>
            <button
              type="button"
              className={`btn ${
                activeYear === "2022"
                  ? "btn-soft-primary"
                  : "btn-soft-secondary"
              } btn-sm`}
              onClick={() => {
                setActiveYear(year2);
                setStartDate(year2Dates.startDate);
                setEndDate(year2Dates.endDate);
              }}
            >
              2022
            </button>
            <button
              type="button"
              className={`btn ${
                activeYear === "2021"
                  ? "btn-soft-primary"
                  : "btn-soft-secondary"
              } btn-sm`}
              onClick={() => {
                setActiveYear(year3);
                setStartDate(year3Dates.startDate);
                setEndDate(year3Dates.endDate);
              }}
            >
              2021
            </button>
            <button
              type="button"
              className={`btn ${
                activeYear === "All" ? "btn-soft-primary" : "btn-soft-secondary"
              } btn-sm`}
              onClick={() => {
                setActiveYear("All");
                setStartDate("2001-01-01");
                setEndDate("2050-12-31");
              }}
            >
              All
            </button>
          </div>
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-light-subtle">
          <Row className="g-0 text-center">
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    start={0}
                    end={contractTotals?.totalContracts}
                    duration={1}
                    separator=","
                  />
                </h5>
                <p className="text-muted mb-0">Number of contracts</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    suffix={totalContractValue.suffix}
                    prefix="$"
                    start={0}
                    decimals={1}
                    end={totalContractValue.value}
                    duration={1}
                  />
                </h5>
                <p className="text-muted mb-0">Contract Value</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    start={0}
                    end={contractTotals?.totalContractors}
                    duration={1}
                  />
                </h5>
                <p className="text-muted mb-0">Contractors</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0 border-end-0">
                <h5 className="mb-1 text-success">
                  <CountUp
                    start={0}
                    end={contractTotals?.percentageActiveContracts}
                    decimals={2}
                    duration={1}
                    suffix="%"
                  />
                </h5>
                <p className="text-muted mb-0">% of active contracts</p>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr">
              <ContractDistributionByAgencyCharts
                chartId="contract_distribution_charts"
                series={chartData}
                distributionByAgency={distributionByAgency}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ContractsDistribution;
