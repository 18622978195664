import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import SEMGMentionForm from "./SEMGMentionForm";
import { updatePersonSEMG } from "common/services/persons.service";
import { toast } from "react-toastify";

const UpdateSEMGMention: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  data: any;
  personId: string;
}> = ({ toggle, isModalOpen, personId, data, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      semgMentions:
        data.length > 0
          ? data.map((item: any) => ({
              id: item.id,
              reportNumber: item.reportNumber,
              SEMGPage: item.SEMGPage,
              SEMGSection: item.SEMGSection,
              SEMGParagraph: item.SEMGParagraph,
              SEMGCommentary: item.SEMGCommentary,
              SEMGRegion: item.SEMGRegion,
            }))
          : [
              {
                reportNumber: "",
                SEMGPage: "",
                SEMGSection: "",
                SEMGParagraph: "",
                SEMGCommentary: "",
                SEMGRegion: "",
              },
            ],
    },
    validationSchema: Yup.object({
      semgMentions: Yup.array().of(
        Yup.object({
          reportNumber: Yup.string().required("Report number is required"),
          SEMGPage: Yup.string(),
          SEMGSection: Yup.string(),
          SEMGParagraph: Yup.string(),
          SEMGCommentary: Yup.string(),
          SEMGRegion: Yup.string(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: personId,
        data: values.semgMentions?.map((item: any) => ({
          ...item,
        })),
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updatePersonSEMG(data);
          validation.resetForm();
          toggle();
          toast.success("SEMG Added Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while adding SEMG Mention", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update SEMG Mentions{" "}
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.semgMentions?.length || 0}
        </small>
      </ModalHeader>

      <SEMGMentionForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateSEMGMention;
