import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getPVRs as getPVRsApi,
  addPVR as addPVRApi,
  updatePVR as updatePVRApi,
  deletePVR as deletePVRApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getPVRs = createAsyncThunk(
  "pvr/getPVRs",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getPVRsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addPVR = createAsyncThunk("pvr/addPVR", async (pvr: any) => {
  try {
    const response = addPVRApi(pvr);
    return response;
  } catch (error) {
    return error;
  }
});

export const updatePVR = createAsyncThunk("pvr/updatePVR", async (pvr: any) => {
  try {
    const response = updatePVRApi(pvr);
    const data = response;
    return data;
  } catch (error) {
    return error;
  }
});

export const deletePVR = createAsyncThunk("pvr/deletePVR", async (pvr: any) => {
  try {
    const response = deletePVRApi(pvr);
    return response;
  } catch (error) {
    return error;
  }
});
