import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BoardOfDirectorsForm from "./BoardOfDirectorsForm";
import { updateContractorBoardOfDirectors } from "common/services/contractors.service";
import { toast } from "react-toastify";

const UpdateBoardOfDirectors: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  data: any;
  contractorId: string;
}> = ({ toggle, isModalOpen, contractorId, data, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      boardOfDirectors: (data.length &&
        data.map((item: any) => ({
          id: item.id,
          person: {
            value: item.person?.id,
            label: `${item?.person?.firstName || ""} ${
              item?.person?.middleName || ""
            } ${item?.person?.surname || ""}`,
          },
          role: item.role || "",
          fromDate: item.fromDate || "",
          toDate: item.toDate || "",
        }))) || [
        {
          person: {
            value: "",
            label: "",
          },
          role: "",
          fromDate: "",
          toDate: "",
        },
      ],
    },
    validationSchema: Yup.object({
      boardOfDirectors: Yup.array().of(
        Yup.object({
          person: Yup.object().shape({
            value: Yup.string().required("Required"),
            label: Yup.string(),
          }),
          role: Yup.string().nullable(),
          fromDate: Yup.string().nullable(),
          toDate: Yup.string().nullable(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: contractorId,
        data: values.boardOfDirectors?.map((item: any) => ({
          ...item,
          person: item.person?.value,
          fromDate: item.fromDate ? item.fromDate : null,
          toDate: item.toDate ? item.toDate : null,
        })),
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractorBoardOfDirectors(data);
          validation.resetForm();
          toggle();
          toast.success("Board of Directors Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating board of directors", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Board of Directors{" "}
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.boardOfDirectors?.length || 0}
        </small>
      </ModalHeader>

      <BoardOfDirectorsForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
      />
    </Modal>
  );
};

export default UpdateBoardOfDirectors;
