import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "./thunk";
import { toast } from "react-toastify";

export const initialState = {
  countries: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const CountriesSlice = createSlice({
  name: "CountriesSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = action.payload?.data.map((item: any) => {
          return {
            ...item.attributes,
            // vehicleMake: {
            //   code: item.attributes.vehicleMake?.data?.attributes?.code,
            //   name: item.attributes.vehicleMake?.data?.attributes?.name,
            //   id: item.attributes.vehicleMake?.data?.id,
            // },
            id: item.id,
          };
        });
        state.countries.data = data || state.countries.data;
      }
      state.countries.meta = action.payload?.meta || state.countries.meta;
      state.loading = false;
    });
    builder.addCase(getCountries.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
  },
});

export const { setLoading } = CountriesSlice.actions;

export default CountriesSlice.reducer;
