import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import PVRForm from "./PVRForm";
import { updatePvr } from "common/services/pvr.service";

const UpdatePVR: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchUpdatedData: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchUpdatedData, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: selectedRecord?.title,
      agency: {
        value: selectedRecord?.agency?.id,
        label: selectedRecord?.agency?.name,
      },
      contractor: {
        value: selectedRecord?.contractor?.id,
        label: selectedRecord?.contractor?.contractorName,
      },
      verificationDate: selectedRecord?.verificationDate,
      conductedBy: {
        value: selectedRecord?.conductedBy?.id,
        label:
          selectedRecord?.conductedBy?.firstName +
          " " +
          selectedRecord?.conductedBy?.lastName,
      },
      summary: selectedRecord?.summary,
      selectedFiles:
        (selectedRecord?.pvrFile &&
          selectedRecord?.pvrFile?.map((file: any) => {
            return {
              id: file.id,
              name: file.name,
              formattedSize: file.size + " KB",
            };
          })) ||
        [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title"),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      vevrificationDate: Yup.string(),

      conductedBy: Yup.object().shape({
        value: Yup.number().required("Please select conducted by"),
      }),
      summary: Yup.string(),
      selectedFiles: Yup.array()
        .min(1, "Atleast one file is required")
        .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        title: values.title,
        agency: values.agency.value,
        contractor: values.contractor.value,
        verificationDate: values.verificationDate,
        conductedBy: values.conductedBy.value,
        summary: values.summary,
        reportFile: validation.values.selectedFiles.filter((file: any) => {
          if (file?.id) {
            return file.id;
          }
          return false;
        }),
      };

      const formData = new FormData();
      values.selectedFiles.forEach((file: any) => {
        if (!file?.id) {
          formData.append("files.pvrFile", file, file.name);
        }
      });
      formData.append("data", JSON.stringify(data));

      setLoading(true);
      try {
        setLoading(true);
        await updatePvr({
          id: selectedRecord.id,
          formData,
        });
        fetchUpdatedData();
        validation.resetForm();
        toggle();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Partner Verification Report
      </ModalHeader>

      <PVRForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdatePVR;
