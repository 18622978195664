import React, { useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { getContract as onGetContract } from "slices/thunks";
import { useParams } from "react-router-dom";
import { isContractActive } from "common/lib/contractOptions";
import { LEVEL_COUNTRY } from "common/constants";
import ContractContent from "./ContractContent";

const ViewContract = () => {
  const dispatch: any = useDispatch();

  const { id: contractId } = useParams();

  useEffect(() => {
    dispatch(
      onGetContract({
        level: LEVEL_COUNTRY,
        id: contractId,
      })
    );
  }, [contractId, dispatch]);

  const selectLayoutState = (state: any) => state.Contracts;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    contract: state.contract,
  }));

  const { contract } = useSelector(selectProperties);

  document.title = "Contract Details | CIMS";

  const status = useMemo(() => {
    return isContractActive(contract?.startDate, contract?.endDate);
  }, [contract.startDate, contract.endDate]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Contract Details"
          pageTitles={[
            {
              title: "Dashboard",
              link: "/",
            },
            { title: "Contracts", link: "/contracts" },
          ]}
          back
        />

        <ContractContent contract={contract} status={status} />
      </Container>
    </div>
  );
};

export default ViewContract;
