import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Row, Table } from "reactstrap";
import { Link } from "react-router-dom";

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";
import Skeleton from "react-loading-skeleton";
import { CARD_MIN_HEIGHT } from "common/constants";

// Column Filter
const Filter = ({
  column,
}: {
  column: Column<any, unknown>;
  table: ReactTable<any>;
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input
      {...props}
      value={value}
      id="search-bar-0"
      className="form-control search"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isProductsFilter?: any;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isLeadsFilter?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  handleTaskClick?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  handleLeadClick?: any;
  handleCompanyClick?: any;
  handleContactClick?: any;
  handleTicketClick?: any;
  pagination: any;
  FilterSection: any;
  onPageChange: Function;
  loading?: boolean;
  totalRows?: number;
  parentSearchValue?: string;
  parentFilterValue?: any;
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  pagination,
  FilterSection,
  onPageChange,
  loading,
  totalRows,
  parentSearchValue,
  parentFilterValue,
}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: customPageSize,
        pageIndex: 0,
      },
      sorting: [
        {
          id: "createdAt",
          desc: true,
        },
      ],
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    manualPagination: true,
    pageCount: pagination?.pageCount || 0,
    autoResetPageIndex: false,
    autoResetAll: false,
    enableSorting: true,
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
    setSorting,
  } = table;

  // Access pageIndex and pageSize
  const { pageIndex, pageSize } = getState().pagination;

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);

  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setSearchValue(() => parentSearchValue || "");
  }, [parentSearchValue]);

  const [filterValue, setFilterValue] = useState({});
  useEffect(() => {
    setFilterValue(() => {
      return parentFilterValue || {};
    });
  }, [parentFilterValue]);

  const [sorted, setSorted] = useState<any>([]);

  useEffect(() => {
    onPageChange({ page: pageIndex + 1, sorted, searchValue, filterValue });
  }, [pageIndex, pageSize, onPageChange, sorted, searchValue, filterValue]);

  const onSort = (columnId: string, sortIndex: any) => {
    // Update the sorted state and trigger data fetching
    if (columnId === "action") {
      return;
    }
    let desc = false;
    if (sortIndex === -1) {
      desc = true;
    } else if (sortIndex === 0) {
      desc = false;
    }
    setSorted([{ id: columnId, desc }]);
    setSorting([{ id: columnId, desc }]);
  };

  // Render Custom page numbers that scale
  // Determine pages to be displayed in pagination
  const currentPage = pageIndex + 1;
  const totalPages = pagination.pageCount;

  const startPage = Math.max(1, Math.min(currentPage - 2, totalPages - 4));
  const endPage = Math.min(totalPages, startPage + 4);

  const renderPageNumbers = useMemo(() => {
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <React.Fragment key={i}>
          <li className="page-item">
            <Link
              to="#"
              className={currentPage === i ? "page-link active" : "page-link"}
              onClick={() => setPageIndex(i - 1 || 0)}
            >
              {i}
            </Link>
          </li>
        </React.Fragment>
      );
    }
    return pageNumbers;
  }, [startPage, endPage, setPageIndex, currentPage]);

  // Assuming sortBy is part of your table state or props
  // const sortableColumns = useMemo(() => {
  //   return columns.filter((column: any) => column.enableSorting !== false);
  // }, [columns]);

  // console.log("Sortable Columns", sortableColumns);

  return (
    <Fragment>
      <>
        <FilterSection
          searchValue={searchValue}
          setSearchValue={(text: string) => {
            // if (pageIndex != 0) {
            //   gotoPage(0);
            // }
            setSearchValue(text);
          }}
        />
      </>

      <div className={divClass} style={{ minHeight: CARD_MIN_HEIGHT }}>
        <Table hover className={tableClass}>
          <thead className={theadClass}>
            {getHeaderGroups().map((headerGroup: any) => (
              <tr className={trClass} key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  // if (header.id === "name") {
                  //   console.log("Header", header);
                  //   console.log("Is Sorted", header.column.getSortIndex());
                  // }
                  return (
                    <th
                      key={header.id}
                      className={thClass}
                      onClick={() => {
                        if (!header.column.getCanSort()) return;
                        onSort(header.id, header.column.getSortIndex());
                        header.column.toggleSorting();
                      }}
                      // {...{
                      //   onClick: header.column.getToggleSortingHandler(),
                      // }}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " ",
                            desc: " ",
                          }[header.column.getIsSorted() as string] ?? null}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody style={{ minHeight: "300px" }}>
              {/* <tr>
                <td colSpan={(columns && columns.length) || 12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "300px",
                      width: "100%",
                    }}
                  >
                    <Loader />
                  </div>
                </td>
              </tr> */}
              {Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index}>
                  {columns.map((column: any, colIndex: any) => (
                    <td key={colIndex}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>

      <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing
            <span className="fw-semibold ms-1">
              {(data && data.length) || 0}
            </span>{" "}
            of <span className="fw-semibold">{totalRows}</span> Results
          </div>
        </div>
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
            <li
              className={
                !getCanPreviousPage() ? "page-item disabled" : "page-item"
              }
            >
              <Link to="#" className="page-link" onClick={previousPage}>
                Previous
              </Link>
            </li>
            {/* {getPageOptions().map((item: any, key: number) => (
              <React.Fragment key={key}>
                <li className="page-item">
                  <Link
                    to="#"
                    className={
                      getState().pagination.pageIndex === item
                        ? "page-link active"
                        : "page-link"
                    }
                    onClick={() => setPageIndex(item)}
                  >
                    {item + 1}
                  </Link>
                </li>
              </React.Fragment>
            ))} */}

            {renderPageNumbers}
            <li
              className={!getCanNextPage() ? "page-item disabled" : "page-item"}
            >
              <Link to="#" className="page-link" onClick={nextPage}>
                Next
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    </Fragment>
  );
};

export default TableContainer;
