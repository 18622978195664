import FileIcon from "Components/Common/FileIcon";
import React from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Alert, Card, Col, FormGroup, Label, Row } from "reactstrap";

const SelectPVRFile = ({ validation }: { validation: any }) => {
  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    validation.setFieldValue("selectedFiles", [
      ...validation.values.selectedFiles,
      ...files,
    ]);
  }

  const handleRemoveFile = (fileIndex: number) => {
    const selectedFiles = [...validation.values.selectedFiles];
    selectedFiles.splice(fileIndex, 1);
    validation.setFieldValue("selectedFiles", selectedFiles);
  };

  return (
    <Col md={12} className="">
      <FormGroup className="mb-3">
        <Label htmlFor="choices-publish-status-input" className="form-label">
          Report File
        </Label>
        <Dropzone
          multiple
          accept={{
            "text/csv": [".csv"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
            "application/msword": [".doc"],
            "application/pdf": [".pdf"],
          }}
          onDrop={(acceptedFiles) => {
            handleAcceptedFiles(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone dz-clickable">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3 mt-5">
                  <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                </div>
                <h5>
                  Drop files here or click to upload risk assessment File.
                </h5>
              </div>
            </div>
          )}
        </Dropzone>

        {validation.errors.selectedFiles ? (
          <Alert color="danger" className="mb-xl-0 mt-2">
            {validation.errors.selectedFiles}
          </Alert>
        ) : null}
        <div className="list-unstyled mb-0" id="file-previews">
          {validation.values.selectedFiles.map((f: any, i: any) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light  rounded fs-24">
                          <FileIcon fileName={f?.name} />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {f?.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f?.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="col-auto">
                      <button
                        type="button"
                        className="btn btn-sm  bg-danger-subtle"
                        onClick={() => handleRemoveFile(i)}
                      >
                        <i className="ri-delete-bin-fill text-danger"></i>
                      </button>
                    </Col>
                  </Row>
                </div>
              </Card>
            );
          })}
        </div>
      </FormGroup>
    </Col>
  );
};

export default SelectPVRFile;
