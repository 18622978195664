import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CapacityAssessmentForm from "./CapacityAssessmentForm";
import { updateCapacityAssessment } from "common/services/capacity-assessments.service";

const UpdateCapacityAssessment: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: any;
  fetchUpdatedData: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchUpdatedData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      reportTitle: selectedRecord?.reportTitle,
      agency: {
        value: selectedRecord?.agency?.id,
        label: selectedRecord?.agency?.name,
      },
      contractor: {
        value: selectedRecord?.contractor?.id,
        label: selectedRecord?.contractor?.contractorName,
      },
      capacityAssessmentType: {
        value: selectedRecord?.typeOfAssessment?.id,
        label: selectedRecord?.typeOfAssessment?.name,
      },
      capacityAssessmentRating: {
        value: selectedRecord?.overallRating?.id,
        label: selectedRecord?.overallRating?.name,
      },
      reportDate: selectedRecord?.reportDate,
      // sensitivity: {
      //   value: selectedRecord?.sensitivity || "",
      //   label: selectedRecord?.sensitivity || "",
      // },

      conductedBy: {
        value: selectedRecord?.conductedBy?.id,
        label: selectedRecord?.conductedBy?.contractorName,
      },
      summaryOfFindings: selectedRecord?.summaryOfFindings,
      selectedFiles:
        (selectedRecord?.reportFile &&
          selectedRecord?.reportFile?.map((file: any) => {
            return {
              id: file.id,
              name: file.name,
              formattedSize: file.size + " KB",
            };
          })) ||
        [],
    },
    validationSchema: Yup.object({
      reportTitle: Yup.string().required("Please enter report title"),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select agency"),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      capacityAssessmentType: Yup.object().shape({
        value: Yup.number().required("Please select capacity assessment type"),
      }),
      capacityAssessmentRating: Yup.object().shape({
        value: Yup.number().required(
          "Please select capacity assessment rating"
        ),
      }),
      reportDate: Yup.string(),
      conductedBy: Yup.object().shape({
        value: Yup.number().required("Please select conducted by"),
      }),
      summaryOfFindings: Yup.string(),
      // sensitivity: Yup.object().shape({
      //   value: Yup.string().nullable(),
      // }),
      selectedFiles: Yup.array()
        .min(1, "Atleast one file is required")
        .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        reportTitle: values.reportTitle,
        agency: values.agency.value,
        contractor: values.contractor.value,
        typeOfAssessment: values.capacityAssessmentType.value,
        overallRating: values.capacityAssessmentRating.value,
        reportDate: values.reportDate,
        conductedBy: values.conductedBy.value,
        summaryOfFindings: values.summaryOfFindings,
        // sensitivity: values.sensitivity.value,
        reportFile: validation.values.selectedFiles.filter((file: any) => {
          if (file?.id) {
            return file.id;
          }
          return false;
        }),
      };

      const formData = new FormData();

      values.selectedFiles.forEach((file: any) => {
        if (!file?.id) {
          formData.append("files.reportFile", file, file.name);
        }
      });
      formData.append("data", JSON.stringify(data));

      setLoading(true);
      try {
        setLoading(true);
        await updateCapacityAssessment({
          id: selectedRecord.id,
          formData,
        });
        fetchUpdatedData();
        validation.resetForm();
        toggle();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Capacity Assessment
      </ModalHeader>

      <CapacityAssessmentForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateCapacityAssessment;
