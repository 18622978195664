import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getPersons as getPersonsApi,
  getPerson as getPersonApi,
  addPerson as addPersonApi,
  updatePerson as updatePersonApi,
  deletePerson as deletePersonApi,
} from "common/services/persons.service";
import { setLoading } from "./reducer";
import { LEVEL_COUNTRY } from "common/constants";

export const getPersons = createAsyncThunk(
  "person/getPersons",
  async (payload: any, { dispatch }) => {
    try {
      let response = null;

      if (payload?.level === LEVEL_COUNTRY) {
        response = getPersonsApi(payload?.params);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPerson = createAsyncThunk(
  "person/getPerson",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const params = {
        populate: [
          "alias",
          "contactDetails",
          "flaggedBy",
          "createdby",
          "createdby.agency",
          "updatedby",
          "currentNationality",
          "nationalityAtBirth",
          "contractorAffiliations",
          "contractorAffiliations.contractor",
          "contractorAffiliations.contractor.organizationType",
          "personAffiliations",
          "personAffiliations.affiliatedTo",
          "semgMentions",
          "boardOfDirectors",
          "boardOfDirectors.contractor",
        ],
      };

      const response = getPersonApi(payload?.id, params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addPerson = createAsyncThunk(
  "person/addPerson",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = addPersonApi(payload?.params);
      }

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updatePerson = createAsyncThunk(
  "person/updatePerson",
  async (payload: any) => {
    try {
      let response = null;
      if (payload?.level === LEVEL_COUNTRY) {
        response = updatePersonApi(payload?.params);
      }

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deletePerson = createAsyncThunk(
  "person/deletePerson",
  async (payload: any) => {
    try {
      const response = deletePersonApi(payload?.id);
      return response;
    } catch (error) {
      return error;
    }
  }
);
