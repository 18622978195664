import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import config from "../config";
import { POST_FORGET_PASSWORD, POST_JWT_LOGIN } from "./url_helper";
import qs from "qs";
const { api } = config;

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const authUser: any = sessionStorage.getItem("authUser");
const token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

axios.interceptors.request.use(
  (config) => {
    if (
      config.url === POST_JWT_LOGIN ||
      config.url === POST_FORGET_PASSWORD ||
      config.url === "/auth/local/register"
    ) {
      config.headers.Authorization = null;
      return config;
    }

    let token = "";
    try {
      token = JSON.parse(sessionStorage.getItem("authUser") || "")
        ? JSON.parse(sessionStorage.getItem("authUser") || "").token
        : "";
    } catch (e) {
      // console.log("error", e);
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response?.data ? response?.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    let message;

    switch (error?.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        if (error.response.data && error?.response?.data?.tokenExpired) {
          message = "Session Expired. Please login again";
          sessionStorage.removeItem("authUser");
          window.location.href = "/login";
        }
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 403:
        message = "You do not have permissions to access this resource";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(error);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from the given URL
   */
  get = (url: string, params?: any): Promise<AxiosResponse> => {
    let response: Promise<AxiosResponse>;

    if (params) {
      //   Object.keys(params).map((key) => {
      //     paramKeys.push(key + "=" + params[key]);
      //     return paramKeys;
      //   });

      //   const queryString =
      //     paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      const queryString = qs.stringify(params);

      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  /**
   * Posts the given data to the URL
   */
  create = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.post(url, data);
  };

  createAndUpload = (url: string, data: any): Promise<AxiosResponse> => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, data, config);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.put(url, data);
  };

  updateAndUpload = (url: string, data: any): Promise<AxiosResponse> => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, data);
  };

  put = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
