import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import UpdateContractorRegistration from "./UpdateContractorRegistration";
import { useDispatch } from "react-redux";
import { getContractor as onGetContractor } from "slices/thunks";

const ContractorRegistration = ({ contractor }: { contractor: any }) => {
  const dispatch: any = useDispatch();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center border-bottom-dashed">
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">
            Contractor Registration
          </h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Link
            to="#"
            onClick={(e) => {
              e.stopPropagation(); // Prevent collapse toggle on edit click
              showUpdateModal();
            }}
            className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
          >
            <i className="ri-pencil-line"></i> Edit
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        <div className="table-responsive table-card">
          <Table className="align-middle table-nowrap mb-4 table-striped">
            <thead className="">
              <tr>
                <th scope="col">Registration Name</th>
                <th scope="col">Registration Type</th>
                <th scope="col">Number</th>
                <th scope="col">Date Registered</th>
                <th scope="col">Length of Validity</th>
                <th scope="col" style={{ width: "150px" }}>
                  Country
                </th>
              </tr>
            </thead>
            <tbody>
              {contractor?.contractorRegistration.map(
                (el: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{el.registrationName}</td>
                      <td>{el.registrationType}</td>
                      <td>{el.registrationNumber}</td>
                      <td>
                        {moment(new Date(el?.registrationDate)).format(
                          "DD MMM, Y"
                        )}
                      </td>
                      <td>{el?.lengthOfValidity}</td>
                      <td>{el?.countryOfRegistration}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>

      <UpdateContractorRegistration
        toggle={() => setIsUpdateModalOpen((state) => !state)}
        isModalOpen={isUpdateModalOpen}
        fetchData={() => {
          dispatch(
            onGetContractor({
              id: contractor?.id,
            })
          );
        }}
        contractorId={contractor?.id}
        data={contractor?.contractorRegistration || []}
      />
    </Card>
  );
};

export default ContractorRegistration;
