import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllResults from "./SearchTabs/AllResults";
import Contractors from "./SearchTabs/Contractors";
import Contracts from "./SearchTabs/Contracts";
import Assessments from "./SearchTabs/Assessments";
import {
  ICON_ASSESSMENTS,
  ICON_CONTRACTORS,
  ICON_CONTRACTS,
  ICON_SANCTIONS,
} from "common/constants";
import SanctionsList from "./SearchTabs/SantionsList";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { setSearchValue } from "slices/search/reducer";

const SearchResults = () => {
  SwiperCore.use([Autoplay]);

  const dispatch: any = useDispatch();

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  //OffCanvas
  const [isOpen, setIsOpen] = useState(false);

  const toggleOffCanvas = () => {
    setIsOpen(!isOpen);
  };

  const selectProperties = createSelector(
    (state: any) => state.Search,
    (state) => ({
      globalSearchValue: state.globalSearchValue,
    })
  );
  const { globalSearchValue: searchValue } = useSelector(selectProperties);

  document.title = "Search Results | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <BreadCrumb title="Search Results" pageTitle="Pages" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <Row className="justify-content-center mb-4">
                    <Col lg={6}>
                      <Row className="g-2">
                        <Col>
                          <div className="position-relative mb-3">
                            <Input
                              type="text"
                              className="form-control form-control-lg bg-light border-light"
                              placeholder="Search here.."
                              value={searchValue}
                              onChange={(e) =>
                                dispatch(setSearchValue(e.target.value))
                              }
                            />
                            {/* <Link
                              to="#"
                              className="btn btn-link link-success btn-lg position-absolute end-0 top-0 material-shadow-none"
                              onClick={toggleOffCanvas}
                            >
                              <i className="ri-mic-fill"></i>
                            </Link> */}
                          </div>
                        </Col>
                        <div className="col-auto">
                          <button
                            type="submit"
                            onClick={() => {}}
                            className="btn btn-primary btn-lg waves-effect waves-light"
                          >
                            <i className="mdi mdi-magnify me-1"></i> Search
                          </button>
                        </div>
                      </Row>
                    </Col>
                    {searchValue && (
                      <Col lg={12}>
                        <h5 className="fs-16 fw-semibold text-center mb-0">
                          Showing results for "
                          <span className="text-primary fw-medium fst-italic">
                            {searchValue}
                          </span>{" "}
                          "
                        </h5>
                      </Col>
                    )}
                  </Row>

                  <Offcanvas
                    isOpen={isOpen}
                    direction="top"
                    toggle={toggleOffCanvas}
                    tabIndex={-1}
                  >
                    <OffcanvasBody>
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="btn-close text-reset float-end"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                      <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                        <div className="search-voice">
                          <i className="ri-mic-fill align-middle"></i>
                          <span className="voice-wave"></span>
                          <span className="voice-wave"></span>
                          <span className="voice-wave"></span>
                        </div>
                        <h4>Talk to me, what can I do for you?</h4>
                      </div>
                    </OffcanvasBody>
                  </Offcanvas>
                </CardHeader>
                <div>
                  <Nav className="nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-search-2-line text-muted align-bottom me-1"></i>{" "}
                        All Results
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i
                          className={`${ICON_CONTRACTORS} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Contractors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        <i
                          className={`${ICON_CONTRACTS} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Contracts
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        <i
                          className={`${ICON_ASSESSMENTS} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Assessments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          toggleTab("5");
                        }}
                      >
                        <i
                          className={`${ICON_SANCTIONS} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Sanctions
                      </NavLink>
                    </NavItem>
                    <li className="nav-item ms-auto">
                      {/* // Bulk search button */}
                    </li>
                  </Nav>
                </div>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <AllResults searchValue={searchValue} />
                    </TabPane>
                    <TabPane tabId="2">
                      <Contractors searchValue={searchValue} />
                    </TabPane>
                    <TabPane tabId="3">
                      <Contracts searchValue={searchValue} />
                    </TabPane>
                    <TabPane tabId="4">
                      <Assessments searchValue={searchValue} />
                    </TabPane>
                    <TabPane tabId="5">
                      <SanctionsList searchValue={searchValue} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchResults;
