import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
  ModalBody,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

const ContactDetailsForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <SimpleBar
          ref={simpleBarRef}
          style={{ maxHeight: "80vh" }}
          className=" px-4 py-4"
          autoHide={false}
        >
          <Row className="mb-4">
            <Col md={12} className="mb-3">
              <h6 className="mb-0 fw-semibold text-uppercase">
                Business Contacts
              </h6>
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                Business Phone
              </Label>
              <Input
                name="businessPhone"
                id="businessPhone"
                className="form-control"
                placeholder="Enter business phone number"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.businessPhone || ""}
                invalid={
                  validation.touched.businessPhone &&
                  validation.errors.businessPhone
                    ? true
                    : false
                }
              />
              {validation.touched.businessPhone &&
              validation.errors.businessPhone ? (
                <FormFeedback type="invalid">
                  {validation.errors.businessPhone}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                Business Email
              </Label>
              <Input
                name="businessEmail"
                id="businessEmail"
                className="form-control"
                placeholder="Enter business email address"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.businessEmail || ""}
                invalid={
                  validation.touched.businessEmail &&
                  validation.errors.businessEmail
                    ? true
                    : false
                }
              />
              {validation.touched.businessEmail &&
              validation.errors.businessEmail ? (
                <FormFeedback type="invalid">
                  {validation.errors.businessEmail}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                Business Website
              </Label>
              <Input
                name="businessWebsite"
                id="businessWebsite"
                className="form-control"
                placeholder="Enter business website"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.businessWebsite || ""}
                invalid={
                  validation.touched.businessWebsite &&
                  validation.errors.businessWebsite
                    ? true
                    : false
                }
              />
              {validation.touched.businessWebsite &&
              validation.errors.businessWebsite ? (
                <FormFeedback type="invalid">
                  {validation.errors.businessWebsite}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={6} className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                Business Postal Address
              </Label>
              <Input
                name="businessPostalAddress"
                id="businessPostalAddress"
                className="form-control"
                placeholder="Enter business phone number"
                type="textarea"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.businessPostalAddress || ""}
                invalid={
                  validation.touched.businessPostalAddress &&
                  validation.errors.businessPostalAddress
                    ? true
                    : false
                }
              />
              {validation.touched.businessPostalAddress &&
              validation.errors.businessPostalAddress ? (
                <FormFeedback type="invalid">
                  {validation.errors.businessPostalAddress}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={6} className="mb-3">
              <Label htmlFor="id-field" className="form-label">
                Business Physical Address
              </Label>
              <Input
                name="businessPhysicalAddress"
                id="businessPhysicalAddress"
                className="form-control"
                placeholder="Enter business phone number"
                type="textarea"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.businessPhysicalAddress || ""}
                invalid={
                  validation.touched.businessPhysicalAddress &&
                  validation.errors.businessPhysicalAddress
                    ? true
                    : false
                }
              />
              {validation.touched.businessPhysicalAddress &&
              validation.errors.businessPhysicalAddress ? (
                <FormFeedback type="invalid">
                  {validation.errors.businessPhysicalAddress}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3 ">
              <h6 className="mb-0 fw-semibold text-uppercase">
                Contact Persons
              </h6>
            </Col>
            <Col md={12} className="mb-3">
              {validation.values.contactPersons.map(
                (contactPerson: any, index: number) => {
                  return (
                    <Card className="bg-light">
                      <CardBody>
                        <Row key={index}>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`name-${index}`}
                              className="form-label"
                            >
                              Full Name
                            </Label>
                            <Input
                              name={`contactPersons[${index}].name`}
                              id={`name-${index}`}
                              className="form-control"
                              placeholder="Enter full name"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={contactPerson.name || ""}
                              invalid={
                                validation.touched.contactPersons?.[index]
                                  ?.name &&
                                validation.errors.contactPersons?.[index]?.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contactPersons?.[index]?.name &&
                              validation.errors.contactPersons?.[index]
                                ?.name && (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors.contactPersons?.[index]
                                      ?.name
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`email-${index}`}
                              className="form-label"
                            >
                              Email Address
                            </Label>
                            <Input
                              name={`contactPersons[${index}].emailAddress`}
                              id={`email-${index}`}
                              className="form-control"
                              placeholder="Enter email address"
                              type="email"
                              validate={{
                                required: { value: true },
                                email: true,
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={contactPerson.emailAddress || ""}
                              invalid={
                                validation.touched.contactPersons?.[index]
                                  ?.emailAddress &&
                                validation.errors.contactPersons?.[index]
                                  ?.emailAddress
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contactPersons?.[index]
                              ?.emailAddress &&
                              validation.errors.contactPersons?.[index]
                                ?.emailAddress && (
                                <FormFeedback>
                                  {
                                    validation.errors.contactPersons[index]
                                      .emailAddress
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`mobileNumber-${index}`}
                              className="form-label"
                            >
                              Mobile Number
                            </Label>
                            <Input
                              name={`contactPersons[${index}].mobileNumber`}
                              id={`mobileNumber-${index}`}
                              className="form-control"
                              placeholder="Enter mobile number"
                              type="text"
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={contactPerson.mobileNumber || ""}
                              invalid={
                                validation.touched.contactPersons?.[index]
                                  ?.mobileNumber &&
                                validation.errors.contactPersons?.[index]
                                  ?.mobileNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contactPersons?.[index]
                              ?.mobileNumber &&
                              validation.errors.contactPersons?.[index]
                                ?.mobileNumber && (
                                <FormFeedback>
                                  {
                                    validation.errors.contactPersons[index]
                                      .mobileNumber
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`telephone-${index}`}
                              className="form-label"
                            >
                              Telephone
                            </Label>
                            <Input
                              name={`contactPersons[${index}].telephone`}
                              id={`telephone-${index}`}
                              className="form-control"
                              placeholder="Enter telephone number"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={contactPerson.telephone || ""}
                            />
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`websiteOrLink-${index}`}
                              className="form-label"
                            >
                              Website or Link
                            </Label>
                            <Input
                              name={`contactPersons[${index}].websiteOrLink`}
                              id={`websiteOrLink-${index}`}
                              className="form-control"
                              placeholder="Enter website or link"
                              type="url"
                              validate={{ url: true }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={contactPerson.websiteOrLink || ""}
                              invalid={
                                validation.touched.contactPersons?.[index]
                                  ?.websiteOrLink &&
                                validation.errors.contactPersons?.[index]
                                  ?.websiteOrLink
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contactPersons?.[index]
                              ?.websiteOrLink &&
                              validation.errors.contactPersons?.[index]
                                ?.websiteOrLink && (
                                <FormFeedback>
                                  {
                                    validation.errors.contactPersons[index]
                                      .websiteOrLink
                                  }
                                </FormFeedback>
                              )}
                          </Col>
                          <Col md={4} className="mb-3">
                            <Label
                              htmlFor={`physicalAddress-${index}`}
                              className="form-label"
                            >
                              Physical Address
                            </Label>
                            <Input
                              name={`contactPersons[${index}].physicalAddress`}
                              id={`physicalAddress-${index}`}
                              className="form-control"
                              placeholder="Enter physical address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={contactPerson.physicalAddress || ""}
                            />
                          </Col>
                          <div className="mb-3 d-flex justify-content-end">
                            <Button
                              className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                              onClick={() => {
                                validation.setFieldValue(
                                  "contactPersons",
                                  validation.values.contactPersons.filter(
                                    (_: any, contactPersonIndex: number) =>
                                      contactPersonIndex !== index
                                  )
                                );
                              }}
                            >
                              {/* <i className="ri-delete-bin-fill me-2"></i> */}
                              Remove
                            </Button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  );
                }
              )}

              <button
                className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
                disabled={validation.values.contactPersons.length === 3}
                onClick={() => {
                  if (validation.values.contactPersons.length < 3) {
                    const newContactPerson = {
                      name: "",
                      emailAddress: "",
                      mobileNumber: "",
                      telephone: "",
                      websiteOrLink: "",
                      physicalAddress: "",
                    };
                    validation.setFieldValue("contactPersons", [
                      ...validation.values.contactPersons,
                      newContactPerson,
                    ]);
                  }
                }}
              >
                Add
              </button>
            </Col>
          </Row>
        </SimpleBar>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ContactDetailsForm;
