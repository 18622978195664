import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { uploadUNGMList as onUploadUNGMList } from "../../../slices/thunks";
import UNGMListForm from "./UNGMListForm";
import { toast } from "react-toastify";

const ImportUNGMList: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchUNGMList: () => void;
}> = ({ toggle, isModalOpen, fetchUNGMList }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values: any) => {
      if (!selectedFiles.length) {
        toast.error("Please upload UNGM List.");
        return;
      }
      const formData = new FormData();
      selectedFiles.forEach((file: any) => {
        formData.append("file", file, file.name);
      });

      setLoading(true);
      dispatch(onUploadUNGMList(formData)).then((result: any) => {
        setLoading(false);
        if (result?.payload) {
          fetchUNGMList();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Import UNGM List
      </ModalHeader>

      <UNGMListForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
        setSelectedFiles={setselectedFiles}
        selectedFiles={selectedFiles}
      />
    </Modal>
  );
};

export default ImportUNGMList;
