import React, { useCallback, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import { getCountries as onGetCountries } from "../../slices/thunks";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";

const Countries = () => {
  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: any) => state.Countries,
    (state) => ({
      countries: state.countries.data,
      meta: state.countries.meta,
      error: state.error,
      loading: state.loading,
    })
  );
  const { countries, meta, loading } = useSelector(selectProperties);

  const pageSize = 10;

  // const fetchCountries = () => {
  //   onPageChange({
  //     page: 1,
  //     sorted: [{ id: "createdAt", desc: true }],
  //     searchValue: "",
  //   });
  // };
  const [searchValue, setSearchValue] = useState("");

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetCountries({
          pagination: {
            page,
            pageSize: 10,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $or: [
              {
                name: {
                  $containsi: searchValue,
                },
              },
              {
                isoCode: {
                  $containsi: searchValue,
                },
              },
            ],
          },
        })
      );
    },
    [dispatch]
  );
  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "ISO Code",
        accessorKey: "isoCode",
        enableColumnFilter: false,
      },
      {
        header: "Phone Code",
        accessorKey: "phoneCode",
        enableColumnFilter: false,
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
        id: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          const time = moment(new Date(cell?.getValue())).format("hh:mm A");
          return (
            <>
              {date} <small className="text-muted">{time}</small>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Countries" pageTitle="Maps & Regions" />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={3}>
                  <h5 className="card-title mb-0">Countries</h5>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search countries..."
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={countries || []}
                customPageSize={pageSize}
                pagination={meta?.pagination || {}}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                parentSearchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Countries;
