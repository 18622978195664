export function formatCurrencySuffix(value: number) {
  let result = {
    value: value,
    suffix: "",
  };

  if (value < 100000) {
    // No suffix
    return result;
  } else if (value >= 100000 && value < 1000000) {
    result.value = Number((value / 1000000).toFixed(2)); // Fraction of a million
    result.suffix = "M";
  } else if (value >= 1000000 && value < 1000000000) {
    result.value = Number((value / 1000000).toFixed(2)); // Million
    result.suffix = "M";
  } else if (value >= 1000000000) {
    result.value = Number((value / 1000000000).toFixed(2)); // Billion
    result.suffix = "B";
  }

  return result;
}
