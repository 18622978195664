import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updatePerson } from "common/services/persons.service";
import { toast } from "react-toastify";
import ContactDetailsForm from "./ContactDetailsForm";

const UpdateContactDetails: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;

  person: any;
}> = ({ toggle, isModalOpen, person, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const contactDetails = person?.contactDetails || [];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      contactDetails: (contactDetails.length > 0 &&
        contactDetails.map((item: any) => ({
          id: item.id,
          emailAddress: item.emailAddress,
          phone: item.phone,
          postalAddress: item.postalAddress,
          websiteOrLink: item.websiteOrLink,
          physicalAddress: item.physicalAddress,
        }))) || [
        {
          name: "",
          emailAddress: "",
          phone: "",
          postalAddress: "",
          websiteOrLink: "",
          physicalAddress: "",
        },
      ],
    },
    validationSchema: Yup.object({
      contactDetails: Yup.array().of(
        Yup.object({
          emailAddress: Yup.string(),
          phone: Yup.string(),
          postalAddress: Yup.string(),
          websiteOrLink: Yup.string(),
          physicalAddress: Yup.string(),
        })
      ),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: person.id,
        data: {
          contactDetails: values.contactDetails?.map((item: any) => ({
            ...item,
          })),
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updatePerson(data);
          validation.resetForm();
          toggle();
          toast.success("Contact Details Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating contact details", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contact Details
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.personRegistration?.length || 0}
        </small>
      </ModalHeader>

      <ContactDetailsForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateContactDetails;
