import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getRegions = (params: any) => api.get(url.GET_REGIONS, params);
export const getRegion = (id: any, params: any) =>
  api.get(url.GET_REGIONS + "/" + id, params);
export const addRegion = (data: any) => api.create(url.ADD_REGION, data);
export const updateRegion = (data: any) =>
  api.update(url.UPDATE_REGION + "/" + data.id, data);
export const deleteRegion = (data: any) =>
  api.delete(url.DELETE_REGION + "/" + data.id, data);
