import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//import images
import OverviewTab from "./OverviewTab";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { getPerson as onGetPerson } from "slices/thunks";
import { createSelector } from "reselect";

const Content = ({ personId }: { personId: string }) => {
  const dispatch: any = useDispatch();

  const [activeTab, setActiveTab] = useState<any>("1");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (personId)
      dispatch(
        onGetPerson({
          id: personId,
        })
      );
  }, [personId, dispatch]);

  const selectProperties = createSelector(
    (state: any) => state.Persons,
    (state) => ({
      person: state.person,
    })
  );
  const { person } = useSelector(selectProperties);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {/* {contractor?.semgMentions && contractor?.semgMentions?.length > 0 && (
            <Alert color="warning" className="material-shadow">
              <strong> SEMG Mention </strong> This contractor has been mentioned
              in an SEMG report. Please review the SEMG section below for more
              information.
            </Alert>
          )}
          {isDebarred && (
            <Alert color="danger" className="material-shadow">
              <strong> Suspended or Debarred! </strong> This contractor has been
              debarred by a UN Agency. Review the performance rating section
              below for more information.
            </Alert>
          )} */}
          <Card className="">
            <div className="bg-warning-subtle">
              <CardBody className="pb-0 px-4">
                <Header person={person} />
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <OverviewTab person={person} toggleTab={toggleTab} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Content;
