import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import AuthSlider from "./AuthSlider";
// actions
import { loginUser, resetLoginFlag } from "slices/thunks";

import { createSelector } from "reselect";

import { APP_NAME_SHORT } from "common/constants";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Components/Common/withRouter";
import TermsAndConditionsModal from "./TermsAndConditionsModal";

const Login = (props: any) => {
  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    user: state.Account.user,
    error: state.Login.error,
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
  }));

  const { user, error, errorMsg, loading } = useSelector(loginpageData);

  const [userLogin, setUserLogin] = useState<any>([]);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  useEffect(() => {
    if (user && user) {
      const updatedUserData = user.email;
      const updatedUserPassword = user.confirm_password;
      setUserLogin({
        email: updatedUserData,
        password: updatedUserPassword,
      });
    }
  }, [user]);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
      agreeToTerms: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email address"),
      password: Yup.string().required("Please enter your password"),
      agreeToTerms: Yup.boolean()
        .oneOf([true], "You must agree to the terms and conditions.")
        .required("Agreement is required"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  const [showTermsModal, setShowTermModal] = useState<boolean>(false);

  document.title = "SignIn | CIMS";

  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "rgba(1,78,107, 0.9)",
        }}
      >
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden card-bg-fill galaxy-border-none">
                  <Row className="g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to {APP_NAME_SHORT}
                          </p>
                        </div>

                        {typeof error === "string" && error ? (
                          <Alert color="danger"> {error} </Alert>
                        ) : null}

                        <div className="mt-4">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email
                              </Label>
                              <Input
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter email"
                                name="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  disabled={loading}
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control pe-5 password-input"
                                  placeholder="Enter password"
                                  id="password-input"
                                  name="password"
                                  value={validation.values.password || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </div>

                            <div className="form-check">
                              <Input
                                disabled={loading}
                                type="checkbox"
                                className="form-check-input"
                                id="auth-remember-check"
                                name="agreeToTerms"
                                checked={
                                  validation.values.agreeToTerms || false
                                }
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.agreeToTerms &&
                                  validation.errors.agreeToTerms
                                    ? true
                                    : false
                                }
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                By signing in you agree to the
                                {` ${APP_NAME_SHORT} `}
                                <Link
                                  to="#"
                                  onClick={() => setShowTermModal(true)}
                                  className="text-primary text-decoration-underline fst-normal fw-medium"
                                >
                                  Terms and Conditions of Use
                                </Link>
                              </Label>

                              {validation.touched.agreeToTerms &&
                              validation.errors.agreeToTerms ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.agreeToTerms}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-4">
                              <Button
                                color="primary"
                                className="w-100"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner size="sm" className="me-2">
                                    Loading...
                                  </Spinner>
                                ) : null}
                                Sign In
                              </Button>
                            </div>
                          </Form>
                          <TermsAndConditionsModal
                            toggle={() => setShowTermModal((state) => !state)}
                            isModalOpen={showTermsModal}
                          />
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Don't have an account ?{" "}
                            <Link
                              // to="/auth-signup-cover"
                              to="/signup"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Signup
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer galaxy-border-none">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0">
                    CIMS {new Date().getFullYear()} - Risk Management Unit
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
