import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { updateContract as onUpdateContract } from "slices/thunks";
import ContractForm from "./ContractForm";
import { LEVEL_AGENCY } from "common/constants";

const UpdateContract: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchContracts: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchContracts, selectedRecord }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      contractTitle: selectedRecord?.contractTitle,
      contractNo: selectedRecord?.contractNo,
      contractor: {
        value: selectedRecord?.contractor?.id,
        label: `${selectedRecord?.contractor?.contractorName} ( ${selectedRecord?.contractor?.acronym} )`,
      },
      subContractedTo: {
        value: selectedRecord?.subContractedTo?.id,
        label: `${selectedRecord?.subContractedTo?.contractorName} ( ${selectedRecord?.subContractedTo?.acronym} )`,
      },
      contractRating: {
        value: selectedRecord?.contractRating?.id,
        label: selectedRecord?.contractRating?.name,
      },
      contractType: {
        value: selectedRecord?.contractType?.id,
        label: selectedRecord?.contractType?.name,
      },
      contractValue: selectedRecord?.contractValue,
      startDate: selectedRecord?.startDate,
      endDate: selectedRecord?.endDate,
      isLTA: selectedRecord?.isLTA,
    },
    validationSchema: Yup.object({
      contractTitle: Yup.string().required("Please enter contract title"),
      contractNo: Yup.string().required("Please enter contract number"),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      subContractedTo: Yup.object().shape({
        value: Yup.number(),
      }),
      contractRating: Yup.object().shape({
        value: Yup.number().required("Please select contract rating"),
      }),
      contractType: Yup.object().shape({
        value: Yup.number().required("Please select contract type"),
      }),
      contractValue: Yup.number().required("Please enter contract value"),
      startDate: Yup.string(),
      endDate: Yup.string(),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: selectedRecord?.id,
        data: {
          contractTitle: values["contractTitle"],
          contractor: values["contractor"]["value"],
          contractNo: values["contractNo"],
          subContractedTo: values["subContractedTo"]["value"],
          contractRating: values["contractRating"]["value"],
          contractType: values["contractType"]["value"],
          contractValue: values["contractValue"],
          startDate: values["startDate"],
          endDate: values["endDate"],
          isLTA: values["isLTA"],
        },
      };
      // save new order
      setLoading(true);
      dispatch(
        onUpdateContract({
          level: LEVEL_AGENCY,
          params: data,
        })
      ).then((result: any) => {
        setLoading(false);
        if (result?.payload?.data) {
          fetchContracts();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contract
      </ModalHeader>

      <ContractForm
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateContract;
