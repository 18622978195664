import React, { useState, useMemo, useCallback } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Card,
  Col,
} from "reactstrap";

// RangeSlider
import "nouislider/distribute/nouislider.css";

import TableContainer from "../../../Components/Common/TableContainer";

import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import { getRiskAssessments } from "common/services/risk-assessments.service";
import { normalize } from "common/lib/normalize";
import FileIcon from "Components/Common/FileIcon";
import { getCapacityAssessments } from "common/services/capacity-assessments.service";
import { getPvrs } from "common/services/pvr.service";

const MyAssessments = () => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const { id: contractorId } = useParams();

  const pageSize = 10;

  const fetchData = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const [searchValue, setSearchValue] = useState("");

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      if (sorted.length === 0) {
        sorted.push({ id: "createdAt", desc: true });
      }
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      try {
        setLoading(true);
        const riskResponse: any = await getRiskAssessments({
          pagination: {
            page,
            pageSize: 50,
          },
          sort: sortObj,
          populate: [
            "contractor",
            "contractor.organizationType",
            "agency",
            "overallRiskRating",
            "conductedBy",
            "reportFile",
          ],
          filters: {
            $and: [
              {
                contractor: contractorId,
              },
            ],
            $or: [
              {
                reportTitle: {
                  $containsi: searchValue,
                },
              },
              {
                contractor: {
                  contractorName: {
                    $containsi: searchValue,
                  },
                },
              },
            ],
          },
        });
        const riskData = normalize(riskResponse.data).map((item: any) => {
          return {
            ...item,
            entityType: "risk-assessment",
          };
        });

        const capacityReponse: any = await getCapacityAssessments({
          pagination: {
            page,
            pageSize: 50,
          },
          sort: sortObj,
          populate: [
            "contractor",
            "contractor.organizationType",
            "agency",
            "overallRating",
            "typeOfAssessment",
            "conductedBy",
            "reportFile",
          ],
          filters: {
            $and: [
              {
                contractor: contractorId,
              },
            ],
            $or: [
              {
                reportTitle: {
                  $containsi: searchValue,
                },
              },
              {
                contractor: {
                  contractorName: {
                    $containsi: searchValue,
                  },
                },
              },
            ],
          },
        });
        const capacityData = normalize(capacityReponse.data).map(
          (item: any) => {
            return {
              ...item,
              entityType: "capacity-assessment",
            };
          }
        );

        const pvrResponse: any = await getPvrs({
          pagination: {
            page,
            pageSize: 50,
          },
          sort: sortObj,
          populate: [
            "contractor",
            "contractor.organizationType",
            "agency",
            "conductedBy",
            "pvrFile",
          ],
          filters: {
            $and: [
              {
                contractor: contractorId,
              },
            ],
            $or: [
              {
                title: {
                  $containsi: searchValue,
                },
              },
              {
                contractor: {
                  contractorName: {
                    $containsi: searchValue,
                  },
                },
              },
            ],
          },
        });

        const pvrData = normalize(pvrResponse.data).map((item: any) => {
          return {
            ...item,
            entityType: "pvr",
          };
        });
        // @ts-ignore
        setData([...riskData, ...capacityData, ...pvrData]);
      } catch (err: any) {
        toast.error("Error while fetching data", {
          autoClose: 1000,
        });
      } finally {
        setLoading(false);
      }
    },
    [contractorId]
  );

  const columns = useMemo(
    () => [
      {
        header: "Report Title",
        accessorKey: "contractor.contractorName",
        id: "contractor.contractorName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          let url = "#";
          if (rowData?.entityType === "risk-assessment") {
            url = `/assessments/risk-assessments/${rowData.id}`;
          } else if (rowData?.entityType === "capacity-assessment") {
            url = `/assessments/capacity-assessments/${rowData.id}`;
          } else if (rowData?.entityType === "pvr") {
            url = `/assessments/pvrs/${rowData.id}`;
          }

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-danger rounded fs-24">
                      <FileIcon
                        fileName={
                          (rowData?.reportFile &&
                            Array.isArray(rowData?.reportFile) &&
                            rowData?.reportFile[0]?.name) ||
                          (rowData?.pvrFile && rowData?.pvrFile?.[0].name)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <Link to={`${url}`} className="text-body">
                      {" "}
                      {rowData?.reportTitle || rowData?.title}
                    </Link>
                  </h5>
                  <p className="text-muted mb-0">
                    Agency :{" "}
                    <span className="fw-medium"> {rowData?.agency?.name}</span>
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      {
        header: "Assessment Type",
        accessorKey: "entityType",
        id: "entityType",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case "risk-assessment":
              return "Risk Assessment";
            case "capacity-assessment":
              return "Capacity Assessment";
            case "pvr":
              return "Partner Verification Report";
            default:
              return null;
          }
        },
      },

      // {
      //   header: "Requesting Agency",
      //   accessorKey: "agency.name",
      //   id: "agency.name",
      //   enableColumnFilter: false,
      // },

      {
        header: "Serial Number",
        accessorKey: "serialNumber",
        id: "serialNumber",
        enableColumnFilter: false,
      },

      {
        header: "Assessment Date",
        accessorKey: "reportDate",
        id: "reportDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;
          const date = moment(
            new Date(rowData?.reportDate || rowData?.createdAt)
          ).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      // {
      //   header: "Action",
      //   cell: (cell: any) => {
      //     const rowData = cell.row.original;
      //     return (
      //       <UncontrolledDropdown>
      //         <DropdownToggle
      //           href="#"
      //           className="btn btn-soft-secondary btn-sm"
      //           tag="button"
      //         >
      //           <i className="ri-more-fill" />
      //         </DropdownToggle>
      //         <DropdownMenu className="dropdown-menu-end">
      //           <DropdownItem
      //             href={`${process.env.REACT_APP_FILES_URL}${rowData?.reportFile?.url}`}
      //           >
      //             <i className="ri-download-fill align-bottom me-2 text-muted"></i>{" "}
      //             Download
      //           </DropdownItem>
      //         </DropdownMenu>
      //       </UncontrolledDropdown>
      //     );
      //   },
      // },
    ],
    []
  );

  return (
    <Row>
      <Col xl={12} lg={12}>
        <div>
          <Card>
            <div className="card-header border-0">
              <Row className="align-items-center g-3">
                <Col md={3}>
                  <h5 className="card-title mb-0">Assessments</h5>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2"></div>
                </div>
              </Row>
            </div>
            <div className="card-body">
              <TableContainer
                loading={loading}
                columns={columns}
                data={data || []}
                customPageSize={pageSize}
                pagination={meta?.pagination || {}}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || data.length}
                parentSearchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </div>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default MyAssessments;

// divClass="table-responsive mb-1"
// tableClass="mb-0 align-middle table-borderless"
// theadClass="table-light text-muted"
