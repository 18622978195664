import React from "react";

//import Scss
import "./assets/scss/themes.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

import Route from "./Routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.Fragment>
      <Route />
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
}

export default App;
