import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getRiskRatings as onGetRiskRatings } from "../../../slices/thunks";

const SelectOverallRating = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.overallRiskRating &&
        validation.errors.overallRiskRating
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.overallRiskRating &&
          validation.errors.overallRiskRating
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetRiskRatings({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.RiskRatings;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    riskRatings: state.riskRatings.data,
  }));

  const { riskRatings } = useSelector(selectProperties);

  const options = useMemo(() => {
    return riskRatings.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [riskRatings]);

  return (
    <FormGroup>
      <Label htmlFor="overallRiskRating-field" className="form-label">
        Overall Risk Rating
      </Label>
      <Select
        name="overallRiskRating"
        id="overallRiskRating"
        value={
          validation.values.overallRiskRating.value
            ? validation.values.overallRiskRating
            : null
        }
        placeholder="Select overall risk rating"
        onChange={(value: any) => {
          validation.setFieldValue("overallRiskRating", value);
          validation.setFieldError("overallRiskRating", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("overallRiskRating", true)}
        className={
          validation.touched.overallRiskRating &&
          validation.errors.overallRiskRating
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.overallRiskRating?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectOverallRating;
