import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getCapacityAssessments as getCapacityAssessmentsApi,
  addCapacityAssessment as addCapacityAssessmentApi,
  updateCapacityAssessment as updateCapacityAssessmentApi,
  deleteCapacityAssessment as deleteCapacityAssessmentApi,
  getCapacityAssessmentTypes as getCapacityAssessmentTypesApi,
  getCapacityAssessmentRatings as getCapacityAssessmentRatingsApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getCapacityAssessments = createAsyncThunk(
  "capacityAssessment/getCapacityAssessments",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getCapacityAssessmentsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addCapacityAssessment = createAsyncThunk(
  "capacityAssessment/addCapacityAssessment",
  async (capacityAssessment: any) => {
    try {
      const response = addCapacityAssessmentApi(capacityAssessment);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateCapacityAssessment = createAsyncThunk(
  "capacityAssessment/updateCapacityAssessment",
  async (capacityAssessment: any) => {
    try {
      const response = updateCapacityAssessmentApi(capacityAssessment);
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteCapacityAssessment = createAsyncThunk(
  "capacityAssessment/deleteCapacityAssessment",
  async (capacityAssessment: any) => {
    try {
      const response = deleteCapacityAssessmentApi(capacityAssessment);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCapacityAssessmentTypes = createAsyncThunk(
  "capacityAssessment/getCapacityAssessmentTypes",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getCapacityAssessmentTypesApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCapacityAssessmentRatings = createAsyncThunk(
  "capacityAssessment/getCapacityAssessmentRatings",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getCapacityAssessmentRatingsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);
