import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { changePassword } from "common/services/users.service";
import { toast } from "react-toastify";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: any;
  fetchUpdatedUsers: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchUpdatedUsers }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please enter current pssword"),
      password: Yup.string().min(6, "Password must be atleast 6 characters"),
      confirmPassword: Yup.string()
        .min(6, "Password must be atleast 6 characters")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const updatedData = {
        id: selectedRecord.id,
        currentPassword: values["currentPassword"],
        password: values["password"] || undefined,
        passwordConfirmation: values["confirmPassword"] || undefined,
      };

      setLoading(true);
      try {
        await changePassword(updatedData);
        toast.success("Password updated successfully.", { autoClose: 500 });
        fetchUpdatedUsers();
        validation.resetForm();
        toggle();
      } catch (e: any) {
        toast.error("Error while updating password.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      isOpen={isModalOpen}
      size="lg"
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Change Password
      </ModalHeader>

      <ChangePasswordForm
        validation={validation}
        isUpdate={true}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default ChangePassword;
