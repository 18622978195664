import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import { searchGlobalContent } from "common/services/dd-search.service";
import { determineEntityType } from "common/lib/entityType";

//SimpleBar
import SimpleBar from "simplebar-react";
import { useDispatch } from "react-redux";
import { setSearchValue } from "slices/search/reducer";

const SearchOption = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    const searchOptions = document.getElementById(
      "search-close-options"
    ) as HTMLElement;
    const dropdown = document.getElementById("search-dropdown") as HTMLElement;
    const searchInput = document.getElementById(
      "search-options"
    ) as HTMLInputElement;

    const handleSearchInput = () => {
      const inputLength = searchInput.value.length;
      if (inputLength > 0) {
        dropdown.classList.add("show");
        searchOptions.classList.remove("d-none");
      } else {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    };

    searchInput.addEventListener("focus", handleSearchInput);
    searchInput.addEventListener("keyup", handleSearchInput);

    searchOptions.addEventListener("click", () => {
      searchInput.value = "";
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
    });

    document.body.addEventListener("click", (e: any) => {
      if (e.target.getAttribute("id") !== "search-options") {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    });
  }, [searchTerm]);

  const onKeyDownPress = (e: any) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      setSearchTerm(e.target.value);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  // Filter content based on search term
  useEffect(() => {
    const search = async () => {
      try {
        const response: any = await searchGlobalContent({
          query: searchTerm,
          pageSize: 10,
          page: 1,
        });
        setFilterData(response.hits);
      } catch (error) {}
    };

    search();
  }, [searchTerm]);

  const dispatch: any = useDispatch();

  return (
    <React.Fragment>
      <form
        className="app-search d-none d-md-block"
        style={{ marginLeft: 5, width: "400px" }}
      >
        <div className="position-relative">
          <Input
            type="text"
            className="form-control"
            placeholder="Search..."
            id="search-options"
            value={searchTerm}
            onKeyDown={onKeyDownPress}
            onChange={handleChange}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <span
            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
            id="search-close-options"
          ></span>
        </div>
        <div
          className="dropdown-menu dropdown-menu-lg"
          id="search-dropdown"
          style={{ width: "500px" }}
        >
          <SimpleBar style={{ height: "320px" }}>
            {/* <div className="dropdown-header mt-2">
              <h6 className="text-overflow text-muted mb-2 text-uppercase">
                Search Results
              </h6>
            </div> */}

            <div className="notification-list">
              {filterData.map((data: any, index: number) => {
                const entityType: string = determineEntityType(
                  data._meilisearch_id
                );
                if (entityType === "contractor") {
                  return (
                    <Link
                      to={`/contractors/${data?.id}`}
                      className="dropdown-item  notify-item py-2"
                      key={data._meilisearch_id}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="m-0 text-wrap">
                            {data?.contractorName}
                          </h6>
                          <span className="fs-12 mb-0 text-muted">
                            Contractor
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (entityType === "contract") {
                  return (
                    <Link
                      to={`/contracts/${data?.id}`}
                      className="dropdown-item  notify-item py-2"
                      key={data._meilisearch_id}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="m-0 text-wrap">
                            {data?.contractTitle}
                          </h6>
                          <span className="fs-12 mb-0 text-muted">
                            Contract
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (entityType === "risk-assessment") {
                  return (
                    <Link
                      to={`/assessments/risk-assessments/${data?.id}`}
                      className="dropdown-item  notify-item py-2"
                      key={data._meilisearch_id}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="m-0 text-wrap">{data?.reportTitle}</h6>
                          <span className="fs-12 mb-0 text-muted">
                            Risk Assessment
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (entityType === "capacity-assessment") {
                  return (
                    <Link
                      to={`/assessments/capacity-assessments/${data?.id}`}
                      className="dropdown-item  notify-item py-2"
                      key={data._meilisearch_id}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="m-0 text-wrap">{data?.reportTitle}</h6>
                          <span className="fs-12 mb-0 text-muted">
                            Capacity Assessment
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (entityType === "partner-verification-report") {
                  return (
                    <Link
                      to={`/assessments/pvrs/${data?.id}`}
                      className="dropdown-item  notify-item py-2"
                      key={data._meilisearch_id}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="m-0 text-wrap">{data?.title}</h6>
                          <span className="fs-12 mb-0 text-muted">
                            Partner Verification Report
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (
                  entityType === "eu-sanctions-list" ||
                  entityType === "us-sanctions-list" ||
                  entityType === "uk-sanctions-list" ||
                  entityType === "un-designated-list" ||
                  entityType === "ungm-list"
                ) {
                  let link = "#";
                  if (entityType === "eu-sanctions-list") {
                    link = `/sanctions-list/$eu/{data?.id}`;
                  } else if (entityType === "us-sanctions-list") {
                    link = `/sanctions-list/us/${data?.id}`;
                  } else if (entityType === "uk-sanctions-list") {
                    link = `/sanctions-list/uk/${data?.id}`;
                  } else if (entityType === "un-designated-list") {
                    link = `/sanctions-list/un/${data?.id}`;
                  } else if (entityType === "ungm-list") {
                    link = `/sanctions-list/ungm/${data?.id}`;
                  }
                  return (
                    <Link
                      to={link}
                      className="dropdown-item  notify-item py-2"
                      key={data._meilisearch_id}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="m-0 text-wrap">
                            {data?.title || data?.companyName}
                          </h6>
                          <span className="fs-12 mb-0 text-muted">
                            {entityType === "eu-sanctions-list" &&
                              "EU Sanctions List"}
                            {entityType === "us-sanctions-list" &&
                              "US Sanctions List"}
                            {entityType === "uk-sanctions-list" &&
                              "UK Sanctions List"}
                            {entityType === "un-designated-list" &&
                              "UN Designated List"}
                            {entityType === "ungm-list" && "UNGM List"}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                }

                return (
                  <Link
                    to="#"
                    className="dropdown-item notify-item py-2"
                    key={data._meilisearch_id}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="m-0">Not Found</h6>
                        <span className="fs-12 mb-0 text-muted">N/A</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </SimpleBar>

          <div className="text-center pt-3 pb-1">
            <Link
              to="/due-diligence-search"
              onClick={() => {
                dispatch(setSearchValue(searchTerm));
              }}
              className="btn btn-primary btn-sm"
            >
              View All Results <i className="ri-arrow-right-line ms-1"></i>
            </Link>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchOption;
