import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getContractTypes as onGetContractTypes } from "../../slices/thunks";

const SelectContractType = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.contractType && validation.errors.contractType
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.contractType && validation.errors.contractType
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetContractTypes({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Contracts;
  const selectVehicleMakeProperties = createSelector(
    selectLayoutState,
    (state) => ({
      contractTypes: state.contractTypes.data,
    })
  );

  const { contractTypes } = useSelector(selectVehicleMakeProperties);

  const options = useMemo(() => {
    return contractTypes.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [contractTypes]);

  return (
    <FormGroup>
      <Label htmlFor="contractType-field" className="form-label">
        Contract Type
      </Label>
      <Select
        name="contractType"
        id="contractType"
        value={
          validation.values.contractType.value
            ? validation.values.contractType
            : null
        }
        isSearchable={false}
        placeholder="Select contract type"
        onChange={(value: any) => {
          validation.setFieldValue("contractType", value);
          validation.setFieldError("contractType", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("contractType", true)}
        className={
          validation.touched.contractType && validation.errors.contractType
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.contractType?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectContractType;
