import React, { useCallback, useEffect } from "react";
import { Container, Row } from "reactstrap";

import Header from "./Header";
import MainDetails from "./MainDetails";
import { useParams } from "react-router-dom";
import { APP_NAME_SHORT } from "common/constants";
import { getCapacityAssessment } from "common/services/capacity-assessments.service";
import { normalize } from "common/lib/normalize";
import Activity from "./Activity";
import BreadCrumb from "Components/Common/BreadCrumb";

const ViewCapacityAssessment = () => {
  const { id: itemId } = useParams();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response: any = await getCapacityAssessment(itemId, {});

      setData(normalize(response.data));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [itemId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  document.title = `Capacity Assessment - ${APP_NAME_SHORT}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Capacity Assessment Details"
            back
            pageTitles={[
              {
                title: "Dashboard",
                link: "/dashboard",
              },
              {
                title: "Capacity Assessments",
                link: "/assessments/capacity-assessments",
              },
            ]}
          />
          <Row>
            <Header data={data} fetchData={fetchData} />
          </Row>
          <Row>
            <MainDetails data={data} />
            <Activity data={data} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCapacityAssessment;
