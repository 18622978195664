import React, { useMemo } from "react";
import { Label, FormFeedback, FormGroup, Input } from "reactstrap";

const SelectTitle = ({ validation }: { validation: any; setLoading: any }) => {
  const options = useMemo(() => {
    const titles = [
      { id: 1, name: "Mr" },
      { id: 2, name: "Mrs" },
      { id: 3, name: "Miss" },
      { id: 4, name: "Dr" },
      { id: 5, name: "Prof" },
    ];

    return titles.map((el: any) => {
      return {
        value: el?.name,
        label: el?.name,
      };
    });
  }, []);

  return (
    <FormGroup>
      <Label htmlFor="title-input" className="form-label">
        Title
      </Label>
      <Input
        name="title"
        type="select"
        className="form-select"
        id="title-input"
        placeholder="Select title"
        onChange={(e) => {
          validation.setFieldValue("title", e.target.value);
        }}
        onBlur={() => validation.setFieldTouched("title", true)}
        value={validation.values.title || ""}
        invalid={
          validation.touched.title && validation.errors.title ? true : false
        }
      >
        <option value="" disabled selected>
          Select an option
        </option>
        {options.map((item: any) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </Input>
      {validation.touched.title && validation.errors.title ? (
        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

export default SelectTitle;
