import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getContractRatings as onGetContractRatings } from "../../slices/thunks";

const SelectContractRating = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.contractRating && validation.errors.contractRating
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.contractRating && validation.errors.contractRating
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetContractRatings({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.Contracts;
  const selectVehicleMakeProperties = createSelector(
    selectLayoutState,
    (state) => ({
      contractRatings: state.contractRatings.data,
    })
  );

  const { contractRatings: contractRatingsList } = useSelector(
    selectVehicleMakeProperties
  );

  const options = useMemo(() => {
    const mainOptions = contractRatingsList.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });

    mainOptions.unshift({ value: null, label: "None" });
    return mainOptions;
  }, [contractRatingsList]);

  return (
    <FormGroup>
      <Label htmlFor="contractRating-field" className="form-label">
        Rating
      </Label>
      <Select
        name="contractRating"
        id="contractRating"
        value={
          validation.values.contractRating.value
            ? validation.values.contractRating
            : null
        }
        placeholder="Select contract rating"
        onChange={(value: any) => {
          validation.setFieldValue("contractRating", value);
          validation.setFieldError("contractRating", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("contractRating", true)}
        className={
          validation.touched.contractRating && validation.errors.contractRating
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.contractRating?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectContractRating;
