import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getUKSanctionsList as onGetSanctionsList } from "../../../slices/thunks";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";

const UKSanctionsList = () => {
  const dispatch: any = useDispatch();

  const selectLayoutState: any = (state: any) => state.SanctionsList;
  const selectinvoiceProperties = createSelector(
    selectLayoutState,
    (state) => ({
      ukSanctionsList: state.ukSanctionsList.data,
      meta: state.ukSanctionsList.meta,
      error: state.error,
      loading: state.loading,
    })
  );

  const { ukSanctionsList, meta, error, loading } = useSelector(
    selectinvoiceProperties
  );
  const [searchValue, setSearchValue] = useState("");

  const [selectedRecord, setSelectedRecord] = useState({});

  const pageSize = 20;

  const [pageCache, setPageCache] = useState({
    page: 1,
    sorted: [{ id: "createdAt", desc: true }],
    searchValue: "",
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      setPageCache({ page, sorted, searchValue });

      const sortObj = getSortObj(sorted);

      dispatch(
        onGetSanctionsList({
          pagination: {
            page,
            pageSize: pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: [
              {
                $or: [
                  {
                    title: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    firstName: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    middleName: {
                      $containsi: searchValue,
                    },
                  },
                  {
                    lastName: {
                      $containsi: searchValue,
                    },
                  },
                ],
              },
            ],
          },
        })
      );
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Full Name",
        accessorKey: "fullName",
        id: "fullName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/uk/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Reference",
        accessorKey: "unReferenceNumber",
        id: "unReferenceNumber",
        enableColumnFilter: false,
      },
      {
        header: "Regime",
        accessorKey: "regime",
        id: "regime",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        header: "Entity Type",
        accessorKey: "individualEntityShip",
        id: "individualEntityShip",
        enableColumnFilter: false,
      },
      {
        header: "Designate Source",
        accessorKey: "designationSource",
        id: "designationSource",
        enableColumnFilter: false,
      },
      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="UK Sanctions List" pageTitle="Sanctions List" />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={3}>
                  <h5 className="card-title mb-0">UK Sanctions List</h5>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search UK sanctions list..."
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={ukSanctionsList || []}
                customPageSize={pageSize}
                pagination={meta?.pagination || {}}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                parentSearchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UKSanctionsList;
