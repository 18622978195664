import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getOrganizationTypes as onGetOrganizationTypes } from "../../slices/thunks";

const SelectOrganizationType = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.organizationType &&
        validation.errors.organizationType
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.organizationType &&
          validation.errors.organizationType
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetOrganizationTypes({
        pagination: {
          page: 1,
          pageSize: 100,
        },
        fields: ["name"],
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.OrganizationTypes;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    organizationTypes: state.organizationTypes.data,
  }));

  const { organizationTypes } = useSelector(selectProperties);

  const options = useMemo(() => {
    return organizationTypes.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
  }, [organizationTypes]);

  return (
    <FormGroup>
      <Label htmlFor="organizationType-field" className="form-label">
        Organization Type
      </Label>
      <Select
        name="organizationType"
        id="organizationType"
        value={
          validation.values.organizationType.value
            ? validation.values.organizationType
            : null
        }
        placeholder="Select organizationType"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("organizationType", value);
          validation.setFieldError("organizationType", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("organizationType", true)}
        className={
          validation.touched.organizationType &&
          validation.errors.organizationType
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.organizationType?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectOrganizationType;
