import { createSlice } from "@reduxjs/toolkit";
import {
  getOrganizationTypes,
  addOrganizationType,
  updateOrganizationType,
  deleteOrganizationType,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  organizationTypes: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const OrganizationTypesSlice = createSlice({
  name: "OrganizationTypesSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOrganizationTypes.fulfilled,
      (state: any, action: any) => {
        if (action.payload?.data) {
          const data: any = normalize(action.payload.data);
          state.organizationTypes.data = data || state.organizationTypes.data;
        }
        state.organizationTypes.meta =
          action.payload?.meta || state.organizationTypes.meta;
        state.loading = false;
      }
    );
    builder.addCase(
      getOrganizationTypes.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        state.loading = false;
        toast.error("Error while fetching data", {
          autoClose: 3000,
        });
      }
    );
    builder.addCase(addOrganizationType.fulfilled, () => {
      toast.success("Organization Type Added Successfully", {
        autoClose: 3000,
      });
    });
    builder.addCase(addOrganizationType.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Organization Type Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateOrganizationType.fulfilled, () => {
      toast.success("Organization Type Updated Successfully", {
        autoClose: 3000,
      });
    });
    builder.addCase(
      updateOrganizationType.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.error("Organization Type Update Failed", { autoClose: 3000 });
      }
    );
    builder.addCase(
      deleteOrganizationType.fulfilled,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.success("Organization Type Deleted Successfully", {
          autoClose: 3000,
        });
      }
    );
    builder.addCase(
      deleteOrganizationType.rejected,
      (state: any, action: any) => {
        state.error = action?.payload?.error || null;
        toast.error("Organization Type Deletion Failed", { autoClose: 3000 });
      }
    );
  },
});

export const { setLoading } = OrganizationTypesSlice.actions;

export default OrganizationTypesSlice.reducer;
