import { ICON_AGENCIES, ICON_CALENDAR, ICON_CONTRACTS } from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";

const ContractorsItem = ({ data, index }: { data: any; index?: number }) => {
  return (
    <div className={index === 0 ? "pb-3" : "py-3"}>
      <h5 className="mb-1">
        <Link to={`/contractors/${data.id}`}>{data?.contractorName}</Link>
      </h5>
      <p className="text-success mb-2 align-items">
        <span className="me-2 text-capitalize">Contractor</span>
        {/* <span className="badge bg-success-subtle text-success fs-11 me-2">
          <i className="ri-account-circle-line align-bottom"></i> Contractors
        </span> */}
        {data?.semgMentions && data?.semgMentions?.length > 0 && (
          <span className="badge bg-warning-subtle text-warning fs-11 me-2">
            SEMG Mention
          </span>
        )}
      </p>
      <p className="text-muted mb-2">
        {`... acronym: ${data?.acronym}, organization type: ${data?.organizationType?.name} ... `}
        {data?.businessEmail && `email: ${data?.businessEmail}, `}
        {data?.businessPostalAddress &&
          `phone: ${data?.businessPostalAddress}, `}
        {data?.businessPhysicalAddress &&
          `address: ${data?.businessPhysicalAddress} ... `}
      </p>

      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item me-3">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CALENDAR}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">
                {moment(new Date(data?.updatedAt)).format("DD MMM Y")}
              </span>
            </div>
          </div>
        </li>
        <li className="list-inline-item me-3">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_AGENCIES}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">0 Agencies</span>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CONTRACTS}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">{`${data?.contracts?.length} Contracts`}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ContractorsItem;
