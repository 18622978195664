import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { updateAgency as onUpdateAgency } from "../../slices/thunks";
import VehicleModelForm from "./AgencyForm";

const UpdateVehicleModel: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  selectedRecord: {
    id: number;
    name: string;
    category: {
      id: number;
      name: string;
    };
  };
  fetchAgencies: () => void;
}> = ({ toggle, isModalOpen, selectedRecord, fetchAgencies }) => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: selectedRecord?.name || "",
      category: selectedRecord?.category?.id || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Make"),
      category: Yup.string(),
    }),
    onSubmit: (values) => {
      const updatedData = {
        id: selectedRecord.id,
        data: {
          name: values["name"],
          category: values["category"],
        },
      };
      // save new order
      setLoading(true);
      dispatch(onUpdateAgency(updatedData)).then((result: any) => {
        setLoading(false);

        if (result?.payload?.data) {
          fetchAgencies();
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  return (
    <Modal id="showModal" isOpen={isModalOpen} toggle={toggle} centered>
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Agency
      </ModalHeader>

      <VehicleModelForm
        validation={validation}
        isUpdate={true}
        loading={loading}
        toggle={toggle}
      />
    </Modal>
  );
};

export default UpdateVehicleModel;
