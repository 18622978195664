import React, { useMemo, useEffect } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { getCapacityAssessmentTypes } from "common/services/capacity-assessments.service";
import { normalize } from "common/lib/normalize";

const SelectTypeFilter = ({
  filterValue,
  setFilterValue,
}: {
  filterValue: any;
  setFilterValue: (value: any) => void;
}) => {
  const dispatch: any = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getCapacityAssessmentTypes({});
        setData(normalize(response.data));
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
  }, [data]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "#ced4da",
      borderRadius: ".25rem",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#ced4da",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      borderColor: "#ced4da",
      borderRadius: ".25rem",
    }),
  };

  return (
    <FormGroup>
      <Select
        name="capacityAssessmentType"
        id="capacityAssessmentType"
        value={filterValue?.capacityAssessmentType || null}
        placeholder="Select type"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          setFilterValue({
            ...filterValue,
            capacityAssessmentType: value,
          });
        }}
        options={options}
        isLoading={loading}
        styles={customStyles}
      />
    </FormGroup>
  );
};

export default SelectTypeFilter;
