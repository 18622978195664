import { createSlice } from "@reduxjs/toolkit";
import { getPVRs, addPVR, updatePVR, deletePVR } from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  pvrs: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const PVRSlice = createSlice({
  name: "PVRSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPVRs.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload.data);
        state.pvrs.data = data || state.pvrs.data;
      }
      state.pvrs.meta = action.payload?.meta || state.pvrs.meta;
      state.loading = false;
    });
    builder.addCase(getPVRs.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
    builder.addCase(addPVR.fulfilled, () => {
      toast.success("PVR Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addPVR.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("PVR Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updatePVR.fulfilled, () => {
      toast.success("PVR Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(updatePVR.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("PVR Update Failed", { autoClose: 3000 });
    });
    builder.addCase(deletePVR.fulfilled, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.success("PVR Deleted Successfully", { autoClose: 3000 });
    });
    builder.addCase(deletePVR.rejected, (state: any, action: any) => {
      state.error = action?.payload?.error || null;
      toast.error("PVR Deletion Failed", { autoClose: 3000 });
    });
  },
});

export const { setLoading } = PVRSlice.actions;

export default PVRSlice.reducer;
