import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { setLoading } from "slices/countries/reducer";
import { getAgencies as onGetAgencies } from "../../slices/thunks";

type AgencyFilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const AgencyFilter = ({ onChange, value }: AgencyFilterProps) => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetAgencies({
        pagination: {
          page: 1,
          pageSize: 10000,
        },
        filters: {
          category: {
            $null: false,
          },
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const selectLayoutState = (state: any) => state.Agencies;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    agencies: state.agencies.data,
  }));

  const { agencies } = useSelector(selectProperties);

  const options = useMemo(() => {
    return agencies.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [agencies]);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default"
      id="choices-single-default"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      <option value="">Select Agency </option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default AgencyFilter;
