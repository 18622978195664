import { createSlice } from "@reduxjs/toolkit";
import {
  getRiskRatings,
  addRiskRating,
  updateRiskRating,
  deleteRiskRating,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  riskRatings: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  error: {},
  refreshList: false,
  loading: false,
};

const RiskRatingsSlice = createSlice({
  name: "RiskRatingsSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRiskRatings.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload.data);
        state.riskRatings.data = data || state.riskRatings.data;
      }
      state.riskRatings.meta = action.payload?.meta || state.riskRatings.meta;
      state.loading = false;
    });
    builder.addCase(getRiskRatings.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
    builder.addCase(addRiskRating.fulfilled, () => {
      toast.success("Risk Rating Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addRiskRating.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Risk Rating Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateRiskRating.fulfilled, () => {
      toast.success("Risk Rating Updated Successfully", { autoClose: 3000 });
    });
    builder.addCase(updateRiskRating.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Risk Rating Update Failed", { autoClose: 3000 });
    });
    builder.addCase(deleteRiskRating.fulfilled, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.success("Risk Rating Deleted Successfully", { autoClose: 3000 });
    });
    builder.addCase(deleteRiskRating.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
      toast.error("Risk Rating Deletion Failed", { autoClose: 3000 });
    });
  },
});

export const { setLoading } = RiskRatingsSlice.actions;

export default RiskRatingsSlice.reducer;
