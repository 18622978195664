import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";

import { getCapacityAssessmentTypes as onGetData } from "../../../slices/thunks";

const SelectCapacityAssessmentType = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.capacityAssessmentType &&
        validation.errors.capacityAssessmentType
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.capacityAssessmentType &&
          validation.errors.capacityAssessmentType
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const dispatch: any = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      onGetData({
        pagination: {
          page: 1,
          pageSize: 100,
        },
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, setLoading]);

  const selectLayoutState = (state: any) => state.CapacityAssessments;
  const selectVehicleMakeProperties = createSelector(
    selectLayoutState,
    (state) => ({
      capacityAssessmentTypes: state.capacityAssessmentTypes.data,
    })
  );

  const { capacityAssessmentTypes } = useSelector(selectVehicleMakeProperties);

  const options = useMemo(() => {
    return capacityAssessmentTypes.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
  }, [capacityAssessmentTypes]);

  return (
    <FormGroup>
      <Label htmlFor="capacityAssessmentType-field" className="form-label">
        Type of Assessment
      </Label>
      <Select
        name="capacityAssessmentType"
        id="capacityAssessmentType"
        value={
          validation.values.capacityAssessmentType.value
            ? validation.values.capacityAssessmentType
            : null
        }
        placeholder="Select capacity assessment type"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("capacityAssessmentType", value);
          validation.setFieldError("capacityAssessmentType", "");
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched("capacityAssessmentType", true)
        }
        className={
          validation.touched.capacityAssessmentType &&
          validation.errors.capacityAssessmentType
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>
        {validation.errors.capacityAssessmentType?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectCapacityAssessmentType;
