import { createSlice } from "@reduxjs/toolkit";
import {
  getRiskAssessments,
  addRiskAssessment,
  updateRiskAssessment,
  deleteRiskAssessment,
  getRiskAssessment,
} from "./thunk";
import { toast } from "react-toastify";

import { normalize } from "common/lib/normalize";

export const initialState = {
  riskAssessments: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 0,
        total: 0,
      },
    },
  },
  riskAssessment: {
    reportDate: "",
    reportTitle: "",
    conductedBy: {},
    agency: {},
    overallRiskRating: {},
    contractor: {},
    summaryOfFindings: "",
    reportFile: "",
    serialNumber: "",
  },
  error: {},
  refreshList: false,
  loading: false,
};

const ContractSlice = createSlice({
  name: "ContractSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRiskAssessments.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);
        state.riskAssessments.data = data || state.riskAssessments.data;
      }
      state.riskAssessments.meta =
        action.payload?.meta || state.riskAssessments.meta;
      state.loading = false;
    });
    builder.addCase(getRiskAssessments.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      state.loading = false;
      toast.error("Error while fetching data", {
        autoClose: 3000,
      });
    });
    builder.addCase(getRiskAssessment.fulfilled, (state: any, action: any) => {
      if (action.payload?.data) {
        const data: any = normalize(action.payload?.data);

        state.contract = data || state.contract;
      }
      state.loading = false;
    });
    builder.addCase(addRiskAssessment.fulfilled, () => {
      toast.success("Risk Assessment Added Successfully", { autoClose: 3000 });
    });
    builder.addCase(addRiskAssessment.rejected, (state: any, action: any) => {
      state.error = action.error?.message || null;
      toast.error("Risk Assessment Creation Failed", {
        autoClose: 3000,
      });
    });
    builder.addCase(updateRiskAssessment.fulfilled, () => {
      toast.success("Risk Assessment Updated Successfully", {
        autoClose: 3000,
      });
    });
    builder.addCase(
      updateRiskAssessment.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.error("Risk Assessment Update Failed", { autoClose: 3000 });
      }
    );
    builder.addCase(
      deleteRiskAssessment.fulfilled,
      (state: any, action: any) => {
        state.error = action.error?.message || null;
        toast.success("Risk Assessment Deleted Successfully", {
          autoClose: 3000,
        });
      }
    );
    builder.addCase(
      deleteRiskAssessment.rejected,
      (state: any, action: any) => {
        state.error = action?.payload?.error || null;
        toast.error("Risk assessment deletion failed", { autoClose: 3000 });
      }
    );
  },
});

export const { setLoading } = ContractSlice.actions;

export default ContractSlice.reducer;
