import React from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import DistributionByContractType from "./DistributionByContractType";
import RecentlyFlagged from "./RecentlyFlagged";
import Header from "./Header";

const AgencyDashboard = () => {
  document.title = "Agency Dashboard | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="">
          <Header />
          <Row className="mt-5">
            <Col lg={12}>
              <Row>
                <Widget />
              </Row>
            </Col>
          </Row>
          <Row>
            <RecentlyFlagged />
            <DistributionByContractType />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgencyDashboard;
