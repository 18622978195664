import React, { useMemo } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";

const SelectRelationshipStatus = ({
  validation,
  selectedId,
  index,
}: {
  validation: any;
  isUpdate?: boolean;
  selectedId?: number;
  index: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation?.touched?.affiliations?.[index]?.relationshipConfirmed &&
        validation?.errors?.affiliations?.[index]?.relationshipConfirmed
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation?.touched?.affiliations?.[index]?.relationshipConfirmed &&
          validation?.errors?.affiliations?.[index]?.relationshipConfirmed
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const options = useMemo(() => {
    return [
      { value: true, label: "Confirmed" },
      {
        value: false,
        label: "Not confirmed",
      },
    ];
  }, []);

  return (
    <FormGroup key={index}>
      <Label
        htmlFor={`affiliations.${index}.relationshipConfirmed`}
        className="form-label"
      >
        Is Relationship Confirmed?
      </Label>
      <Select
        name={`affiliations.${index}.relationshipConfirmed`}
        id={`affiliations.${index}.relationshipConfirmed`}
        value={
          validation.values.affiliations[index].relationshipConfirmed.value !==
          null
            ? validation.values.affiliations[index].relationshipConfirmed
            : null
        }
        placeholder="Select relationship confirmed"
        onChange={(value: any) => {
          validation.setFieldValue(
            `affiliations.${index}.relationshipConfirmed`,
            value
          );
          validation.setFieldError(
            `affiliations.${index}.relationshipConfirmed`,
            ""
          );
        }}
        options={options}
        onBlur={() =>
          validation.setFieldTouched(
            `affiliations.${index}.relationshipConfirmed`,
            true
          )
        }
        className={
          validation.touched.affiliations?.[index]?.relationshipConfirmed &&
          validation.errors.affiliations?.[index]?.relationshipConfirmed
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>
        {validation.errors.affiliations?.[index]?.relationshipConfirmed?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectRelationshipStatus;
