import React from "react";
import MyAssessments from "./MyAssessments";

const AssessmentsTab = () => {
  return (
    <React.Fragment>
      <MyAssessments />
    </React.Fragment>
  );
};

export default AssessmentsTab;
