import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import RiskAssessmentForm from "./RiskAssessmentForm";
import { addRiskAssessment } from "common/services/risk-assessments.service";

const AddRiskAssessment: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
}> = ({ toggle, isModalOpen, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      reportTitle: "",
      agency: {
        value: "",
        label: "",
      },
      contractor: {
        value: "",
        label: "",
      },
      serialNumber: "",
      reportDate: "",
      overallRiskRating: {
        value: "",
        label: "",
      },
      conductedBy: {
        value: "",
        label: "",
      },
      summaryOfFindings: "",
      sensitivity: {
        value: "",
        label: "",
      },
      selectedFiles: [],
    },
    validationSchema: Yup.object({
      reportTitle: Yup.string().required("Please enter report title"),
      agency: Yup.object().shape({
        value: Yup.number().required("Please select requesting agency"),
      }),
      contractor: Yup.object().shape({
        value: Yup.number().required("Please select contractor"),
      }),
      serialNumber: Yup.string().required("Please enter serial number"),
      reportDate: Yup.string().required("Please select report date"),
      overallRiskRating: Yup.object().shape({
        value: Yup.number().required("Please select overall risk rating"),
      }),

      conductedBy: Yup.object().shape({
        value: Yup.number().required("Please select conducted by"),
      }),
      sensitivity: Yup.object().shape({
        value: Yup.string().nullable(),
      }),
      summaryOfFindings: Yup.string().required(
        "Please enter summary of findings"
      ),
      selectedFiles: Yup.array()
        .min(1, "Atleast one file is required")
        .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {
        reportTitle: values.reportTitle,
        agency: values.agency.value,
        contractor: values.contractor.value,
        serialNumber: values.serialNumber,
        reportDate: values.reportDate,
        overallRiskRating: values.overallRiskRating.value,
        conductedBy: values.conductedBy.value,
        summaryOfFindings: values.summaryOfFindings,
        sensitivity: values.sensitivity.value,
      };

      const formData = new FormData();
      values.selectedFiles.forEach((file: any) => {
        formData.append("files.reportFile", file, file.name);
      });
      formData.append("data", JSON.stringify(data));

      setLoading(true);
      try {
        setLoading(true);
        await addRiskAssessment(formData);
        fetchData();
        validation.resetForm();
        toggle();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Risk Assessment
      </ModalHeader>

      <RiskAssessmentForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddRiskAssessment;
