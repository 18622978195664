import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

import Dashboard from "./dashboard/reducer";

// Countries
import CountriesReducer from "./countries/reducer";

// Agencies
import AgenciesReducer from "./agencies/reducer";

// Country Offices
import CountryOfficesReducer from "./country-office/reducer";

// Role
import RoleReducer from "./role/reducer";

//  Sanctions List
import SanctionsListReducer from "./sanctions-list/reducer";

// Contracts
import ContractsReducer from "./contract/reducer";

// Contractors
import ContractorsReducer from "./contractor/reducer";

// Risk Assessments
import RiskAssessmentsReducer from "./risk-assessments/reducer";

// Risk Ratings
import RiskRatingsReducer from "./risk-ratings/reducer";

// PVRs
import PVRsReducer from "./pvr/reducer";

// Capacity Assessments
import CapacityAssessmentsReducer from "./capacity-assessments/reducer";

// Organization Types
import OrganizationTypesReducer from "./organization-types/reducer";

// Search
import SearchReducer from "./search/reducer";

// Persons
import PersonsReducer from "./person/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Dashboard: Dashboard,
  Countries: CountriesReducer,
  Agencies: AgenciesReducer,
  CountryOffices: CountryOfficesReducer,
  Roles: RoleReducer,
  SanctionsList: SanctionsListReducer,
  Contracts: ContractsReducer,
  Contractors: ContractorsReducer,
  RiskAssessments: RiskAssessmentsReducer,
  RiskRatings: RiskRatingsReducer,
  PVRs: PVRsReducer,
  CapacityAssessments: CapacityAssessmentsReducer,
  OrganizationTypes: OrganizationTypesReducer,
  Search: SearchReducer,
  Persons: PersonsReducer,
});

export default rootReducer;
