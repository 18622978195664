import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import {
  getRiskRatings as getRiskRatingsApi,
  addRiskRating as addRiskRatingApi,
  updateRiskRating as updateRiskRatingApi,
  deleteRiskRating as deleteRiskRatingApi,
} from "../../helpers/backend_helper";
import { setLoading } from "./reducer";

export const getRiskRatings = createAsyncThunk(
  "riskRating/getRiskRatings",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = getRiskRatingsApi(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addRiskRating = createAsyncThunk(
  "riskRating/addRiskRating",
  async (riskRating: any) => {
    try {
      const response = addRiskRatingApi(riskRating);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateRiskRating = createAsyncThunk(
  "riskRating/updateRiskRating",
  async (riskRating: any) => {
    try {
      const response = updateRiskRatingApi(riskRating);
      const data = response;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteRiskRating = createAsyncThunk(
  "riskRating/deleteRiskRating",
  async (riskRating: any) => {
    try {
      const response = deleteRiskRatingApi(riskRating);
      return response;
    } catch (error) {
      return error;
    }
  }
);
